import axiosConfig from '../axiosConfig'
import { userId } from './users'

const markItem = (siteChecklistId, fhid, checked) => {

  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/siteChecklist/checkItem`, {
      siteChecklistId: siteChecklistId,
      fhid: fhid,
      checked: checked
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })

}

export default markItem