import { useContext, useState } from "react";
import SidebarPage from "../SidebarPage";
import ActionPanel from "../ui/ActionPanel";
import { LightningBoltIcon } from "@heroicons/react/solid";
import axiosConfig from '../../axiosConfig';
import { ModalContext } from '../../hooks/ModalContext';
import DangerDialog from '../ui/modal/dialogs/Danger';
import SimpleDropdown from "../ui/SimpleDropdown";

const LinkSites = () => {

  let { handleModal } = useContext(ModalContext);

  const linkDudaToFirm = () => {
    // handleModal(<DangerDialog
    //   title='Test title'
    //   messageText='Test description coming here soon wtf'
    //   actionText={false}
    //   cancelText='Dismiss'
    //   actionFn={null}
    // />)

    // axiosConfig.get('/api/test');
    

    if (!linkLoading) {
      setLinkLoading(true);
      axiosConfig.post('/api/sites/linkDuda', linker)
      .then((res) => {

        if (res.status === 204) {
          window.location.href = `/sites/id/${linker.fhId}`;
        } else {
          handleModal(<DangerDialog
            title={res.data.error}
            messageText={res.data.errorMessage}
            actionText={false}
            cancelText='Enter a different ID'
            actionFn={null}
          />)
          setLinkLoading(false);
        }

      })
      .catch((err) => {

        alert(err);
        setLinkLoading(false);
      })  
    }
  }

  const unlinkDudaToFirm = () => {
    
    if (!unlinkLoading) {
      setUnlinkLoading(true);
      axiosConfig.post('/api/sites/unlinkDuda', linker)
      .then((res) => {

        if (res.status === 204) {
          window.location.href = `/sites/id/${linker.fhId}`;
        } else {
          handleModal(<DangerDialog
            title={res.data.error}
            messageText={res.data.errorMessage}
            actionText={false}
            cancelText='Enter a different ID'
            actionFn={null}
          />)
          setUnlinkLoading(false);
        }

      })
      .catch((err) => {

        alert(err);
        setUnlinkLoading(false);
      })  
    }
  }
  
  // let [ dudaSite, setDudaSite ] = useState('');
  // let [ fhId, setFhId ] = useState('');
  let [ linkLoading, setLinkLoading ] = useState(false);
  let [ unlinkLoading, setUnlinkLoading ] = useState(false);

  let [ linker, setLinker ] = useState({
    dudaSite: '',
    fhId: ''
  })

  const handleChange = (e) => {
    setLinker({
      ...linker,
      [e.target.name]: e.target.value
    })
  }

  return (
    <SidebarPage docTitle='Link Site | Tukios Website Admin'>
      <div className='mb-5 text-center'>
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Link an existing Duda site</h2>
        <p className="text-sm mt-1 text-gray-500">Connect an existing Duda site to a firm in our system</p>
      </div>

      <div className='grid lg:grid-cols-2 space-y-5 lg:space-x-5 lg:space-y-0'>
        <ActionPanel
          title='Duda site'
          description='Enter the Duda "site name"'
          placeholder='84c529cf'
          name='dudaSite'
          value={linker.dudaSite}
          updateValue={handleChange}
        />

        <ActionPanel
          title='Funeral home'
          description='Enter the funeral home ID'
          placeholder='325'
          name='fhId'
          value={linker.fhId}
          updateValue={handleChange}
        />

        {/* <SimpleDropdown
          label='Choose site status'
          options={[
            { id: 'In Design', name: 'In Design' },
            { id: 'Rebuild', name: 'Rebuild' },
          ]}
        /> */}
      </div>

      <div className='flex items-center justify-center mt-5'>
      <button
        type="button"
        onClick={linkDudaToFirm}
        className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
      >
        {
          !linkLoading ?
            <>
              Link Duda site
              <LightningBoltIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
            </> :
            <>
              Loading...
            </>
        }
      </button>
      </div>
      <div className='mb-5 mt-16 text-center'>
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Unlink an existing Duda site</h2>
        <p className="text-sm mt-1 text-gray-500">Disconnect an existing Duda site from a firm in our system</p>
      </div>

      <div className='block space-y-5 lg:space-x-5 lg:space-y-0 ml-auto mr-auto lg:w-1/2'>
        <ActionPanel
          title='Funeral home'
          description='Enter the funeral home ID'
          placeholder='325'
          name='fhId'
          value={linker.fhId}
          updateValue={handleChange}
        />
      </div>

      {/* <SimpleDropdown
        label='Choose site status'
         options={[
          { id: 'In Design', name: 'In Design' },
          { id: 'Rebuild', name: 'Rebuild' },
        ]}
      /> */}

      <div className='block space-y-5 lg:space-x-5 lg:space-y-0 ml-auto mr-auto'>
        <div className='flex items-center justify-center mt-5'>
        <button
          type="button"
          onClick={unlinkDudaToFirm}
          className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
        >
          {
            !unlinkLoading ?
              <>
                Unlink Duda site
                <LightningBoltIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
              </> :
              <>
                Loading...
              </>
          }
        </button>
        </div>
      </div>

    </SidebarPage>
  )
}

export default LinkSites;