import { Dialog } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import { useContext } from 'react';
import { ModalContext } from '../../../../hooks/ModalContext';
import PromoteProgressStep from '../../../site/PromoteProgressStep';

const StageWarning = ({ title, message, buttonText, warnings }) => {

  let { handleModal } = useContext(ModalContext);

  return (
    <>
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
            <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {message}
              </p>
            </div>
            <div className="mt-5 grid grid-cols-2 gap-4">
              {
                warnings.map(step => (
                  <PromoteProgressStep key={step.id} step={step} />
                ))
              }
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
            <button
                type="button"
                className="inline-flex items-center justify-center w-full px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
                onClick={() => handleModal(false)}
            >
                {buttonText}
                <CheckIcon className="ml-1 -mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
        </div>
    </>
  )
}

export default StageWarning;