import React from 'react'
import SidebarLink from './SidebarLink'
import navItemsGenerator from './NavItems'

const MobileNavigation = () => {

  return (
    <div className="mt-5 flex-1 h-0 overflow-y-auto">
      <nav className="px-2 space-y-1">
        {
          navItemsGenerator().map((item) => (
            <SidebarLink item={item} />
          ))
        }
      </nav>
    </div>
  )
}

export default MobileNavigation
