import axiosConfig from '../axiosConfig'

const assignSdrToFirm = (fhid, sdrId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/assignSdr`, {
      sdrId: sdrId
    })
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const assignSdr = (fhid, sdrId) => {
  return new Promise((resolve, reject) => {
    assignSdrToFirm(fhid, sdrId)
      .then(() => {
        resolve(true)
      })
      .catch((err) => reject(err))
  })
}

export default assignSdr