import SiteActions from "../../utils/site table/columns/SiteActions";
import SiteID from "../../utils/site table/columns/SiteID";
import SiteLaunchDate from "../../utils/site table/columns/SiteLaunchDate";
import SiteMembers from "../../utils/site table/columns/SiteMembers";
import SiteName from "../../utils/site table/columns/SiteName";
import SiteStatus from "../../utils/site table/columns/SiteStatus";
import SiteSearch from "../../utils/site table/SiteSearch";
import DesignerSiteFilter from "../../utils/site table/DesignerSiteFilter";
import AeSiteFilter from "../../utils/site table/AeSiteFilter";
import CorporationSiteFilter from "../../utils/site table/CorporationSiteFilter";
import StatusSiteFilter from "../../utils/site table/StatusSiteFilter";
import SitesTable from "../../utils/site table/SitesTable";
import ThreeDotMenu from "../../utils/site table/icons/ThreeDotMenu";
import SiteProvider from "../../utils/site table/columns/SiteProvider";
import SiteRooftops from "../../utils/site table/columns/SiteRooftops";
import SiteInfo from "../../utils/site table/columns/SiteInfo";
import { siteStatuses } from "../../utils/utilityLibrary";

export default function LaunchScheduleTable() {

  const filters = [
    {
      Component: SiteSearch,
      className: 'w-full lg:1/5'
    },
    {
      Component: DesignerSiteFilter,
      className: 'w-full lg:1/5'
    },
    {
      Component: AeSiteFilter,
      className: 'w-full lg:1/5'
    },
    {
      Component: CorporationSiteFilter,
      className: 'w-full lg:1/5'
    },
    {
      Component: StatusSiteFilter,
      className: 'w-full lg:1/5'
    }
  ]

  const columns = [
    {
      label: "ID",
      Component: SiteID,
      className: 'lg:w-32'
    },
    {
      label: "Name",
      Component: SiteName,
      className: 'lg:w-1/6'
    },
    {
      label: "Rooftops",
      Component: SiteRooftops,
      className: 'w-full lg:justify-center lg:w-20 lg:text-center lg:flex'
    },
    {
      label: "Status",
      Component: SiteStatus,
      className: 'lg:w-1/6 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Members",
      Component: SiteMembers,
      className: 'lg:w-1/12 lg:flex lg:justify-center'
    },
    {
      label: "Launch Date",
      Component: SiteLaunchDate,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    {
      label: "Info",
      Component: SiteInfo,
      className: 'lg:w-24 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Prev. Provider",
      Component: SiteProvider,
      className: 'lg:w-48 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Actions",
      Component: SiteActions,
      className: 'lg:w-44 lg:flex lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  return (
    <div style={
      {
        minWidth: window.innerWidth >= 1024 ? '1533px' : '100%',
      }
    }>
      <SitesTable columns={columns} filters={filters} trailingIcon={ThreeDotMenu} defaultSiteParams={{
        label: [...Object.values(siteStatuses)].filter(status => !['Lost', 'Test'].includes(status)),
        launchSchedule: true
      }} />
    </div>
  )

}