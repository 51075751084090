import axiosConfig from '../axiosConfig';
import logActivity from './logActivity';

const updateLaunchDate = (fhid, launchDate, reason, note) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/updateLaunchDate`, {
      launchDate: launchDate,
      reason: reason,
      note: note
    }).then(() => {
      axiosConfig.get(`/api/sites/${fhid}`)
        .then((res) => {
          logActivity({
            message: `Scheduled launch for ${res.data.pretty_scheduled_launch_date}`,
            fhId: fhid,
            logType: 'OTHER',
          }).then(() => {
            resolve()
          })
            .catch(err => {
              reject(err)
            })
        })
    })
      .catch((err) => reject(err))
  })
}

const unscheduleLaunchDate = (fhid, reason, note, updateLaunchDateDisplay) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`api/sites/${fhid}/updateLaunchDate`, {
      launchDate: null,
      allowNull: true,
      reason: reason,
      note: note
    }).then(() => {
      axiosConfig.get(`/api/sites/${fhid}`)
        .then(() => {
          logActivity({
            message: `Unscheduled launch`,
            fhId: fhid,
            logType: 'OTHER',
          }).then(() => {
            updateLaunchDateDisplay(fhid, null)
            resolve()
          })
            .catch(err => {
              reject(err)
            })
        })
    })
      .catch((err) => { reject(err) })
  })
}

const launchDateAvailable = (launchDate) => {
  return new Promise((resolve, reject) => {
    axiosConfig.get(`/api/sites/${launchDate}/launchDateAvailable`, {
      launchDate: launchDate
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export { updateLaunchDate, unscheduleLaunchDate, launchDateAvailable };