import axiosConfig from '../axiosConfig'
import { notableUsers } from './utilityLibrary'

// const UserRole = {
//   SuperAdmin: 'user:super_admin',
//   AccountExecutive: 'user:account_executive',
//   AccountManager: 'user:account_manager',
//   Designer: 'user:designer',
//   QualityAssurance: 'user:quality_assurance',
//   Sales: 'user:sales',
//   FloralSpecialist: 'user:floral_specialist',
//   Support: 'user:support',
// }

const UserRole = {
  SuperAdmin: 'admin',
  AccountExecutive: 'ae',
  AccountManager: 'am',
  Designer: 'designer',
  QualityAssurance: 'care',
  Sales: 'sdr',
  FloralSpecialist: 'florist',
}

const isAdmin = () => {
  return getUserRole() == UserRole.SuperAdmin
}

const isAM = () => {
  return getUserRole() == UserRole.AccountManager
}

const isSDR = () => {
  return getUserRole() == UserRole.Sales
}

const isDesigner = () => {
  return getUserRole() == UserRole.Designer
}

const isAE = () => {
  return getUserRole() == UserRole.AccountExecutive
}

const isQA = () => {
  return getUserRole() == UserRole.QualityAssurance
}

const isFlorist = () => {
  return getUserRole() == UserRole.FloralSpecialist
}

const getUserRole = () => {
  return localStorage.getItem('role')
}

const userId = () => {
  return localStorage.getItem('user_id')
}

const userName = () => {
  return localStorage.getItem('name')
}

const userImage = () => {
  return localStorage.getItem('image')
}

const userEmail = () => {
  return localStorage.getItem('email')
}

const userTrelloId = () => {
  return localStorage.getItem('trello_id')
}

const isLaunchSpecialist = () => {
  return [notableUsers.Trey, notableUsers.Ryan, notableUsers.Coulter].includes(userId())
}

const isScrapeMaster = () => {
  return [notableUsers.Tyson].includes(userId())
}

const isDirectorOfSalesDevelopement = () => {
  return userId() == notableUsers.Sage
}

const isDirectorOfAccountsManagement = () => {
  return userId() == notableUsers.Landon
}

const getUser = (userId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.get(`/api/users/${userId}`).then((res) => {
      resolve(res.data)
    })
      .catch((err) => reject(err))
  })
}

const getDesignerFromUser = () => {
  return new Promise((resolve, reject) => {
    let userId = userId()
    axiosConfig.get(`/api/designers/fromUser/${userId}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

const getAeFromUser = () => {
  return new Promise((resolve, reject) => {
    let userId = userId()
    axiosConfig.get(`/api/aes/fromUser/${userId}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

const getAmFromUser = () => {
  return new Promise((resolve, reject) => {
    let userId = userId()
    axiosConfig.get(`/api/ams/fromUser/${userId}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

const getCareFromUser = () => {
  return new Promise((resolve, reject) => {
    let userId = userId()
    axiosConfig.get(`/api/cares/fromUser/${userId}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

const getUserFromDesigner = () => {
  return new Promise((resolve, reject) => {
    let userId = userId()
    axiosConfig.get(`/api/designers/toUser/${userId}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

const getUserFromAe = () => {
  return new Promise((resolve, reject) => {
    let userId = userId()
    axiosConfig.get(`/api/aes/toUser/${userId}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

const getUserFromAm = () => {
  return new Promise((resolve, reject) => {
    let userId = userId()
    axiosConfig.get(`/api/ams/toUser/${userId}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

const getUserObject = () => {
  return {
    id: userId(),
    name: userName(),
    user_id: userId(),
    role: getUserRole(),
    image: userImage(),
    email: userEmail(),
    trello_id: userTrelloId()
  }
}

const hasAllUserData = () => {
  let userData = getUserObject()
  for (const key in userData) {
    if (!userData[key] && key != 'image' && key != 'trello_id') {
      return false
    }
  }
  return true
}

const addTrelloMember = (fhid, userId) => {
  return new Promise((resolve, reject) => {
    getUser(userId)
      .then((user) => {
        axiosConfig.post(`/api/sites/${fhid}/addTrelloMember`, {
          memberId: user.trello_id
        })
          .then(() => resolve())
          .catch((err) => reject(err))
      })
      .catch((err) => reject(err))
  })
}

export {
  UserRole,
  addTrelloMember,
  hasAllUserData,
  isAdmin,
  isQA,
  isAM,
  isSDR,
  isFlorist,
  isDesigner,
  isAE,
  getUser,
  getUserObject,
  userId,
  userName,
  userEmail,
  getUserRole,
  userImage,
  userTrelloId,
  getCareFromUser,
  getDesignerFromUser,
  getUserFromDesigner,
  getUserFromAe,
  getUserFromAm,
  getAeFromUser,
  getAmFromUser,
  isLaunchSpecialist,
  isScrapeMaster,
  isDirectorOfSalesDevelopement,
  isDirectorOfAccountsManagement
}