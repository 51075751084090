import { useEffect } from "react"
import { useState } from "react"
import { useLocation } from "react-router"
import SidebarPage from "../SidebarPage"
import ActivityFeed from "./ActivityFeed"
import SiteHeader from "./SiteHeader"
import SiteTabs from "./SiteTabs"
import TabRouter from "./TabRouter"
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import getSite from "../../utils/getSite"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const SitePage = (props) => {

  const location = useLocation()
  const { width, height } = useWindowSize()
  let { id } = useParams()

  let [site, setSite] = useState(location.state?.site ?? {id: id})

  let [isLoading, setIsLoading] = useState(true)
  let [showConfetti, setShowConfetti] = useState(false)

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)

    if (params.has('created')) {
      let created = params.get('created')
      window.history.replaceState({}, document.title, window.location.pathname)

      if (created == 1) setShowConfetti(true)
    }

    getSite(id)
      .then((site) => {
        setSite({ ...site })
        setIsLoading(false)
        document.title = `${id} ${site.name} | Tukios Website Admin`
      })
  }, [])

  const updateSiteStatus = (status) => {
    setSite({
      ...site,
      launcher_label: status
    })
  }

  return (
    <div className="overflow-y-auto">
      <div className="max-w-7xl mx-auto">
        {
          showConfetti &&
          <Confetti
            width={width}
            height={height}
            recycle={false}
          />
        }
        <SidebarPage scroll={false} docTitle={`${id} ${site.name} | Tukios Website Admin`}>
          <SiteHeader
            site={site}
            updateSiteStatus={updateSiteStatus}
            setShowConfetti={setShowConfetti}
          />
          <div className="mt-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-4">
            <div className="space-y-4 lg:col-start-1 lg:col-span-1">
              <ActivityFeed path={props.match.url} site_id={id} />
            </div>
            <section className="space-y-4 lg:col-start-2 lg:col-span-3">
              <div className="bg-white px-4 pt-3 pb-5 shadow sm:rounded-lg sm:px-6">
                <SiteTabs path={props.match.url} />
                <div className="mt-5">
                  <TabRouter path={props.match.path} site={site} isLoading={isLoading} />
                </div>
              </div>
            </section>
          </div>
        </SidebarPage>
      </div>
    </div>
  )
}

export default SitePage
