import classNames from "classnames";

const StatsWrapper = (props) => {
  return (
    <div>
      {/* <h3 className="text-lg leading-6 font-medium text-gray-900">All-time Stats</h3> */}
      <dl className={classNames("mt-2 grid grid-cols-2 gap-5", props.classNames)}>
        {props.children}
      </dl>
    </div>
  )
}

export default StatsWrapper;