import classNames from 'classnames'
import SiteFilter from '../sites/SiteFilter'
import { noteTypeFilters } from '../../utils/utilityLibrary'

export default function TypeNoteFilter({ handleNoteTypeFilterChange, typeFilter, className }) {

  let filters = [{ id: '*', name: 'All Notes', color: 'bg-gray-400' }]
  filters.push(...noteTypeFilters)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        filters={filters}
        selected={typeFilter}
        setSelected={handleNoteTypeFilterChange}
      />
    </div>
  )
}
