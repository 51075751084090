import axiosConfig from '../axiosConfig'

const markFixed = (issueId, fixed, verified, fhid) => {

  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/siteRevisions/markFixed`, {
      issueId: issueId,
      fhid: fhid,
      fixed: fixed,
      verified: verified,
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err));
  })

}

export default markFixed;