import classNames from "classnames";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { useContext } from "react";

export default function Table({ data, columns, className = '', headerClassName = '', Context, LeadingIcon = false, TrailingIcon = false, hideHeader = false }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={classNames('shadow text-sm text-gray-90', className)}>
      {!hideHeader && <TableHeader className={headerClassName} columns={columns} TrailingIcon={TrailingIcon} LeadingIcon={LeadingIcon} Context={Context} />}
      {(!isLoading ? data : [...Array(3).keys()]).map((data, i) => (
        <TableRow data={data} Context={Context} i={i} key={i} columns={columns} LeadingIcon={LeadingIcon} TrailingIcon={TrailingIcon} />
      ))}
    </div>
  )
}