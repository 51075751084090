import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import SidebarPage from '../SidebarPage';
import LaunchScheduleTable from '../sites/LaunchScheduleTable';


export default class LaunchSchedule extends Component {

  render() {
    return (
      <SidebarPage docTitle='Launch Schedule | Tukios Website Admin'>
        <LaunchScheduleTable />
      </SidebarPage>
    )
  }
}