import { useState, useEffect } from "react"
import classNames from "classnames"
import { providerEmails, listAes, providers, tributeTechProviders } from '../../utils/utilityLibrary'
import axiosConfig from '../../axiosConfig'
import { toast } from 'react-toastify'
import { userEmail, isAdmin } from "../../utils/users"
import ContentShimmer from "react-content-shimmer"
import ReactTooltip from "react-tooltip"
import { ExclamationCircleIcon } from "@heroicons/react/solid"

const DomainRequest = ({ site }) => {

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formValues, setFormValues] = useState()

  const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 mb-5 text-gray-700 leading-tight focus:outline-none'

  useEffect(() => {
    setIsLoading(true)
    listAes().then(aes => {
      setFormValues({
        additionalDomains: '',
        providerEmail: providerEmails[tributeTechProviders.includes(site.provider) ? 'Tribute Tech' : site.provider] ?? providerEmails[providers.other],
        primaryDomain: site.apex.replace('https://', ''),
        other: '',
        additionalCc: '',
        fhEmail: site.email,
        aeEmail: aes.find((ae) => ae.user_id === site.aes_id)?.email ?? userEmail(),
        aeNotes: ''
      })
      setIsLoading(false)
    }).catch(error => {
      toast.error(error)
      setIsLoading(false)
    })
  }, [site])

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    axiosConfig.post('/api/domainRequest', {
      fhName: site.name,
      primaryDomain: formValues.primaryDomain,
      additionalDomains: formValues.additionalDomains,
      providerEmail: formValues.providerEmail,
      otherEmail: formValues.other,
      fhEmail: formValues.fhEmail,
      aeEmail: formValues.aeEmail,
      additionalCc: formValues.additionalCc,
      aeNotes: formValues.aeNotes,
      fhid: site.id,      
    })
      .then((data) => {
        setFormValues({
          additionalDomains: '', 
          additionalCc: '',        
          aeNotes: '' 
        })
        setIsSubmitting(false)
        toast.success('Domain Request Sent!')
      })
      .catch((error) => toast.error(error))
  }
  
  return (
    <>
      <ReactTooltip />
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2">
          <label className="col-span-2 border-b border-gray-200 font-semibold pb-2 mb-5">Funeral Home Information</label>
          <div>
            <label>Funeral Home Name</label>
            {!isLoading ?
              <input className="mt-1 block w-3/4 pl-3 pr-10 py-2 text-base bg-gray-200 border-gray-200 focus:outline-none sm:text-sm rounded shadow" type='text' value={site.name} disabled required />
              :
              <ContentShimmer style={{ width: "75%", height: "38px", borderRadius: "7px" }} />
            }
          </div>
          <div>
            <label>Funeral Home Email</label>
            {!isLoading ?
              <input className="mt-1 block w-3/4 pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow" type='email' value={formValues.fhEmail} onChange={(e) => setFormValues({ ...formValues, fhEmail: e.target.value })} required />
              :
              <ContentShimmer style={{ width: "75%", height: "38px", borderRadius: "7px" }} />
            }       
          </div>
          <div className="col-span-2 mt-3">
            <label>CC Additional Emails</label>
            <p className="text-sm font-medium text-gray-500">Seperate emails by using commas</p>
            {!isLoading ?
              <textarea
                className={classNames(inputClasses, 'h-36')}
                value={formValues.additionalCc}
                onChange={(e) => setFormValues({ ...formValues, additionalCc: e.target.value.split(',') })}
              />
              :
              <ContentShimmer style={{ width: "100%", height: "144px", borderRadius: "7px", marginBottom: "1.25rem" }} />
            }
          </div>
          <label className="col-span-2 border-b border-gray-200 font-semibold pb-2 my-5">Account Executive / Provider Information</label>
          <div>
            <label>Account Excecutive Email</label>
            {!isLoading ?
              <input 
                type="text" 
                className={`mt-1 block w-3/4 pl-3 pr-10 py-2 text-base ${isAdmin() ? '' : 'bg-gray-200'} border-gray-200 focus:outline-none sm:text-sm rounded shadow`}
                value={formValues.aeEmail} 
                disabled={!isAdmin()} 
                onChange={(e) => setFormValues({ ...formValues, aeEmail: e.target.value })}
                required 
              />
              :
              <ContentShimmer style={{ width: "75%", height: "38px", borderRadius: "7px" }} />
            }
          </div>
          <div>
            <div className="flex">
              <label classname="text-sm font-medium text-gray-500 mt-auto mb-auto">Current Provider</label>
              <ExclamationCircleIcon data-tip="Use Tribute Tech for CFS, FZ, FR, FT, FI, MKJ" className='h-5 w-5 text-gray-300 ml-1' />
            </div>
            {!isLoading ?
              <div>
                <dd className="w-full flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    <div className="sm:col-span-2">
                      <select
                        name="provider"
                        className="mt-1 block w-3/4 pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                        initialValues="chooseProvider"
                        value={formValues.providerEmail}
                        onChange={(e) => setFormValues({ ...formValues, providerEmail: e.target.value })}
                      >
                        <option value="" disabled>Select one</option>
                        {
                          Object.entries(providerEmails).map(([key, value], i) => (
                            <>
                              <option value={value} key={i}>{key}</option>
                            </>
                          ))
                        }
                      </select>
                    </div>
                    {
                      formValues.providerEmail === 'other' &&
                      <div className="mt-3">
                        <label className="text-sm font-medium text-gray-500">Provider Email</label>
                        <input
                          className="mt-1 block w-3/4 pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                          type='text'
                          value={formValues.other}
                          onChange={(e) => setFormValues({ ...formValues, other: e.target.value })}
                        />
                      </div>
                    }
                  </span>
                </dd>
              </div>
              :
              <ContentShimmer style={{ width: "75%", height: "38px", borderRadius: "7px" }} />
            }
          </div>
          <label className="col-span-2 border-b border-gray-200 font-semibold pb-2 my-5">Domain Information</label>
          <div className="mb-5">
            <label>Domain Being Requested</label>
            {!isLoading ?
              <input
                className="mt-1 block w-3/4 pl-3 pr-10 py-2 text-base border-gray-200 bg-gray-200 focus:outline-none sm:text-sm rounded shadow"
                type="text"
                value={formValues.primaryDomain}
                disabled={!isAdmin()}
                onChange={(e) => setFormValues({ ...formValues, primaryDomain: e.target.value })}
                required
              />
              :
              <ContentShimmer style={{ width: "75%", height: "38px", borderRadius: "7px" }} />
            }
          </div>
          <div className="col-span-2">
            <div >
              <label>Additional Domain(s)</label>
              <p className="text-sm font-medium text-gray-500">Seperate domains by using commas</p>
            </div>
            {!isLoading ?
              <textarea
                className={classNames(inputClasses, 'h-36')}
                value={formValues.additionalDomains}
                onChange={(e) => setFormValues({ ...formValues, additionalDomains: e.target.value.split(',') })}
              />
              :
              <ContentShimmer style={{ width: "100%", height: "144px", borderRadius: "7px", marginBottom: "1.25rem" }} />
            }
          </div>
          <div className="col-span-2">
            <div className="mb-2">
              <label>Notes</label>
              <p className="text-sm font-medium text-gray-500">Add a note to the top of the email</p>
            </div>
            {!isLoading ?
              <textarea
                className={classNames(inputClasses, 'h-36')}
                value={formValues.aeNotes}
                onChange={(e) => setFormValues({ ...formValues, aeNotes: e.target.value })}
              />
              :
              <ContentShimmer style={{ width: "100%", height: "144px", borderRadius: "7px", marginBottom: "1.25rem" }} />
            }
          </div>
          <button
            type="submit"
            disabled={isSubmitting || isLoading}
            className={classNames('block w-fit items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white col-start-2 justify-self-end', isSubmitting || isLoading ? "bg-gray-500" : "bg-tukios hover:bg-tukios-dark focus:outline-none")}
          >
            Send Request
          </button>
        </div>
      </form>
    </>
  )
}

export default DomainRequest