import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';

export default function SdrSiteFilter({ Context, className }) {

  const { sdrs, anySdr, sdrFilter, handleSdrChange } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        placeholder={anySdr}
        filters={sdrs}
        selected={sdrFilter}
        setSelected={handleSdrChange}
      />
    </div>
  )
}
