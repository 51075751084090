import React, { useState } from 'react';
import SidebarPage from '../SidebarPage';
import IconTabs from '../IconTabs';
import { isAdmin, isScrapeMaster } from '../../utils/users';
import { DocumentSearchIcon, ViewListIcon } from '@heroicons/react/solid';
import ScrapeRequestTable from './ScrapeRequestsTable';
import ScrapeTable from '../../utils/scrape table/ScrapeTable';


export default function ScrapePage() {

  const tabs = [
    {
      name: 'Scrape Requests',
      icon: DocumentSearchIcon
    }
  ]
  
  if (isAdmin()) tabs.unshift(
    {
      name: 'Scrape Tasks',
      icon: ViewListIcon
    }
  )
  
  const [selectedTab, setSelectedTab] = useState(isScrapeMaster() ? tabs[1] : tabs[0])

  return (
    <SidebarPage docTitle='Scrapes | Tukios Website Admin'>
      {isAdmin() &&
        <IconTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      }
      {selectedTab.name == 'Scrape Tasks' &&
        <ScrapeTable />
      }
      {selectedTab.name == 'Scrape Requests' &&
        <ScrapeRequestTable />
      }
    </SidebarPage>
  )

}