import classNames from "classnames";
import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";

export default function SitePagesPath({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={classNames("flex items-center relative", className)}>
      {!isLoading ?
        <>
          {data.path}
        </>
        :
        <ContentShimmer style={{ height: '14px', width: '150px', borderRadius: '7px' }} />
      }
    </div>
  )
}