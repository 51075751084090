import classNames from 'classnames'
import { scrapeStatusBadgeColors } from '../../utils/utilityLibrary'

const ScrapeStatusBadge = ({ status,  }) => {

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            }
        )
    }

    return (
        <span className={classNames('px-3 inline-flex leading-5 rounded-full text-xs', scrapeStatusBadgeColors[status])}>
            {toTitleCase(status)}
        </span>
    )
}

export default ScrapeStatusBadge