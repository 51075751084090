import classNames from 'classnames';
import React from 'react'

export default function TableRow({ data, Context, i, columns, LeadingIcon = false, TrailingIcon = false }) {

  return (
    <div className={classNames("py-3 px-4 lg:flex-col lg:block lg:items-center", i % 2 === 0 ? 'bg-white' : 'bg-gray-50')}>
      <div className="flex-1 flex items-center">
        {
          LeadingIcon && <LeadingIcon data={data} Context={Context} />
        }
        <div className="flex-1 px-1 lg:flex lg:gap-4 items-center">
          {columns.map((column, i) => (
            <column.Component key={i} data={data} Context={Context} className={column.className} />
          ))}
        </div>
        {
          TrailingIcon && <TrailingIcon data={data} Context={Context} />
        }
      </div>
    </div>
  )
}