import { faTrello } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExclamationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactTooltip from "react-tooltip";

export default function SiteID({ data, className, Context }) {

  let { isLoading } = useContext(Context)
  let history = useHistory()

  return (
    <>
      <ReactTooltip />
      <div className={classNames("flex items-center relative", className)}>
        {!isLoading ?
          <>
            {
              data?.trello_card_id &&
              <a className="mr-1 text-md hover:text-gray-700" data-tip="Open Trello card" target="_blank" href={`https://trello.com/c/${data?.trello_card_id}`}>
                <FontAwesomeIcon icon={faTrello} />
              </a>
            }
            {data?.id}
            {
              (data?.urgent_build == 1) &&
              <>
                <ExclamationCircleIcon data-tip="Urgent build requested" className='h-5 w-5 text-red-500 ml-1' />
              </>
            }
            {
              (data?.hosted_obit_site == 1) &&
              <>
                <QuestionMarkCircleIcon data-tip="Obit only site" className='h-5 w-5 text-blue-500 ml-1' />
              </>
            }
            {
              data?.revisions_needed != 0 && data?.revisions_needed &&
              <button
                onClick={() => {
                  history.push({
                    pathname: `/sites/id/${data.id}/qa`,
                    state: { site: data }
                  })
                }}
                data-tip={`${data?.revisions_needed} change${data?.revisions_needed > 1 ? 's' : ''} needed`}
                className="hover:opacity-50 ml-1 inline-flex cursor-pointer items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full dark:border-gray-900">{data?.revisions_needed}
              </button>
            }
            {
              data?.revisions_needed == 0 && data?.verification_needed != 0 && data?.verification_needed &&
              <button
                onClick={() => {
                  history.push({
                    pathname: `/sites/id/${data.id}/qa`,
                    state: { site: data }
                  })
                }}
                data-tip={`${data?.verification_needed} change${data?.verification_needed > 1 ? 's' : ''} needing verification`}
                className="hover:opacity-50 ml-1 inline-flex cursor-pointer items-center justify-center w-6 h-6 text-xs font-bold text-white bg-blue-500 border-2 border-white rounded-full dark:border-gray-900">{data?.verification_needed}
              </button>
            }
          </> :
          <ContentShimmer style={{ height: '14px', width: '50px', borderRadius: '7px' }} />
        }
      </div>
    </>
  )
}