import classNames from "classnames";
import { useContext, useState } from "react";
import ContentShimmer from "react-content-shimmer";
import { toast } from "react-toastify"
import axiosConfig from "../../../axiosConfig"

export default function SitePagesDescription({ data, className, Context }) {

  let { isLoading, edit, handleChange, pages, site } = useContext(Context)
  const [metaDescriptionPreview, setMetaDescriptionPreview] = useState()
  const [usingPreview, setUsingPreview] = useState(false)

  const stockPages = ["FREQUENT QUESTIONS", "TESTIMONIALS", "PRIVACY POLICY", "WHEN DEATH OCCURS", "THE GRIEVING PROCESS", "OUR FACILITIES", "TRADITIONAL SERVICES", "PRE-PLANNING CHECKLIST", "OUR STAFF", "PLANNING AHEAD", "OBITUARY LISTING", "DEATH AWAY FROM HOME", "MAKE A PAYMENT", "SERVICES OVERVIEW", "SOCIAL SECURITY BENEFITS", "CONTACT US", "OBITUARY NOTIFICATIONS", "HOME", "WHY CHOOSE US", "IMMEDIATE NEED", "SEND FLOWERS", "ONLINE PREPLANNING FORM", "MERCHANDISE", "CREMATION SERVICES", "OUR LOCATION", "GRIEF RESOURCES", "CHILDREN & GRIEF", "VETERANS SERVICES"]

  const generateMeta = () => {
    setUsingPreview(false)
    axiosConfig.post('/api/chat-gpt', {
      fhName: site.name,
      location: site.address,
      city: site.city,
      state: site.sate,
      pageTitle: data.title,
    })
      .then((res) => {
        setMetaDescriptionPreview(res.data.data[0].message.content)
      })
  }

  const savePreviewAsValue = () => {
    toast.success('Preview Saved!')
    setUsingPreview(true)
    data.seo.description = metaDescriptionPreview
  }

  return (
    <div className={classNames("flex items-center relative", className)}>
      {!isLoading ?
        <div className="flex flex-col w-full">
          {!edit ?
            <div>{data.seo.description}</div>
            :
            <div className="flex">
              <div className="w-full mr-5">
                <textarea
                  type="text"
                  name="description"
                  data-index={pages.indexOf(data)}
                  className={`text-sm w-full h-36 shadow appearance-none border border-gray-200 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none`}
                  value={data.seo.description}
                  onChange={handleChange}
                />
                {(data.seo.description && data.seo.description.length) &&
                  <>
                    <div className={classNames("text-xs mt-1", data.seo.description.length > 160 ? 'text-red-500' : 'text-gray-400')}>Character Count: {data.seo.description.length}</div>
                  </>
                }
              </div>
              {!stockPages.includes(data?.title?.toUpperCase()) &&
                <div className="w-full">
                  <textarea
                    type="text"
                    className={`text-sm w-full h-36 shadow appearance-none border ${usingPreview ? "border-green-600" : "border-gray-200"}  rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none`}
                    value={metaDescriptionPreview}
                    onChange={(e) => setMetaDescriptionPreview(e.target.value)}
                  />
                  {(metaDescriptionPreview && metaDescriptionPreview.length) &&
                    <div className={classNames("text-xs mt-1", metaDescriptionPreview.length > 160 ? 'text-red-500' : 'text-gray-400')}>Character Count: {metaDescriptionPreview.length}</div>
                  }
                </div>
              }
            </div>
          }

          {edit && !stockPages.includes(data?.title?.toUpperCase()) &&
            <div className="flex">
              <div className="w-full mr-5"></div>
              <div className="flex w-full justify-center mt-2">
                <button onClick={generateMeta} className="w-full mr-5 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-tukios hover:bg-tukios-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tukios">Generate</button>
                <button onClick={savePreviewAsValue} className={`${metaDescriptionPreview?.length > 0 ? "bg-tukios hover:bg-tukios-dark" : "bg-gray-500"} w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tukios`} disabled={!metaDescriptionPreview}>Save</button>

              </div>
            </div>
          }
        </div>
        :
        <ContentShimmer style={{ height: '14px', width: '250px', borderRadius: '7px' }} />
      }
    </div>
  )
}