import classNames from "classnames";
import { Link } from "react-router-dom";

export default function ScrapeFuneralHomeId({ data, className }) {
  return (
    <div className={classNames("flex items-center relative", className)}>
        <Link to={{
          pathname: data?.kwargs?.legacy_fhid ? `/sites/id/${data?.kwargs?.legacy_fhid}/scrape` : ''
        }}>
          {data?.kwargs?.legacy_fhid ?? '-'}
        </Link>
    </div>
  )
}