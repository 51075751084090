import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';
import { ScrapeTypeFilters } from '../utilityLibrary';

export default function ScrapeTypeFilter({ Context, className }) {

  let { scrapeTypeFilter, handleScrapeTypeChange } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        filters={ScrapeTypeFilters}
        selected={scrapeTypeFilter}
        setSelected={handleScrapeTypeChange}
      />
    </div>
  )
}
