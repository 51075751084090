import { UserAddIcon } from "@heroicons/react/solid"
import classNames from "classnames"
import { useContext } from "react"
import { toast } from "react-toastify"
import DropdownWithAvatars from "../../../components/ui/DropdownWithAvatars"
import logActivity from "../../logActivity"
import { getUserObject, isAdmin, isFlorist } from "../../users"
import ContentShimmer from "react-content-shimmer"
import assignFlorist from "../../assignFlorist"

export default function SiteAssignFlorist({ data, className, Context }) {

  let { sites, setSites, florists, assignLoading, setAssignLoading, isLoading } = useContext(Context)

  const setSiteFlorist = (fhid, e) => {

    setAssignLoading(true)

    let floristId = e.user_id == 'none' ? null : e.user_id

    assignFlorist(fhid, floristId).then((res) => {
      logActivity({
        message: `${e.name} assigned to site`,
        fhId: fhid,
        logType: 'FLORIST'
      })

      let currentSites = [...sites]
      let currentSiteIndex = currentSites.map(s => s.id).indexOf(fhid)
      let currentSite = { ...currentSites[currentSiteIndex] }

      currentSite.florist_id = e.id == 'none' ? null : e.id
      currentSite.florist_name = e.id == 'none' ? null : e.name
      currentSite.florist_image = e.id == 'none' ? null : e.image
      currentSites[currentSiteIndex] = currentSite

      setSites(currentSites)

      toast.success(e.name == 'Not assigned' ? 'Unassigned' : `${e.name} assigned to site!`)
      if (res !== true) toast.warn(res)
      setAssignLoading(false)

    })
      .catch((err) => {
        toast.error('Error updating site floral specialist')
        console.log(err)
        setAssignLoading(false)
      })
  }

  return (
    <div className={classNames("text-sm text-gray-900 flex gap-2", className)}>
      {(!isLoading && !assignLoading) ?
        <>
          {isFlorist() ?
            <button
              onClick={() => setSiteFlorist(data.id, getUserObject())}
              type="button"
              className={classNames("w-8 h-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-tukios-green-dark focus:outline-none", isFlorist() ? 'hover:bg-tukios-green' : '')}
            >
              <UserAddIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            : <></>}
          <div
            className='w-full'
          >
            {
              florists !== undefined &&
              <DropdownWithAvatars
                people={florists}
                current={data.florist_id ? {
                  name: data.florist_name,
                  image: data.florist_image
                } : null}
                handleSelection={(e) => setSiteFlorist(data.id, e)}
                disabled={isAdmin() ? false : !isFlorist()}
              />
            }
          </div>
        </> : <ContentShimmer style={{ height: '34px', width: '200px', borderRadius: '7px' }} />
      }
    </div>
  )
}