import { createContext, useContext, useState } from "react"
import { createScrapeRequest } from "../utils/scrapeRequest"
import logActivity from "../utils/logActivity"
import { activityType } from "../utils/utilityLibrary"

const ScrapeRequestFormContext = createContext()

const ScrapeRequestFormProvider = ({ children }) => {

  let [formValues, setFormValues] = useState({
    type: "",
    url: '',
    note: '',
  })

  let submitScrapeRequest = (fhid) => {
    return new Promise((resolve, reject) => {
      createScrapeRequest(fhid, formValues.type, formValues.url, formValues.note)
        .then(() => {
          resolve()
        }).catch(err => {
          reject(err)
        })
    })
  }

  const checkForScrapeRequestErrors = () => {
    if (!formValues.type) {
      return 'Missing \'Scrape Type\''
    }
    else {
      return false
    }
  }

  return (
    <ScrapeRequestFormContext.Provider value={{ formValues, setFormValues, submitScrapeRequest, checkForScrapeRequestErrors }}>
      {children}
    </ScrapeRequestFormContext.Provider>
  )
}

const useScrapeRequestForm = () => {
  return useContext(ScrapeRequestFormContext)
}

export { ScrapeRequestFormProvider, useScrapeRequestForm }
