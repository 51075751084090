import classNames from "classnames"
import { useContext } from "react"
import ContentShimmer from "react-content-shimmer"
import { ModalContext } from "../../../hooks/ModalContext"
import ReadMoreDialog from "../../../components/pages/ViewMoreDialog"

const MAX_CHARACTERS = 150

export default function ScrapeRequestNote({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  let { handleModal } = useContext(ModalContext)

  const readMore = (data) => {
    handleModal(
      <ReadMoreDialog data={data} />
    )
  }

  const truncatedNote = data.note
    ? data.note.length > MAX_CHARACTERS
      ? data.note.slice(0, MAX_CHARACTERS) + '...'
      : data.note
    : '-'

  return (
    <>
      <div className={classNames(className)}>
        {!isLoading ?
          <>
            {
              data.note ?
                <>
                  {truncatedNote}
                  {
                    data.note.length > MAX_CHARACTERS &&
                    <button
                      onClick={() => readMore(data)}
                      className="text-blue-600 hover:text-blue-500"
                    >
                      &nbsp;Read More
                    </button>
                  }
                </> : <div className="lg:text-center">-</div>
            }
          </>
          : <ContentShimmer style={{ height: '14px', width: '100px', borderRadius: '7px', marginLeft: 'auto', marginRight: 'auto' }} />
        }
      </div>
    </>
  )
}