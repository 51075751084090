import classNames from "classnames"
import { noteTypes } from "../../utils/utilityLibrary"

export default function NoteType({ handleNoteTypeChange, defaultType, className }) {

  return (
    <div className={classNames("flex gap-4 w-fit", className)} style={{ "width": "fit-content" }}>
      {
        Object.values(noteTypes).map(type => (
          <div className="flex items-center w-fit">
            <input
              id={type}
              name="newType"
              type="radio"
              onClick={handleNoteTypeChange}
              defaultChecked={type == defaultType}
              className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
            />
            <div>
              <label
                htmlFor="Instant"
                className="ml-1 block text-sm font-medium text-gray-700"
              >
                {type}
              </label>
            </div>
          </div>
        ))
      }
    </div>
  )
}
