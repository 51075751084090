import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';
import { ScrapeProviderFilters } from '../utilityLibrary';

export default function ScrapeProviderFilter({ Context, className }) {

  let { scrapeProviderFilter, handleScrapeProviderChange } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        filters={ScrapeProviderFilters}
        selected={scrapeProviderFilter}
        setSelected={handleScrapeProviderChange}
      />
    </div>
  )
}
