import { LockClosedIcon } from "@heroicons/react/solid";

const AdminOnlyTab = () => {
  return (
    <div
      type="button"
      className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center"
    >
      <LockClosedIcon className="mx-auto h-12 w-12 text-gray-400" />
      <span className="mt-2 block text-sm font-medium text-gray-900">Admin Only</span>
    </div>
  )
}

export default AdminOnlyTab;