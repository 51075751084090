import axiosConfig from '../axiosConfig';

const updateIssue = (issueId, issue, severity, status) => {

  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/siteRevisions/update`, {
      issueId: issueId,
      issue: issue,
      severity: severity,
      status: status
    })
    .then((res) => resolve())
    .catch((err) => reject(err));
  })

}

export default updateIssue;