import classNames from 'classnames';

const badgeColors = {
    'High': 'bg-red-100 text-red-800',
    'Medium': 'bg-sky-100 text-sky-800',
    'Low': 'bg-green-100 text-green-800',
}

const SeverityBadge = ({ severity }) => {

    const defaultClasses = ['px-3', 'leading-5', 'rounded-full', 'text-xs', 'mx-auto', 'block', 'w-min'];

    return (
        <span className={classNames(...defaultClasses, badgeColors[severity])}>
            {severity}
        </span>
    )
}

export default SeverityBadge;