import { createContext, useContext, useEffect, useRef, useState } from "react"
import listTasks from "../listTasks"
import Table from "../table/Table"
import ScrapeTaskID from "./columns/ScrapeTaskId"
import ScrapeTaskIdLoading from "./columns/loading/ScrapeTaskIdLoading"
import ScrapePagination from "./ScrapePagination"
import ScrapeFuneralHomeId from "./columns/ScrapeFuneralHomeId"
import ScrapeFuneralHomeIdLoading from "./columns/loading/ScrapeFuneralHomeIdLoading"
import ScrapeStatus from "./columns/ScrapeStatus"
import ScrapeStatusLoading from "./columns/loading/ScrapeStatusLoading"
import ScrapeOrganizationId from "./columns/ScrapeOrganizationId"
import ScrapeScheduledDate from "./columns/ScrapeScheduledDate"
import ScrapeDateLoading from "./columns/loading/ScrapeDateLoading"
import ScrapeScheduledTime from "./columns/ScrapeScheduledTime"
import ScrapeTimeLoading from "./columns/loading/ScrapeTimeLoading"
import ScrapeType from "./columns/ScrapeType"
import ScrapeNameLoading from "./columns/loading/ScrapeFuneralHomeIdLoading copy"
import ScrapeSearch from "./ScrapeSearch"
import { debounce } from "lodash"
import { ScrapeProviderFilters, ScrapeStatusFilters, ScrapeTypeFilters } from "../utilityLibrary"
import ScrapeStartPage from "./columns/ScrapeStartPage"
import ScrapeEndPage from "./columns/ScrapeEndPage"
import ScrapeUrl from "./columns/ScrapeUrl"
import ScrapeProvider from "./columns/ScrapeProvider"
import ScrapeActions from "./columns/ScrapeActions"
import { toast } from "react-toastify"
import ScrapeStatusFilter from "./ScrapeStatusFilter"
import ScrapeTypeFilter from "./ScrapeTypeFilter"
import ScrapeProviderFilter from "./ScrapeProviderFilter"
import killTask from "../killTask"
import ViewResultDialog from "../../components/pages/ViewResultDialog"
import { ModalContext } from "../../hooks/ModalContext"
import ScrapeActionsLoading from "./columns/loading/ScrapeActionsLoading"
import ScrapeProviderLoading from "./columns/loading/ScrapeProviderLoading"
import ScrapeUrlLoading from "./columns/loading/ScrapeUrlLoading"
import ScrapePageLoading from "./columns/loading/ScrapePageLoading"
import ScrapeThreeDotMenu from "./icons/ScrapeThreeDotMenu"
import WatchScrapeDialog from "../../components/pages/WatchScrapeDialog"

const ScrapeContext = createContext()

export default function ScrapeTable() {

  let [page, setPage] = useState(1)
  let [perPage, setPerPage] = useState(20)
  let [tasks, setTasks] = useState([])
  let [searchString, setSearchString] = useState("")
  let [isLoading, setIsLoading] = useState(true)
  let [totalScrapes, setTotalScrapes] = useState(0)
  let [scrapeStatusFilter, setScrapeStatusFilter] = useState(ScrapeStatusFilters[0])
  let [scrapeTypeFilter, setScrapeTypeFilter] = useState(ScrapeTypeFilters[0])
  let [scrapeProviderFilter, setScrapeProviderFilter] = useState(ScrapeProviderFilters[0])
  let searchRef = useRef()

  let { handleModal } = useContext(ModalContext);

  const updateTasks = () => {
    setIsLoading(true)
    listTasks({ page, perPage, searchString, scrapeStatusFilter, scrapeTypeFilter, scrapeProviderFilter }).then(tasks => {
      setTasks(tasks)
      setTotalScrapes(tasks.totalTasks)
      setIsLoading(false)
    })
  }

  const revokeTask = (taskId, legacyFhid) => {
    killTask(taskId, legacyFhid).then(() => {
      toast.success('Task successfully revoked')
    }).catch(err => {
      toast.error('Unable to revoke task')
    })
  }

  const viewResult = (result) => {
    handleModal(
      <ViewResultDialog result={result} />
    )
  }

  const watchScrape = (data) => {
    handleModal(
      <WatchScrapeDialog data={data} />
    )
  }

  const search = () => {
    setPage(1)
    setSearchString(searchRef.current.value)
  }

  const handleScrapeStatusChange = (e) => {
    setPage(1)
    setScrapeStatusFilter(e);
  }

  const handleScrapeTypeChange = (e) => {
    setPage(1)
    setScrapeTypeFilter(e);
  }

  const handleScrapeProviderChange = (e) => {
    setPage(1)
    setScrapeProviderFilter(e)
  }

  const clearFilters = () => {
    searchRef.current.value = ""
    setSearchString("")
    setScrapeStatusFilter(ScrapeStatusFilters[0])
    setScrapeTypeFilter(ScrapeTypeFilters[0])
    setScrapeProviderFilter(ScrapeProviderFilters[0])
    setPage(1)
  }

  const handleSearch = debounce(search, 300)

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  }

  useEffect(() => {
    updateTasks()
  }, [page, perPage, searchString, scrapeStatusFilter, scrapeTypeFilter, scrapeProviderFilter])

  const filters = [
    {
      Component: ScrapeSearch,
      className: 'w-full lg:1/5'
    },
    {
      Component: ScrapeStatusFilter,
      className: 'w-full lg:1/5'
    },
    {
      Component: ScrapeTypeFilter,
      className: 'w-full lg:1/5'
    },
    {
      Component: ScrapeProviderFilter,
      className: 'w-full lg:1/5'
    }
  ]

  const columns = [
    {
      label: "FHID",
      Component: ScrapeFuneralHomeId,
      className: 'lg:w-20'
    },
    {
      label: "Org ID",
      Component: ScrapeOrganizationId,
      className: 'lg:w-20'
    },
    {
      label: "Task ID",
      Component: ScrapeTaskID,
      className: 'lg:w-2/12'
    },
    {
      label: "Type",
      Component: ScrapeType,
      className: 'lg:w-20'
    },
    {
      label: "Status",
      Component: ScrapeStatus,
      className: 'lg:w-24 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Domain",
      Component: ScrapeUrl,
      className: 'lg:w-52'
    },
    {
      label: "Provider",
      Component: ScrapeProvider,
      className: 'lg:w-32 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Scheduled Date",
      Component: ScrapeScheduledDate,
      className: 'lg:w-1/12 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Scheduled Time",
      Component: ScrapeScheduledTime,
      className: 'lg:w-32 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Start Page",
      Component: ScrapeStartPage,
      className: 'lg:w-24 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "End Page",
      Component: ScrapeEndPage,
      className: 'lg:w-24 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Actions",
      Component: ScrapeActions,
      className: 'lg:w-44 lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  const loadingColumns = [
    {
      label: "FHID",
      Component: ScrapeFuneralHomeIdLoading,
      className: 'lg:w-20'
    },
    {
      label: "Org ID",
      Component: ScrapeFuneralHomeIdLoading,
      className: 'lg:w-20'
    },
    {
      label: "Task ID",
      Component: ScrapeTaskIdLoading,
      className: 'lg:w-2/12'
    },
    {
      label: "Type",
      Component: ScrapeFuneralHomeIdLoading,
      className: 'lg:w-20'
    },
    {
      label: "Status",
      Component: ScrapeStatusLoading,
      className: 'lg:w-24 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Domain",
      Component: ScrapeUrlLoading,
      className: 'lg:w-52'
    },
    {
      label: "Provider",
      Component: ScrapeProviderLoading,
      className: 'lg:w-32 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Scheduled Date",
      Component: ScrapeDateLoading,
      className: 'lg:w-1/12 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Scheduled Time",
      Component: ScrapeStatusLoading,
      className: 'lg:w-32 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Start Page",
      Component: ScrapePageLoading,
      className: 'lg:w-24 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "End Page",
      Component: ScrapePageLoading,
      className: 'lg:w-24 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Actions",
      Component: ScrapeActionsLoading,
      className: 'lg:w-44 lg:flex lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  return (
    <div style={{ minWidth: '1533px' }}>
      <ScrapeContext.Provider
        value={{
          tasks,
          setTasks,
          totalScrapes,
          page,
          setPage,
          perPage,
          setPerPage,
          goToPage,
          clearFilters,
          handleSearch,
          searchRef,
          scrapeStatusFilter,
          handleScrapeStatusChange,
          revokeTask,
          viewResult,
          watchScrape,
          scrapeTypeFilter,
          handleScrapeTypeChange,
          scrapeProviderFilter,
          handleScrapeProviderChange,
        }}>
        <div className="flex flex-col gap-1 lg:flex-row justify-between items-start">
          {filters.map((filter, i) => (
            <filter.Component Context={ScrapeContext} className={filter.className} />
          ))}
        </div>
        {filters.length != 0 &&
          <button
            onClick={clearFilters}
            className='text-sm text-gray-400 mb-1'
          >
            Clear filters
          </button>
        }
        {!isLoading ? (
          <Table data={tasks.data} columns={columns} Context={ScrapeContext} TrailingIcon={ScrapeThreeDotMenu} />
        ) : (
          <Table data={[...Array(3).keys()]} columns={loadingColumns} Context={ScrapeContext} TrailingIcon={ScrapeThreeDotMenu} />
        )
        }
        <ScrapePagination Context={ScrapeContext} />
      </ScrapeContext.Provider>
    </div>
  )
}