import AeSiteFilter from "../../utils/site table/AeSiteFilter"
import SiteActions from "../../utils/site table/columns/SiteActions"
import SiteID from "../../utils/site table/columns/SiteID"
import SiteName from "../../utils/site table/columns/SiteName"
import ThreeDotMenu from "../../utils/site table/icons/ThreeDotMenu"
import CorporationSiteFilter from "../../utils/site table/CorporationSiteFilter"
import SiteSearch from "../../utils/site table/SiteSearch"
import SitesTable from "../../utils/site table/SitesTable"
import CareSiteFilter from "../../utils/site table/CareSiteFilter"
import SiteStatus from "../../utils/site table/columns/SiteStatus"
import SiteProvider from "../../utils/site table/columns/SiteProvider"
import SiteInfo from "../../utils/site table/columns/SiteInfo"
import SiteLaunchDate from "../../utils/site table/columns/SiteLaunchDate"
import SiteMembers from "../../utils/site table/columns/SiteMembers"
import SiteRooftops from "../../utils/site table/columns/SiteRooftops"
import SiteCareDropdown from "../../utils/site table/columns/SiteCareDropdown"
import SiteAssignCare from "../../utils/site table/columns/SiteAssignCare"
import { siteStatuses } from "../../utils/utilityLibrary"

const PreLiveSeoTable = () => {

  const filters = [
    {
      Component: SiteSearch,
      className: 'w-full lg:1/4'
    },
    {
      Component: AeSiteFilter,
      className: 'w-full lg:1/4'
    },
    {
      Component: CareSiteFilter,
      className: 'w-full lg:1/4'
    },
    {
      Component: CorporationSiteFilter,
      className: 'w-full lg:1/4'
    },
  ]

  const columns = [
    {
      label: "ID",
      Component: SiteID,
      className: 'lg:w-32'
    },
    {
      label: "Name",
      Component: SiteName,
      className: 'lg:w-1/6'
    },
    {
      label: "Members",
      Component: SiteMembers,
      className: 'lg:w-40 lg:flex lg:justify-center'
    },
    {
      label: "Launch Date",
      Component: SiteLaunchDate,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    {
      label: "Info",
      Component: SiteInfo,
      className: 'lg:w-1/12 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Prev. Provider",
      Component: SiteProvider,
      className: 'lg:w-1/6 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: 'Assign',
      Component: SiteAssignCare,
      className: 'lg:w-1/6'
    },
    {
      label: "Actions",
      Component: SiteActions,
      className: 'lg:w-44 lg:flex lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  return (
    <>
      <div style={
        {
          minWidth: window.innerWidth >= 1024 ? '1400px' : '100%',
        }
      }>
        <SitesTable
          filters={filters}
          columns={columns}
          defaultSiteParams={{
            preLiveSeo: true,
            label: Object.values(siteStatuses).filter(status => ![siteStatuses.lost, siteStatuses.test, siteStatuses.rebuild, siteStatuses.readyForDesign, siteStatuses.inDesign].includes(status))
          }}
          trailingIcon={ThreeDotMenu}
        />
      </div>
    </>
  )
}

export default PreLiveSeoTable
