import ContentShimmer from 'react-content-shimmer'

const RevisionsLoader = () => {
  return (
    Array.from({ length: 1 }, (e, i) => (
      <li
        key={i}
        className="relative py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
      >
        <div className="flex gap-3">
          <div className="flex-col align-center">
            {/* <img className="mb-1 max-w-none h-14 w-14 rounded-full ring-2 ring-white my-auto" /> */}
            <ContentShimmer
              style={{
                width: "56px",
                height: "56px",
                borderRadius: "9999px"
              }}
            />
            {/* <SeverityBadge severity={issue.severity} /> */}
            <ContentShimmer
              style={{
                width: "50px",
                height: "16px",
                borderRadius: "9999px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: '3px'
              }}
            />
          </div>
          <div className="flex-col justify-between">
            <div className="min-w-0 flex-1 flex flex-col">
              <p className="truncate text-sm font-medium text-gray-900">
                <ContentShimmer
                  style={{
                    width: '90px',
                    height: '16px',
                    borderRadius: '7px'
                  }}
                /></p>
            </div>
            <p className="text-sm text-gray-600 line-clamp-2">
              <ContentShimmer
                style={{
                  width: '500px',
                  height: '60px',
                  marginTop: '5px',
                  borderRadius: '7px'
                }}/>
            </p>
          </div>
        </div>
      </li>
    ))
  )
}

export default RevisionsLoader;