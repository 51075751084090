import {useState} from "react"
import {CheckCircleIcon} from "@heroicons/react/outline"
import axiosConfig from '../axiosConfig'


const GoDaddyDomainSearch = () => {
    const [domain, setDomain] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [result, setResult] = useState()
    const [suggestions, setSuggestions] = useState([])

    const handleDomainSearch = (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        axiosConfig.post('api/godaddy/domain-availability', {enteredDomain: domain, tlds: ['.com']})
            .then((res) => {
                setIsSubmitting(false)
                setResult(res.data.data)
                setSuggestions(res.data.suggestionData)
            })
            .catch((error) => {
                setIsSubmitting(false)
                console.log(error)
            })
    }
    return (
        <div className="bg-white mt-10 px-4 pt-3 pb-5 shadow sm:rounded-lg sm:px-6">
            {!result ? (
                <form onSubmit={handleDomainSearch}>
                    <div className="flex flex-col w-full">
                        <label className="text-lg font-medium text-gray-500 mb-3">Domain Availability Search</label>
                        <input onChange={(e) => setDomain(e.target.value)} className="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 mb-5 text-gray-700 leading-tight focus:outline-none" />
                        <div className="flex flex-col items-end">
                            <button type="submit" className={`block w-fit items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white col-start-2 justify-self-end' ${isSubmitting ? "bg-gray-500" : "bg-tukios hover:bg-tukios-dark focus:outline-none"}`}>Search</button>
                        </div>
                    </div>
                </form>
            ) : (
                <div className="flex flex-col justify-center items-center w-ful p-4">
                    {!result.available ?
                        <div className="w-full">
                            <div className="w-full flex justify-center">
                                <div className="text-xl font-medium text-gray-500 mb-5">{domain} is NOT available.</div>
                            </div>
                            <div className="text-xl font-medium text-gray-500 mb-5"> Domain Suggestions</div>
                            <div>
                                <ul className="grid grid-cols-5">
                                    {suggestions && suggestions.map((suggestion) => (
                                        <li>{suggestion.domain}</li>
                                    ))}
                                </ul>
                            <div className="w-full flex justify-end">
                                <button onClick={() => setResult(null)} className={'block w-fit mt-5 items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white col-start-2 justify-self-end bg-tukios hover:bg-tukios-dark focus:outline-none'}>Search Again</button>
                            </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="flex items-center text-xl font-medium text-gray-500 mb-5">
                                {domain} is Available! <CheckCircleIcon className="ml-2 w-7 h-7 text-green-500" />
                            </div>
                            <div className="text-xl font-medium text-gray-500 mb-5">
                                Contact launch specialist for purchase
                            </div>
                            <div className="flex">
                                <button onClick={() => setResult(null)} className={`block w-fit items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white col-start-2 justify-self-end' ${isSubmitting ? "bg-gray-500" : "bg-tukios hover:bg-tukios-dark focus:outline-none"}`}>Search Again</button>
                            </div>
                        </>
                    }
                </div>
            )}
        </div>
    )
}

export default GoDaddyDomainSearch