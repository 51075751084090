import axiosConfig from '../axiosConfig';

const getChecklist = (fhid, typeId) => {

  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/siteChecklist/checklist', {
      params: {
        fhid: fhid,
        typeId: typeId
      }
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err));
  })

}

export default getChecklist;