import axiosConfig from '../axiosConfig'

const createScrapeRequest = (fhid, type, url, note) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/scrapeRequest/${fhid}/create`, {
      type: type,
      url: url,
      note: note
    })
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

const markScrapeRequestComplete = (fhid, type, requestedBy, additionalNote) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`api/scrapeRequest/${fhid}/markComplete`, {
      type: type,
      additionalNote: additionalNote,
      requestedBy: requestedBy
    })
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export { createScrapeRequest, markScrapeRequestComplete }