import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';
import { statusFilters } from '../utilityLibrary';
import { filter } from 'lodash';

export default function StatusSiteFilter({ Context, className }) {

  let { statusFilter, handleStatusChange, defaultSiteParams } = useContext(Context)

  let filters = [{id: '*', name: 'Any status', color: 'bg-gray-400'}]
  filters.push(...statusFilters.filter(filter => defaultSiteParams?.label ? defaultSiteParams.label.includes(filter.name) : statusFilter))

  return (
    <div className={classNames(className, 'w-full')}>
          <SiteFilter
            filters={filters}
            selected={statusFilter}
            setSelected={handleStatusChange} 
          />
    </div>
  )
}
