
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { ModalContext } from '../../hooks/ModalContext'
import { useContext } from 'react'

const PreviousVideoProviderConfirmation = ({ addon, e, setup, commit, setupAddon, commitAddon }) => {

    let { handleModal } = useContext(ModalContext);

    const handleYes = () => {
        e.target.checked = true
        if(setup) {
            setupAddon(e, addon, true, true)
        }
        else if(commit) {
            commitAddon(e, addon, true, true)
        }
        handleModal(false)
    }
    
    const handleNo = () => {
        e.target.checked = true
        if(setup) {
            setupAddon(e, addon, true, false)
        }
        else if(commit) {
            commitAddon(e, addon, true, false)
        }
        handleModal(false)
    }

    return (
        <>
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => handleModal(false)}
                >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="sm:flex sm:items-center">
                <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Tukios Videos
                    </Dialog.Title>
                </div>
            </div>
            <div className="py-4 space-y-6 sm:space-y-5">
                <p className="text-gray-900">Was this funeral home a Tukios video customer prior to being onboarded to websites?</p>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    type="button"
                    onClick={(e) => { handleNo() }}
                    className="min-w-16 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                    No
                </button>
                <button
                    type="button"
                    onClick={(e) => { handleYes() }}
                    className="min-w-16 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Yes
                </button>
            </div>
        </>
    )
}

export default PreviousVideoProviderConfirmation