import axiosConfig from '../axiosConfig';
import logActivity from './logActivity';

const startScrape = (organizationId, legacyFhid, url, jobType, provider, startPage, endPage) => {

  return new Promise((resolve, reject) => {
    axiosConfig.post('/api/scrapiTasks/startScrape', {
      organizationId: organizationId,
      legacyFhid: legacyFhid,
      url: url,
      jobType: jobType,
      provider: provider,
      startPage: startPage,
      endPage: endPage,
    })
      .then((res) => {
        logActivity({
          message: `${jobType} scrape started`,
          logType: 'SCRAPE',
          fhId: legacyFhid
        })
        resolve(res)
      })
      .catch((err) => reject(err));
  })

}

export default startScrape;