import React, { useState } from 'react';
import DesktopNavigation from './DesktopNavigation';
import logo from './assets/tukios-logo-alt.png';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const DesktopSidebar = () => {

  let [shrink, setShrink] = useState(true)

  let iconStyles = 'text-white w-8 cursor-pointer'

  const shrinkSideBar = () => {
    setShrink(!shrink)
  }

  return (
    <div className="hidden bg-tukios md:flex md:flex-shrink-0">
      <div className={classNames(shrink ? "w-14" : "w-60", "flex flex-col transition-all ease-in-out delay-150 duration-1000")}>

        <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center justify-between flex-shrink-0 px-4 relative">
            <img
              className={classNames(shrink ? "opacity-0" : "opacity-100", "h-8 w-32 transition-all ease-in-out delay-150 duration-500")}
              src={logo}
              alt="Tukios"
            />
            <button
              className="absolute right-3"
              onClick={() => shrinkSideBar()}
            >
              { shrink ? <ChevronDoubleRightIcon className={iconStyles}/> : <ChevronDoubleLeftIcon className={iconStyles} /> }
            </button>
          </div>

          <DesktopNavigation shrink={shrink} />
          
        </div>
      </div>
    </div>

  )
}

export default DesktopSidebar;
