import React, { useState } from 'react';
import SidebarPage from '../SidebarPage';
import IconTabs from '../IconTabs';
import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/solid';
import PreLiveSeoTable from './PreLiveSeoTable';
import PostLiveSeoTable from './PostLiveSeoTable';

export default function SeoPage() {

  const tabs = [
    {
      name: 'Pre-Live',
      icon: TrendingDownIcon
    },
    {
      name: 'Post-Live',
      icon: TrendingUpIcon
    }
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return (
    <SidebarPage docTitle='SEO | Tukios Website Admin'>
      <IconTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab.name == 'Pre-Live' &&
        <PreLiveSeoTable />
      }
      {selectedTab.name == 'Post-Live' &&
        <PostLiveSeoTable />
      }
    </SidebarPage>
  )

}