import { UserAddIcon } from "@heroicons/react/solid"
import classNames from "classnames"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import DropdownWithAvatars from "../../../components/ui/DropdownWithAvatars"
import assignDesigner from "../../assignDesigner"
import logActivity from "../../logActivity"
import { getUserObject, isAdmin, isDesigner } from "../../users"
import ContentShimmer from "react-content-shimmer"

export default function SiteAssignDesigner({ data, className, Context }) {

  let { sites, setSites, designers, assignLoading, setAssignLoading, isLoading } = useContext(Context)

  const setSiteDesigner = (fhid, e) => {

    setAssignLoading(true)

    let designerId = e.user_id == 'none' ? null : e.user_id

    assignDesigner(fhid, designerId).then((res) => {
      logActivity({
        message: `${e.name} assigned to site`,
        fhId: fhid,
        logType: 'DESIGNER'
      })

      let currentSites = [...sites]
      let currentSiteIndex = currentSites.map(s => s.id).indexOf(fhid)
      let currentSite = { ...currentSites[currentSiteIndex] }

      currentSite.designer_id = e.id == 'none' ? null : e.id
      currentSite.designer_name = e.id == 'none' ? null : e.name
      currentSite.designer_image = e.id == 'none' ? null : e.image
      if (designerId) {
        currentSite.launcher_label = 'In Design'
      }

      currentSites[currentSiteIndex] = currentSite

      setSites(currentSites)

      toast.success(e.name == 'Not assigned' ? 'Unassigned' : `${e.name} assigned to site!`)
      if (res !== true) toast.warn(res)
      setAssignLoading(false)

    })
      .catch((err) => {
        toast.error('Error updating site designer')
        console.log(err)
        setAssignLoading(false)
      })
  }

  return (
    <div className={classNames("text-sm text-gray-900 flex gap-2", className)}>
      {(!isLoading && !assignLoading) ?
        <>
          {isDesigner() ?
            <button
              onClick={() => setSiteDesigner(data.id, getUserObject())}
              type="button"
              className={classNames("w-8 h-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-tukios-green-dark focus:outline-none", isDesigner() ? 'hover:bg-tukios-green' : '')}
            >
              <UserAddIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            : <></>}
          <div
            className='w-full'
          >
            {
              designers !== undefined &&
              <DropdownWithAvatars
                people={designers}
                current={data.designer_id ? {
                  name: data.designer_name,
                  image: data.designer_image
                } : null}
                handleSelection={(e) => setSiteDesigner(data.id, e)}
                disabled={isAdmin() ? false : !isDesigner()}
              />
            }
          </div>
        </> : <ContentShimmer style={{ height: '34px', width: '200px', borderRadius: '7px' }} />
      }
    </div>
  )
}