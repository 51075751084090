import axiosConfig from '../axiosConfig';

const listSiteRevisions = (fhid) => {

  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/siteRevisions', {
      params: {
        fhid: fhid
      }
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err));
  })

}

export default listSiteRevisions;