import AeSiteFilter from "../../utils/site table/AeSiteFilter";
import SiteAssignDesigner from "../../utils/site table/columns/SiteAssignDesigner";
import SiteDateCreated from "../../utils/site table/columns/SiteDateCreated";
import SiteID from "../../utils/site table/columns/SiteID";
import SiteMembers from "../../utils/site table/columns/SiteMembers";
import SiteName from "../../utils/site table/columns/SiteName";
import SiteRooftops from "../../utils/site table/columns/SiteRooftops";
import SiteStatus from "../../utils/site table/columns/SiteStatus";
import CorporationSiteFilter from "../../utils/site table/CorporationSiteFilter";
import SiteSearch from "../../utils/site table/SiteSearch";
import SitesTable from "../../utils/site table/SitesTable";
import { siteStatuses } from "../../utils/utilityLibrary";
import SidebarPage from "../SidebarPage";

const UnassignedSites = () => {

  const filters = [
    {
      Component: SiteSearch,
      className: 'w-full lg:1/3'
    },
    {
      Component: AeSiteFilter,
      className: 'w-full lg:1/3'
    },
    {
      Component: CorporationSiteFilter,
      className: 'w-full lg:1/3'
    },
  ]

  const columns = [
    {
      label: "ID",
      Component: SiteID,
      className: 'lg:w-32'
    },
    {
      label: "Name",
      Component: SiteName,
      className: 'lg:w-1/6'
    },
    {
      label: "Rooftops",
      Component: SiteRooftops,
      className: 'w-full lg:justify-center lg:w-20 lg:text-center lg:flex'
    },
    {
      label: "Status",
      Component: SiteStatus,
      className: 'lg:w-1/6 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Members",
      Component: SiteMembers,
      className: 'lg:w-28 lg:flex lg:justify-center'
    },
    {
      label: "Date Created",
      Component: SiteDateCreated,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    {
      label: "Assign",
      Component: SiteAssignDesigner,
      className: 'lg:w-1/4 lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  return (
    <SidebarPage docTitle='Unassigned Sites | Tukios Website Admin'>
      <div style={
        {
          minWidth: window.innerWidth >= 1024 ? '1300px' : '100%',
        }
      }>
        <SitesTable filters={filters} columns={columns} defaultSiteParams={{
          label: Object.values(siteStatuses).filter(status => [siteStatuses.readyForDesign].includes(status)),
          urgent_build_filter: true,
          exclude_hosted_obit_sites: true
        }}
        />
      </div>
    </SidebarPage>
  )
}

export default UnassignedSites;
