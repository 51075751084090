import classNames from "classnames"
import { useContext } from "react"
import { ModalContext } from "../../../hooks/ModalContext"
import { isScrapeMaster } from "../../users"
import ScheduleLaunchDialog from "../../../components/pages/ScheduleLaunchDialog"
import ContentShimmer from "react-content-shimmer"
import MarkScrapeRequestCompleteDialog from "../../../components/pages/MarkScrapeRequestCompleteDialog"

export default function ScrapeMarkComplete({ data, className, Context }) {

  let { handleModal } = useContext(ModalContext)

  let { isLoading, removeScrape } = useContext(Context)

  const scheduleLaunch = (data) => {
    handleModal(
      <MarkScrapeRequestCompleteDialog data={data} removeScrape={removeScrape} />
    )
  }

  return (
    <div className={classNames(className)}>
      {!isLoading ?
        <>
          {isScrapeMaster() ?
            <button
              type="button"
              className="ml-1 inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              onClick={() => scheduleLaunch(data)}
            >
              Mark Complete
            </button> : <>-</>
          }
        </>
        : <ContentShimmer style={{ height: '14px', width: '100px', borderRadius: '7px' }} />}
    </div>
  )
}