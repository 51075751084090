import { Dialog } from '@headlessui/react'
import { useState, useEffect } from 'react';
import HashLoader from 'react-spinners/HashLoader';
import socketIOClient from "socket.io-client";

const LaunchLoader = ({ launchId }) => {

  let [ launchStatusMessage, setLaunchStatusMessage ] = useState('Getting started');

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_TUKIOS_API_URL, { withCredentials: true });
    socket.on('launch_event', (data) => {
      // eslint-disable-next-line
      if (data.launchId == launchId) {
        setLaunchStatusMessage(data.message);
      }
    })
  // eslint-disable-next-line
  }, []);

  return (
    <>
        <div>
          <div className="mx-auto relative flex items-center justify-center h-12 w-12">
            {/* <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
            <HashLoader
              color='#76AB23'
            />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              Site launch in progress!
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {launchStatusMessage}...
              </p>
            </div>
          </div>
        </div>
    </>
  )
}

export default LaunchLoader;