import AeSiteFilter from "../../utils/site table/AeSiteFilter"
import ScrapeMarkComplete from "../../utils/site table/columns/ScrapeMarkComplete"
import ScrapeRequestNeededBy from "../../utils/site table/columns/ScrapeRequestNeededBy"
import ScrapeRequestNote from "../../utils/site table/columns/ScrapeRequestNote"
import ScrapeRequestType from "../../utils/site table/columns/ScrapeRequestType"
import SiteActions from "../../utils/site table/columns/SiteActions"
import SiteID from "../../utils/site table/columns/SiteID"
import SiteName from "../../utils/site table/columns/SiteName"
import DesignerSiteFilter from "../../utils/site table/DesignerSiteFilter"
import ThreeDotMenu from "../../utils/site table/icons/ThreeDotMenu"
import CorporationSiteFilter from "../../utils/site table/CorporationSiteFilter"
import SiteSearch from "../../utils/site table/SiteSearch"
import SitesTable from "../../utils/site table/SitesTable"

const ScrapeRequestTable = () => {

  const filters = [
    {
      Component: SiteSearch,
      className: 'w-full lg:1/4'
    },
    {
      Component: AeSiteFilter,
      className: 'w-full lg:1/4'
    },
    {
      Component: DesignerSiteFilter,
      className: 'w-full lg:1/4'
    },
    {
      Component: CorporationSiteFilter,
      className: 'w-full lg:1/4'
    },
  ]

  const columns = [
    {
      label: "ID",
      Component: SiteID,
      className: 'lg:w-32'
    },
    {
      label: "Name",
      Component: SiteName,
      className: 'lg:w-1/6'
    },
    {
      label: "Type",
      Component: ScrapeRequestType,
      className: 'lg:w-1/12 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Needed By",
      Component: ScrapeRequestNeededBy,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    {
      label: "Note",
      Component: ScrapeRequestNote,
      className: 'lg:text-left lg:w-4/12 lg:justify-center'
    },
    {
      label: "Mark Complete",
      Component: ScrapeMarkComplete,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    {
      label: "Actions",
      Component: SiteActions,
      className: 'lg:w-1/6 lg:flex lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  return (
    <>
      <div style={
        {
          minWidth: window.innerWidth >= 1024 ? '1400px' : '100%',
        }
      }>
        <SitesTable filters={filters} columns={columns} defaultSiteParams={{ scrapeRequest: true }} trailingIcon={ThreeDotMenu}/>
      </div>
    </>
  )
}

export default ScrapeRequestTable
