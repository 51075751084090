import { EyeIcon, UploadIcon } from "@heroicons/react/solid";
import PreviewImageDialog from "../ui/modal/dialogs/PreviewImage";
import { useContext } from "react";
import { ModalContext } from "../../hooks/ModalContext";

const ContentItem = ({contentItem, content, setContent}) => {

  let { handleModal } = useContext(ModalContext);

  let imageStyle = {
    backgroundImage: `url(${contentItem.url})`,
    "background-repeat": "no-repeat",
    "background-position": "center",
    width: "100%",
    "margin-left": "auto",
    "margin-right": "auto",
  }

  if(contentItem.label.includes("Logo")) {
    imageStyle["background-size"] = "contain";
    imageStyle["background-color"] = "rgb(148 163 184)";
  }
  else {
    imageStyle["background-size"] = "cover";
  }

  const editImage = () => {
    handleModal(
      <PreviewImageDialog
        contentItem={contentItem}
        content={content}
        setContent={setContent}
      />
    )
  }

  return (
    <div className="text-center bg-white space-y-4 shadow rounded w-full divide-y divide-gray-200">
      <div className="flex-1 flex flex-col pr-6 pl-6 pt-5">
        <h3 className="mb-5 text-gray-900 font-medium text-base">
          {contentItem.label}
        </h3>
        <div
          style={imageStyle}
          className="flex items-center justify-center h-40"
        />
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
            <div className="-ml-px w-0 flex-1 flex">
                <a
                  href={contentItem.url}
                  rel="noreferrer"
                  target='_blank'
                  className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                >
                  <EyeIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">Preview</span>
                </a>
            </div>
          <div onClick={editImage} className="w-0 flex-1 flex">
            <div className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <UploadIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="ml-3">Upload/Comment</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentItem;
