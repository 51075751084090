import SidebarPage from "../SidebarPage"
import { useEffect, useState } from "react"
import StatsWrapper from '../dashboard/StatsWrapper'
import Stat from '../dashboard/Stat'
import {
  BeakerIcon,
  LightningBoltIcon,
  PaperAirplaneIcon,
  SparklesIcon
} from '@heroicons/react/solid'
import axiosConfig from '../../axiosConfig'
import { isDesigner, isAE, isAdmin, userId, isQA, isFlorist, isAM, isSDR } from "../../utils/users"
import SiteID from "../../utils/site table/columns/SiteID"
import SiteName from "../../utils/site table/columns/SiteName"
import SiteLaunchDate from "../../utils/site table/columns/SiteLaunchDate"
import SiteActions from "../../utils/site table/columns/SiteActions"
import SiteStatus from "../../utils/site table/columns/SiteStatus"
import ThreeDotMenu from "../../utils/site table/icons/ThreeDotMenu"
import SitesTable from "../../utils/site table/SitesTable"
import SiteMembers from "../../utils/site table/columns/SiteMembers"
import SiteDateCreated from "../../utils/site table/columns/SiteDateCreated"
import { siteStatuses } from "../../utils/utilityLibrary"
import SiteSearch from "../../utils/site table/SiteSearch"
import DesignerSiteFilter from "../../utils/site table/DesignerSiteFilter"
import AeSiteFilter from "../../utils/site table/AeSiteFilter"
import CorporationSiteFilter from "../../utils/site table/CorporationSiteFilter"
import StatusSiteFilter from "../../utils/site table/StatusSiteFilter"
import SiteRooftops from "../../utils/site table/columns/SiteRooftops"
import SiteInfo from "../../utils/site table/columns/SiteInfo"
import SiteMarkCoached from "../../utils/site table/columns/SiteMarkCoached"
import SiteLastCoachingSession from "../../utils/site table/columns/SiteLastCoachingSession"

const MySites = () => {

  let [stats, setStats] = useState([])
  let [defaultSiteParams, setDefaultSiteParams] = useState()

  const getDesignerStats = (designerId) => {
    axiosConfig.get(`/api/designers/${designerId}/stats`)
      .then((res) => {
        let newStats = [
          { id: 1, name: siteStatuses.inDesign, stat: res.data.design, icon: BeakerIcon },
          { id: 2, name: 'In Review', stat: res.data.review, icon: PaperAirplaneIcon },
          { id: 3, name: siteStatuses.staged, stat: res.data.staged, icon: SparklesIcon },
          { id: 4, name: siteStatuses.live, stat: res.data.live, icon: LightningBoltIcon }
        ]
        setStats(newStats)
      })
  }

  const getAeStats = (aeId) => {
    axiosConfig.get(`/api/aes/${aeId}/stats`)
      .then((res) => {
        let newStats = [
          { id: 1, name: siteStatuses.inDesign, stat: res.data.design, icon: BeakerIcon },
          { id: 2, name: 'In Review', stat: res.data.review, icon: PaperAirplaneIcon },
          { id: 3, name: siteStatuses.staged, stat: res.data.staged, icon: SparklesIcon },
          { id: 4, name: siteStatuses.live, stat: res.data.live, icon: LightningBoltIcon }
        ]
        setStats(newStats)
      })
  }

  const getQaStats = () => {
    axiosConfig.get('/api/cares/stats')
      .then(res => {
        let newStats = [
          { id: 1, name: siteStatuses.preStagingQa, stat: res.data.preStage, icon: BeakerIcon },
          { id: 2, name: siteStatuses.finalQa, stat: res.data.final, icon: PaperAirplaneIcon }
        ]
        setStats(newStats)
      })
  }

  useEffect(() => {

    let includedStatuses = isAdmin() ? [...Object.values(siteStatuses)] : [...Object.values(siteStatuses).filter(status => status != siteStatuses.test)]

    if (isDesigner() || isAdmin()) {
      getDesignerStats(userId())
      setDefaultSiteParams({
        label: includedStatuses.filter(status => !['Lost'].includes(status)),
        designer: {
          id: userId()
        },
        designerMySites: true
      })
    }
    else if (isAE()) {
      getAeStats(userId())
      setDefaultSiteParams({
        label: includedStatuses.filter(status => !['Lost'].includes(status)),
        ae: {
          id: userId()
        },
        aeMySites: true
      })
    }
    else if (isQA()) {
      getQaStats()
      setDefaultSiteParams({
        label: includedStatuses.filter(status => !['Lost'].includes(status)),
        care: {
          id: userId()
        },
        careMySites: true
      })
    }
    else if (isFlorist()) {
      setDefaultSiteParams({
        label: includedStatuses.filter(status => !['Lost'].includes(status)),
        florist: {
          id: userId()
        }
      })
    }
    else if (isAM()) {
      setDefaultSiteParams({
        label: Object.values(siteStatuses).filter(status => ['Live'].includes(status)),
        coachingSessions: true,
        am: {
          id: userId()
        }
      })
    }
    else if (isSDR()) {
      setDefaultSiteParams({
        label: includedStatuses,
        sdr: {
          id: userId()
        },
        sdrMySites: true
      })
    }
  }, [])


  const filters = [
    {
      Component: SiteSearch,
      className: `w-full lg:1/${isQA() ? '5' : '4'}`
    },
  ]

  if (!isAdmin() && !isDesigner()) {
    filters.push(
      {
        Component: DesignerSiteFilter,
        className: `w-full lg:1/${isQA() ? '5' : '4'}`
      }
    )
  }
  if (!isAE()) {
    filters.push(
      {
        Component: AeSiteFilter,
        className: `w-full lg:1/${isQA() ? '5' : '4'}`
      },
    )
  }

  filters.push(
    {
      Component: CorporationSiteFilter,
      className: `w-full lg:1/${isQA() ? '5' : '4'}`
    }
  )

  filters.push(
    {
      Component: StatusSiteFilter,
      className: `w-full lg:1/${isQA() ? '5' : '4'}`
    }
  )

  const columns = [
    {
      label: "ID",
      Component: SiteID,
      className: 'lg:w-32'
    },
    {
      label: "Name",
      Component: SiteName,
      className: 'lg:w-1/6'
    },
    {
      label: "Rooftops",
      Component: SiteRooftops,
      className: 'w-full lg:justify-center lg:w-20 lg:text-center lg:flex'
    },
    {
      label: "Status",
      Component: SiteStatus,
      className: 'lg:w-1/6 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Members",
      Component: SiteMembers,
      className: 'lg:w-1/12 lg:flex lg:justify-center'
    },
    isAM() ?
    {
      label: 'Last Coaching Session',
      Component: SiteLastCoachingSession,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    } :
    {
      label: "Launch Date",
      Component: SiteLaunchDate,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    isAM() ?
    {
      label: 'Mark Coached',
      Component: SiteMarkCoached,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    } :
    {
      label: "Date Created",
      Component: SiteDateCreated,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    {
      label: "Info",
      Component: SiteInfo,
      className: 'lg:w-20 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Actions",
      Component: SiteActions,
      className: 'lg:w-1/6 lg:flex lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  return (
    <SidebarPage scroll={false} docTitle='My Sites | Tukios Website Admin'>
      {(!isFlorist() && !isAM() && !isSDR()) &&
        <div style={{ minWidth: '1356px' }} className='flex flex-col'>
          <div className='mb-4'>
            <StatsWrapper classNames={isQA() ? "lg:grid-cols-2" : "lg:grid-cols-4"}>
              {
                stats.map(stat => (
                  <Stat key={stat.id} stat={stat} />
                ))
              }
            </StatsWrapper>
          </div>
        </div>
      }
      {defaultSiteParams &&
        <div style={
          {
            minWidth: window.innerWidth >= 1024 ? '1403px' : '100%',
          }
        }>
          <SitesTable filters={filters} columns={columns} trailingIcon={ThreeDotMenu} defaultSiteParams={defaultSiteParams} />
        </div>
      }
    </SidebarPage>
  )
}

export default MySites