import axiosConfig from '../axiosConfig'
import { addTrelloMember } from './users'

const assignAeToFirm = (fhid, aeId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/assignAe`, {
      aeId: aeId
    })
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const assignAe = (fhid, aeId) => {
  return new Promise((resolve, reject) => {
    assignAeToFirm(fhid, aeId)
      .then(() => {
        if (!aeId) {
          resolve(true)
        }
        else {
          addTrelloMember(fhid, aeId)
          resolve(true)
        }
      })
      .catch((err) => reject(err))
  })
}

export default assignAe