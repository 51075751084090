import axiosConfig from '../axiosConfig';

const getActivityLogs = (fhId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.get(`/api/log/${fhId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err));
  })
}

export default getActivityLogs;