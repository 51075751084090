import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useState, useContext } from 'react';
import { ModalContext } from '../../../../hooks/ModalContext';
import { nanoid } from 'nanoid';
import ToggleWithLabel from '../../ToggleWithLabel';

const AddLocationDialog = ({ newLocation, editLocation, existingLocation, index }) => {

  const inputClasses = 'shadow appearance-none border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline';

  let { handleModal } = useContext(ModalContext);

  let [location, setLocation] = useState({
    name: existingLocation ? existingLocation.name : '',
    address: existingLocation ? existingLocation.address : '',
    city: existingLocation ? existingLocation.city : '',
    state: existingLocation ? existingLocation.state : '',
    zip: existingLocation ? existingLocation.zip : '',
    phone: existingLocation ? existingLocation.phone : '',
    email: existingLocation ? existingLocation.email : '',
    is_cemetery: existingLocation ? existingLocation.is_cemetery : false
  });

  let [saving, setSaving] = useState(false);

  let optionalParams = ['phone', 'email', 'is_cemetery'];

  const handleChange = (e) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value
    })
  }

  const setIsCemetery = () => {
    setLocation({
      ...location,
      ['is_cemetery']: !location.is_cemetery
    })
  }

  const saveLocation = () => {
    
    if (saving === false) {
      setSaving(true);


      let invalid = [];
      Object.keys(location).forEach(property => {
        if (location[property] == '' && !optionalParams.includes(property)) invalid.push(property);
      });

      if (invalid.length > 0) {
        let errorMessage = `Please fill out these fields: ${invalid.join(', ')}`;
        alert(errorMessage);
        setSaving(false)
      } else {
        if (newLocation) {
          newLocation({ ...location, id: nanoid() });
        }
        else {
          editLocation({ ...location }, index);
        }
        handleModal(false);
      }
    }


  }

  return (
    <>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
          onClick={() => handleModal(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start w-full">
        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Add a location
          </Dialog.Title>
          <div className="mt-2 w-full">

            <div className="py-4 space-y-6 sm:space-y-5">

              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Name*
                  </label>
                  <div className="mt-1">
                    <input
                      name="name"
                      type="text"
                      placeholder="Sonzini Mortuary"
                      onChange={handleChange}
                      value={location.name}
                      className={inputClasses}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Address*
                  </label>
                  <div className="mt-1">
                    <input
                      name="address"
                      type="text"
                      placeholder="208 West Main Street"
                      onChange={handleChange}
                      value={location.address}
                      className={inputClasses}
                    />
                  </div>
                </div>


                <div className="sm:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City*
                  </label>
                  <div className="mt-1">
                    <input
                      name="city"
                      type="text"
                      placeholder="Clinton"
                      onChange={handleChange}
                      value={location.city}
                      className={inputClasses}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                    State / Province*
                  </label>
                  <div className="mt-1">
                    <input
                      name="state"
                      type="text"
                      placeholder="NC"
                      onChange={handleChange}
                      value={location.state}
                      className={inputClasses}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                    ZIP / Postal*
                  </label>
                  <div className="mt-1">
                    <input
                      name="zip"
                      type="text"
                      placeholder="28328"
                      onChange={handleChange}
                      value={location.zip}
                      className={inputClasses}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="contactPhone" className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <div className="mt-1">
                    <input
                      name="phone"
                      type="text"
                      placeholder="(910) 260-2599"
                      onChange={handleChange}
                      value={location.phone}
                      className={inputClasses}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      name="email"
                      type="text"
                      placeholder="john@sonzinimortuary.com"
                      onChange={handleChange}
                      value={location.email}
                      className={inputClasses}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <ToggleWithLabel
                    enabled={location.is_cemetery}
                    setEnabled={setIsCemetery}
                    label='Is Cemetery'
                  />
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={() => saveLocation()}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
        >
          Save
        </button>
        <button
          type="button"
          onClick={() => handleModal(false)}
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>

    </>
  )
}

export default AddLocationDialog;