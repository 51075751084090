import SiteActions from "../../utils/site table/columns/SiteActions";
import SiteAeDropdown from "../../utils/site table/columns/SiteAeDropdown";
import SiteCareDropdown from "../../utils/site table/columns/SiteCareDropdown";
import SiteDesignerDropdown from "../../utils/site table/columns/SiteDesignerDropdown";
import SiteID from "../../utils/site table/columns/SiteID";
import SiteLaunchDate from "../../utils/site table/columns/SiteLaunchDate";
import SiteMembers from "../../utils/site table/columns/SiteMembers";
import SiteName from "../../utils/site table/columns/SiteName";
import SiteStatus from "../../utils/site table/columns/SiteStatus";
import SiteSearch from "../../utils/site table/SiteSearch";
import DesignerSiteFilter from "../../utils/site table/DesignerSiteFilter";
import AeSiteFilter from "../../utils/site table/AeSiteFilter";
import CorporationSiteFilter from "../../utils/site table/CorporationSiteFilter";
import StatusSiteFilter from "../../utils/site table/StatusSiteFilter";
import SitesTable from "../../utils/site table/SitesTable";
import { UserRole, getUserRole, isDirectorOfAccountsManagement, isDirectorOfSalesDevelopement } from "../../utils/users";
import ThreeDotMenu from "../../utils/site table/icons/ThreeDotMenu";
import SiteFloristDropdown from "../../utils/site table/columns/SiteFloristDropdown";
import FloristSiteFilter from "../../utils/site table/FloristSiteFilter";
import SiteAmDropdown from "../../utils/site table/columns/SiteAmDropdown";
import AmSiteFilter from "../../utils/site table/AmSiteFilter";
import SiteRooftops from "../../utils/site table/columns/SiteRooftops";
import SiteInfo from "../../utils/site table/columns/SiteInfo";
import SdrSiteFilter from "../../utils/site table/SdrSiteFilter";
import SiteSdrDropdown from "../../utils/site table/columns/SiteSdrDropdown";

export default function AllSitesTable() {

  const designerDropdown = {
    label: "Designer",
    Component: SiteDesignerDropdown,
    className: 'lg:w-1/6'
  }

  const floristDropdown = {
    label: "Floral Specialist",
    Component: SiteFloristDropdown,
    className: 'lg:w-1/6'
  }

  const aeDropdown = {
    label: "Account Executive",
    Component: SiteAeDropdown,
    className: 'lg:w-1/6'
  }

  const amDropdown = {
    label: "Account Manager",
    Component: SiteAmDropdown,
    className: 'lg:w-1/6'
  }

  const careDropdown = {
    label: "Quality Assurance",
    Component: SiteCareDropdown,
    className: 'lg:w-1/6'
  }

  const sdrDropdown = {
    label: "Sales Developement Rep.",
    Component: SiteSdrDropdown,
    className: 'lg:w-1/6'
  }

  const roleSelector = new Map([
    [UserRole.SuperAdmin, isDirectorOfSalesDevelopement() ? sdrDropdown : isDirectorOfAccountsManagement() ? amDropdown : designerDropdown],
    [UserRole.Executive, designerDropdown],
    [UserRole.Designer, designerDropdown],
    [UserRole.AccountExecutive, aeDropdown],
    [UserRole.QualityAssurance, careDropdown],
    [UserRole.FloralSpecialist, floristDropdown],
    [UserRole.AccountManager, amDropdown],
    [UserRole.Sales, sdrDropdown]
  ]);

  const designerFilter = {
    Component: DesignerSiteFilter,
    className: 'w-full lg:1/5'
  }

  const floristFilter = {
    Component: FloristSiteFilter,
    className: 'w-full lg:1/5'
  }

  const amFilter = {
    Component: AmSiteFilter,
    className: 'w-full lg:1/5'
  }

  const sdrFilter = {
    Component: SdrSiteFilter,
    className: 'w-full lg:1/5'
  }

  const roleFilter = new Map([
    [UserRole.SuperAdmin, isDirectorOfSalesDevelopement() ? sdrFilter : isDirectorOfAccountsManagement() ? amFilter : designerFilter],
    [UserRole.Executive, designerFilter],
    [UserRole.Designer, designerFilter],
    [UserRole.AccountExecutive, designerFilter],
    [UserRole.QualityAssurance, designerFilter],
    [UserRole.FloralSpecialist, floristFilter],
    [UserRole.AccountManager, amFilter],
    [UserRole.Sales, sdrFilter]
  ])

  const filters = [
    {
      Component: SiteSearch,
      className: 'w-full lg:1/5'
    },
    roleFilter.get(getUserRole()),
    {
      Component: AeSiteFilter,
      className: 'w-full lg:1/5'
    },
    {
      Component: CorporationSiteFilter,
      className: 'w-full lg:1/5'
    },
    {
      Component: StatusSiteFilter,
      className: 'w-full lg:1/5'
    }
  ]

  const columns = [
    {
      label: "ID",
      Component: SiteID,
      className: 'w-full lg:w-32'
    },
    {
      label: "Name",
      Component: SiteName,
      className: 'w-full lg:w-1/6'
    },
    {
      label: "Rooftops",
      Component: SiteRooftops,
      className: 'w-full lg:justify-center lg:w-20 lg:text-center lg:flex'
    },
    {
      label: "Status",
      Component: SiteStatus,
      className: 'text-center flex justify-left lg:justify-center lg:w-1/6'
    },
    roleSelector.get(getUserRole()),
    {
      label: "Members",
      Component: SiteMembers,
      className: 'lg:w-1/12 lg:flex lg:justify-center'
    },
    {
      label: "Launch Date",
      Component: SiteLaunchDate,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    {
      label: "Info",
      Component: SiteInfo,
      className: 'lg:w-20 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Actions",
      Component: SiteActions,
      className: 'lg:w-52 lg:flex lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  return (
    <div style={
      {
        minWidth: window.innerWidth >= 1024 ? '1522px' : '100%',
      }
    }>
      <SitesTable columns={columns} filters={filters} trailingIcon={ThreeDotMenu} defaultSiteParams={{ allSites: true }} />
    </div>
  )

}