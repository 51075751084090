import classNames from 'classnames'
import { scrapeRequestTypeBadgeColors } from '../../utils/utilityLibrary'

const ScrapeRequestTypeBadge = ({ scrapeRequstType }) => {

    return (
        <span className={classNames('px-3 inline-flex leading-5 rounded-full text-xs', scrapeRequestTypeBadgeColors[scrapeRequstType])}>
            {scrapeRequstType}
        </span>
    )
}

export default ScrapeRequestTypeBadge