import { useContext, useState } from "react"
import { ModalContext } from "../../hooks/ModalContext"
import {
  HomeIcon,
  UserCircleIcon,
  LocationMarkerIcon,
  PlusCircleIcon
} from '@heroicons/react/solid'
import AddLocationDialog from "../ui/modal/dialogs/AddLocation"
import ToggleWithLabel from '../ui/ToggleWithLabel'
import { useEffect } from "react"
import axiosConfig from '../../axiosConfig'
import { fundingPartners, listCorporations, listReferralPartners, providers } from "../../utils/utilityLibrary"

const FH = ({ currentStep, fh, setFh, completeStep }) => {
  const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none'

  let { handleModal } = useContext(ModalContext)
  let [corporations, setCorporations] = useState()
  let [referralPartners, setReferralPartners] = useState()

  useEffect(() => {
    Promise.all([
      listCorporations(),
      listReferralPartners()
    ])
    .then(([corporations, referralPartners]) => {
      setCorporations(corporations)
      setReferralPartners(referralPartners)
    })
  }, [])

  const newLocation = (newLoc) => {
    let newFh = { ...fh }
    newFh.locations.push(newLoc)
    setFh(newFh)
  }
  const editLocation = (loc, index) => {
    let newFh = { ...fh }
    newFh.locations[index] = loc
    setFh(newFh)
  }

  const handleChange = (e) => {
    let val = e.target.value

    let nameSplit = e.target.name.split('.')

    if (nameSplit.length > 1) {

      let section = nameSplit[0]
      let property = nameSplit[1]

      let newFh = { ...fh }
      newFh[section][property] = val
      setFh(newFh)

    } else {

      let property = e.target.name

      let newFh = { ...fh }
      newFh[property] = val
      setFh(newFh)

    }
    console.log(fh)
  }

  const addLocation = (e) => {
    if (e.currentTarget.value == '') {
      handleModal(
        <AddLocationDialog
          newLocation={newLocation}
          editLocation={false}
          existingLocation={false}
          index={false}
        />
      )
    }
    else {
      handleModal(
        <AddLocationDialog
          newLocation={false}
          editLocation={editLocation}
          existingLocation={fh.locations[e.currentTarget.value]}
          index={e.currentTarget.value}
        />
      )
    }
  }

  const setUrgentBuild = (e) => {
    let newFh = { ...fh }
    newFh.urgentBuild = e
    setFh(newFh)
  }

  const setHostedObits = (e) => {

    let newFh = { ...fh }
    newFh.hostedObits = e
    setFh(newFh)

    // setDetails({ ...details, hostedObits: e })

  }

  if (currentStep.id !== 'fh') return null

  return (
    <form className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200">

        <div className='bg-white p-5 space-y-4 shadow rounded'>

          <div className='text-lg font-medium flex items-center'>
            <HomeIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Funeral Home Details</div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">

            <div className="sm:col-span-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  name="details.name"
                  type="text"
                  placeholder="Sonzini Mortuary"
                  onChange={handleChange}
                  value={fh.details.name}
                  className={inputClasses}
                />
              </div>
            </div>
            <div className="sm:col-span-4">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Website <span className="text-gray-400 text-xs"> - Do not include `http` or `www`</span>
              </label>
              <div className="mt-1 flex shadow rounded-md">
                <span className="inline-flex shadow-none items-center px-3 rounded-l-md border border-r-0 bg-gray-50 text-gray-500 sm:text-sm">
                  www.
                </span>
                <input
                  name="details.url"
                  type="text"
                  placeholder="sonzinimortuary.com"
                  onChange={handleChange}
                  value={fh.details.url}
                  className={inputClasses + ' rounded-l-none shadow-none'}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="provider" className="block text-sm font-medium text-gray-700">
                Provider
              </label>
              <select
                name="details.provider"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                initialValues="chooseProvider"
                value={fh.details.provider}
                onChange={handleChange}
              >
                <option value="" disabled>Select one</option>
                {
                  Object.values(providers).map(provider => (
                    <option value={provider}>{provider}</option>
                  ))
                }
              </select>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="referral_partner" className="block text-sm font-medium text-gray-700">
                Referral Partner
              </label>
              <select
                name="details.referralPartner"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                initialValues="chooseReferralPartner"
                value={fh.details.referralPartner}
                onChange={handleChange}
              >
                <option selected="selected" value="" disabled>Select one</option>
                {
                  referralPartners ? referralPartners.map(referralPartner => (
                    <option value={referralPartner.id}>{referralPartner.name}</option>
                  )) : (<></>)
                }
              </select>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="provider" className="block text-sm font-medium text-gray-700">
                Funding Partner
              </label>
              <select
                name="details.fundingPartner"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                initialValues="chooseProvider"
                value={fh.details.fundingPartner}
                onChange={handleChange}
              >
                <option value="" disabled>Select one</option>
                {
                  Object.values(fundingPartners).map(fundingPartner => (
                    <option value={fundingPartner}>{fundingPartner}</option>
                  ))
                }
              </select>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="corporation" className="block text-sm font-medium text-gray-700">
                Corporation
              </label>
              <select
                name="details.corporation"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                initialValues="chooseCorporation"
                value={fh.details.corporation}
                onChange={handleChange}
              >
                <option selected="selected" value="" disabled>Select one</option>
                {
                  corporations ? corporations.map(corporation => (
                    <option value={corporation.id}>{corporation.name}</option>
                  )) : (<></>)
                }
              </select>
            </div>
            <div class="sm:col-span-8">
              <label
                for="floralPhone"
                className="block text-sm font-medium text-gray-700"
              >
                Purchase Notification Email{fh.details.floralPhone ? fh.details.floralPhone.includes(",") ? 's' : '' : ''} <span className="text-gray-400 text-xs">- use commas to separate multiple emails</span>
              </label>
              <div class="mt-1">
                <input
                  name="details.floralPhone"
                  type="text"
                  placeholder="john@sonzinimortuary.com"
                  onChange={handleChange}
                  value={fh.details.floralPhone}
                  className={inputClasses}
                />
              </div>
            </div>
            <div class="sm:col-span-4">
              <label
                for="floralPhone"
                className="block text-sm font-medium text-gray-700"
              >
                Email Reply To Name
              </label>
              <div class="mt-1">
                <input
                  name="details.replyToName"
                  type="text"
                  placeholder="John Doe"
                  onChange={handleChange}
                  value={fh.details.replyToName}
                  className={inputClasses}
                />
              </div>
            </div>
            <div class="sm:col-span-4">
              <label
                for="floralPhone"
                className="block text-sm font-medium text-gray-700"
              >
                Email Reply To Address
              </label>
              <div class="mt-1">
                <input
                  name="details.replyToAddress"
                  type="text"
                  placeholder="john@sonzinimortuary.com"
                  onChange={handleChange}
                  value={fh.details.replyToAddress}
                  className={inputClasses}
                />
              </div>
            </div>
            <div className="sm:col-span-4">
              <ToggleWithLabel
                enabled={fh.hostedObits}
                setEnabled={setHostedObits}
                label='Hosted obituary site'
              />
            </div>
          </div>
        </div>


        <div className='bg-white p-5 space-y-4 shadow rounded'>

          <div className='text-lg font-medium flex items-center'>
            <UserCircleIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Primary Contact Person</div>
          </div>


          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-6">
              <label htmlFor="contact.name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  name="contact.name"
                  type="text"
                  placeholder="John Doe"
                  onChange={handleChange}
                  value={fh.contact.name}
                  className={inputClasses}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="contact.phone" className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <div className="mt-1">
                <input
                  name='contact.phone'
                  type="text"
                  placeholder="(910) 260-2599"
                  onChange={handleChange}
                  value={fh.contact.phone}
                  className={inputClasses}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="contact.email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  name="contact.email"
                  type="text"
                  placeholder="john@sonzinimortuary.com"
                  onChange={handleChange}
                  value={fh.contact.email}
                  className={inputClasses}
                />
              </div>
            </div>

          </div>

        </div>


        <div className='bg-white p-5 space-y-4 shadow rounded'>

          <div className='text-lg font-medium flex items-center'>
            <LocationMarkerIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>
              {
                fh.locations.length > 1 ? 'Locations' : 'Location'
              }
              {
                fh.locations.length === 0 &&
                <span className="text-gray-500 text-sm font-normal"> - At least one is required</span>
              }
            </div>
          </div>

          <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3'>

            {
              fh.locations.map(loc => (

                <button
                  type="button"
                  key={fh.locations.indexOf(loc)}
                  value={fh.locations.indexOf(loc)}
                  className='relative flex flex-col justify-center w-full border-2 border-gray-300 hover:border-gray-400 rounded-lg p-4'
                  onClick={addLocation}
                >
                  <h3 className='text-lg underline mb-2'>{loc.name}</h3>
                  <span className='mb-2'>
                    {loc.address}
                    <br />
                    {loc.city}, {loc.state} {loc.zip}
                  </span>
                  {loc.email && <span>{loc.email}</span>}
                  {loc.phone && <span>{loc.phone}</span>}
                </button>

              ))
            }



            <button
              type="button"
              onClick={addLocation}
              className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none"
            >
              <PlusCircleIcon className='mx-auto h-12 w-12 text-gray-400' />
              <span className="mt-2 block text-sm font-medium text-gray-900">
                {
                  fh.locations.length > 0 ? 'Add another location' : 'Add a location'
                }
              </span>
            </button>
          </div>


        </div>

      </div>
    </form>
  )

}

export default FH