import axiosConfig from '../axiosConfig';

const logIssue = ( fhid, issue, severity, newStatus, verified ) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post('/api/siteRevisions', {
      fhid: fhid,
      issue: issue,
      severity: severity,
      status: newStatus,
      verified: verified
    }).then((res) => resolve(res))
    .catch((err) => reject(err));
  })
}

export default logIssue;