import classNames from "classnames";
import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";
import ScrapeRequestTypeBadge from "../../../components/sites/ScrapeRequestTypeBadge";

export default function ScrapeRequestType({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={classNames("whitespace-nowrap text-sm", className)}>
      {!isLoading ?
        <ScrapeRequestTypeBadge scrapeRequstType={data.type} />
        :
        <ContentShimmer style={{ height: '20px', width: '100px', borderRadius: '7px' }} />
      }
    </div>
  )
}