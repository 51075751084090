import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';

export default function DesignerSiteFilter({ Context, className }) {

  const { designers, anyDesigner, designerFilter, handleDesignerChange } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        placeholder={anyDesigner}
        filters={designers}
        selected={designerFilter}
        setSelected={handleDesignerChange}
      />
    </div>
  )
}
