import classNames from "classnames";
import { siteStatuses } from "../../utils/utilityLibrary";

export default function RevisionType({ handleSiteStatusChange, defaultStatus, className }) {

  return (
    <div className={classNames("flex gap-4 w-fit ml-auto mr-5 justify-end", className)}>
      <div className="flex items-center w-fit">
        <input
          id="Post-Live QA"
          name="newStatus"
          type="radio"
          onClick={handleSiteStatusChange}
          defaultChecked={"Post-Live QA" == defaultStatus}
          className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
        />
        <div>
          <label
            htmlFor="Instant"
            className="ml-1 block text-sm font-medium text-gray-700"
          >
            Post-Live QA
          </label>
        </div>
      </div>
      <div className="flex items-center w-fit">
        <input
          id="Final QA"
          name="newStatus"
          type="radio"
          onClick={handleSiteStatusChange}
          defaultChecked={siteStatuses.finalQa == defaultStatus}
          className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
        />
        <div>
          <label
            htmlFor="Instant"
            className="ml-1 block text-sm font-medium text-gray-700"
          >
            Final QA
          </label>
        </div>
      </div>
      <div className="flex items-center w-fit">
        <input
          id="Pre-Staging QA"
          name="newStatus"
          type="radio"
          onClick={handleSiteStatusChange}
          defaultChecked={siteStatuses.preStagingQa == defaultStatus}
          className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
        />
        <div>
          <label
            className="ml-1 block text-sm font-medium text-gray-700"
          >
            Pre-Staging QA
          </label>
        </div>
      </div>
      <div className="flex items-center w-fit">
        <input
          id="Client Request"
          name="newStatus"
          type="radio"
          onClick={handleSiteStatusChange}
          defaultChecked={'Client Request' == defaultStatus}
          className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
        />
        <div>
          <label
            className="ml-1 block text-sm font-medium text-gray-700"
          >
            Client Request
          </label>
        </div>
      </div>
    </div>
  )
}
