import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';

export default function CareSiteFilter({ Context, className }) {

  const { cares, anyCare, careFilter, handleCareChange } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        placeholder={anyCare}
        filters={cares}
        selected={careFilter}
        setSelected={handleCareChange}
      />
    </div>
  )
}
