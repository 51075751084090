import classNames from 'classnames'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import buildPreviewLink from '../../buildPreviewLink'
import openSiteEditor from '../../openSiteEditor'
import { isAdmin, isDesigner, isSDR } from '../../users'
import ContentShimmer from 'react-content-shimmer'

export default function SiteActions({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={classNames(className, "whitespace-nowrap font-medium")}>
      {!isLoading ?
        <>
          {
            data.duda_id || data.hosted_obit_site ?
              <>
                {
                  (!isSDR() && !data.hosted_obit_site) &&
                  <button
                    type="button"
                    onClick={() => openSiteEditor(data.duda_id, data.id)}
                    className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                  >
                    Editor
                  </button>
                }
                {
                  data.launcher_label != 'Live' && !data.hosted_obit_site ?
                    <a
                      href={buildPreviewLink(data.duda_id)}
                      target="_blank"
                      className="ml-1 inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                    >
                      Preview
                    </a>
                    : (
                      <a
                        href={data.url}
                        target="_blank"
                        className="ml-1 inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                      >
                        Website
                      </a>)
                }
              </> :
              ((isDesigner() || isAdmin()) && !['Live', 'Staged'].includes(data.launcher_label)) ?
                <>
                  <Link
                    to={{
                      pathname: `/create`,
                      state: {
                        fhid: data.id
                      }
                    }}
                    className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                  >
                    Create Duda Project
                  </Link>
                </> :
                <p>Project not yet created</p>

          }</>
        :
        <>
          <ContentShimmer style={{ height: '30px', width: '65px', borderRadius: '7px', marginRight: '7px' }} />
          <ContentShimmer style={{ height: '30px', width: '65px', borderRadius: '7px' }} />
        </>
      }
    </div>
  )
}