import classNames from "classnames"
import { useContext } from "react"
import { ModalContext } from "../../../hooks/ModalContext"
import { isAdmin, isAE, isLaunchSpecialist } from "../../users"
import ScheduleLaunchDialog from "../../../components/pages/ScheduleLaunchDialog"
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/solid"
import moment from "moment"
import { siteStatuses, statusesReadyToScheduleLaunchDate } from "../../utilityLibrary"
import ContentShimmer from "react-content-shimmer"

export default function SiteLaunchDate({ data, className, Context }) {

  let { handleModal } = useContext(ModalContext)

  let { isLoading, updateLaunchDateDisplay } = useContext(Context)

  const scheduleLaunch = (site) => {
    handleModal(
      <ScheduleLaunchDialog site={site} updateLaunchDateDisplay={updateLaunchDateDisplay} />
    )
  }

  const LiveOnTime = (launchDate) => {
    let today = moment().format('YYYYMMDD')
    let launchDateObj = moment(launchDate, 'MMM D, YYYY').format('YYYYMMDD')

    return today.valueOf() > launchDateObj.valueOf()
  }

  return (
    <div className={classNames(className)}>
      {!isLoading ?
        <>
          {
            data.pretty_scheduled_launch_date ? (
              <div className={classNames('flex')}>
                {((LiveOnTime(data.pretty_scheduled_launch_date) || data.launcher_label == 'Live') && !['Lost', 'Rebuild', 'Test'].includes(data.launcher_label)) ?
                  <>
                    {data.launcher_label == siteStatuses.live ?
                      <CheckCircleIcon data-tip="Live" className='h-5 w-5 text-green-500 mr-1' /> :
                      <XCircleIcon data-tip="Missed launch date" className='h-5 w-5 text-red-500 mr-1' />
                    }
                  </> : <>
                    {![siteStatuses.lost, siteStatuses.rebuild, siteStatuses.test].includes(data.launcher_label) &&
                      <ExclamationCircleIcon data-tip="Launch pending" className="h-5 w-5 text-yellow-300 mr-1" />
                    }
                  </>
                }
                {data.pretty_scheduled_launch_date}
              </div>) :
              (
                (isLaunchSpecialist() || statusesReadyToScheduleLaunchDate.includes(data.launcher_label) && (isAE() || isAdmin())) ?
                  <button
                    type="button"
                    className="ml-1 inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                    onClick={() => scheduleLaunch(data)}
                  >
                    Schedule Launch Date
                  </button>
                  :
                  <>
                    {data.launcher_label == 'Live' ? (
                      <>
                        -
                      </>
                    ) : (
                      <>
                        -
                      </>
                    )
                    }
                  </>
              )
          }
        </> : <ContentShimmer style={{ height: '14px', width: '100px', borderRadius: '7px' }} />}
    </div>
  )
}