import SidebarPage from "./SidebarPage"
import GoDaddyDomainSearch from "./GoDaddyDomainSearch"

const goDaddyTools = () => {

    return(
        <SidebarPage docTitle={"Godaddy Tools"} pageTitle={"Godaddy Tools"} maxWidth={'max-w-7xl mx-auto'} scroll={true}>
            <GoDaddyDomainSearch />    
        </SidebarPage>

    )
}

export default goDaddyTools