import ContentShimmer from 'react-content-shimmer'

const ActivityLoader = () => {
  return (
    Array.from({ length: 3 }, (e, i) => (
      <li key={i}>
        <div className="relative pb-8">
          {i !== 2 ? (
            <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
          ) : null}
          <div className="relative flex space-x-3">
            <div>
              <span className='h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white overflow-hidden'>
                <ContentShimmer className='h-5 w-5 text-white' />
              </span>
            </div>
            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
              <div className="w-full">
                <div className="text-sm text-gray-500">
                  <ContentShimmer style={{
                    width: '85%',
                    height: '16px',
                    borderRadius: '7px'
                  }} />
                  <span className="font-medium text-gray-900">
                    <ContentShimmer style={{
                      width: '50%',
                      height: '16px',
                      borderRadius: '7px',
                      marginTop: '5px'
                    }} />
                  </span>
                </div>
              </div>
              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                <ContentShimmer style={{
                  width: '40px',
                  height: '16px',
                  borderRadius: '7px'
                }} />
              </div>
            </div>
          </div>
        </div>
      </li>
    ))
  )
}

export default ActivityLoader;