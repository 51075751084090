
import { useState, useContext, useEffect, useRef } from 'react';
import { ModalContext } from '../../hooks/ModalContext';
import { XIcon } from '@heroicons/react/solid';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import RevisionSeverity from '../site/RevisionSeverity';
import RevisionType from '../site/RevisionType';
import { isAdmin, isQA } from '../../utils/users';
import updateIssue from '../../utils/updateIssue';
import { toast } from 'react-toastify';
import deleteIssue from '../../utils/deleteIssue';

const UpdateRevisionDialog = ({ issue }) => {

    let { handleModal, setWidth } = useContext(ModalContext);
    setWidth('sm:max-w-4xl')

    const [saving, setSaving] = useState(false)
    const [newSeverity, setNewSeverity] = useState(issue.severity)
    const [newStatus, setNewStatus] = useState(issue.status)

    let textBoxRef = useRef();

    const handleSiteSeverityChange = (e) => {
        setNewSeverity(e.target.id)
    }

    const handleSiteStatusChange = (e) => {
        setNewStatus(e.target.id)
    }

    const handleSubmit = () => {
        if (saving) return
        setSaving(true)
        let issueText = textBoxRef.current.value;
        updateIssue(issue.id, issueText, newSeverity, newStatus).then(() => {
            window.location.reload()
        })
        .catch((err) => {
            setSaving(false)
            toast.error('Error updating site revision')
        })
    }

    const handleDelete = () => {
        if (saving) return 
        setSaving(true)
        deleteIssue(issue.id).then(() => {
            window.location.reload()
        })
        .catch((err) => {
            setSaving(false)
            toast.error('Error deleting site revision')
        })
    }

    const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none';

    return (
        <>
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => handleModal(false)}
                >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Edit Revision
                    </Dialog.Title>
                </div>
            </div>
            <div className="py-4 space-y-6 sm:space-y-5">
                <textarea
                    name="newIssue"
                    ref={textBoxRef}
                    defaultValue={issue.issue}
                    type="text"
                    placeholder="Incorrect email address linked in the footer."
                    className={classNames(inputClasses, 'h-36')}
                />
            </div>
            <div className="flex justify-center gap-16 my-3">
                <RevisionSeverity handleSiteSeverityChange={handleSiteSeverityChange} defaultSeverity={newSeverity} />
                {(isQA() || isAdmin()) &&
                    <RevisionType handleSiteStatusChange={handleSiteStatusChange} defaultStatus={newStatus} />
                }
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    type="button"
                    onClick={(e) => { handleSubmit() }}
                    className={classNames(saving ? "opacity-50" : "", "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm")}
                >
                    Save
                </button>
                <button
                    type="button"
                    onClick={() => handleModal(false)}
                    className={"mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={(e) => { handleDelete() }}
                    className={classNames(saving ? "opacity-50" : "", "mr-auto w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm")}
                >
                    Delete
                </button>
            </div>
        </>
    )
}

export default UpdateRevisionDialog;