import { useContext, useEffect, useState } from "react";
import markItem from "../../utils/markItem";
import ChecklistItem from "./ChecklistItem";
import { ChecklistContext } from "./Checklist";

const ChecklistCategory = ({ category }) => {

  let { site, updateSiteInfoDisplay } = useContext(ChecklistContext)

  let [items, setItems] = useState(category.items)

  const checkItem = (item) => {
    let newItem = items.find(oldItem => oldItem.id == item.id)

    newItem.checked = !item.checked
    setItems([...items])
    if(updateSiteInfoDisplay) {
      updateSiteInfoDisplay(site.id, newItem)
    }

    markItem(item.id, site.id, item.checked).catch(err => {
      console.log(err)
    })
  }

  return (
    <>
      {
        (items.length > 0) &&
        <div className="flex flex-col gap-2 mb-2 pl-5">
          <h2 className="font-gray-900 font-medium mt-2">{category.name}</h2>
          {
            items.map(item => (
              <ChecklistItem item={item} onClick={checkItem} />
            ))
          }
        </div>
      }
    </>
  )
}

export default ChecklistCategory;
