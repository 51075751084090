import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClipboardCopyIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import buildPreviewLink from "../../utils/buildPreviewLink";
import openSiteEditor from '../../utils/openSiteEditor';
import ContentShimmer from 'react-content-shimmer'
import StatusBadge from '../sites/StatusBadge';
import DeleteButton from '../sites/DeleteButton';
import Stager from '../ui/modal/dialogs/Stager';
import { ModalContext } from "../../hooks/ModalContext";
import { useContext, useState } from 'react';
import { isAdmin, isDesigner, isLaunchSpecialist, isSDR } from '../../utils/users';
import { Link, useHistory } from 'react-router-dom';
import GoLiveButton from '../ui/GoLiveButton';
import { siteStatuses, statusesReadyToScheduleLaunchDate } from '../../utils/utilityLibrary';

const SiteHeader = ({ site, updateSiteStatus, setShowConfetti }) => {
  let { handleModal } = useContext(ModalContext);
  let history = useHistory()
  let [statusLoading, setStatusLoading] = useState(false);  

  const stageSite = (e) => {
    e.preventDefault();
    handleModal(<Stager site={site} showConfetti={() => setShowConfetti(true)} updateSiteStatus={updateSiteStatus} />)
  }

  return (
    <div className="md:flex md:items-start md:justify-between md:space-x-5">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          {site.id ?
            <div className="relative mt-1">
              <span className="inline-flex items-center px-3 py-0.5 rounded text-sm font-medium bg-transparent text-tukios-dark border border-tukios-dark">
                <>{site.id}</>
              </span>
            </div>
            :
            <ContentShimmer style={{ height: '26px', width: '78px', borderRadius: '7px' }} />
          }
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            {
              site.name ? site.name :
                <ContentShimmer
                  rounded='7px'
                  style={{
                    width: '200px',
                    height: '28px'
                  }}
                />
            }
          </h1>
          <StatusBadge siteStatus={site.launcher_label} />
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

        {
          (site.launcher_label === 'Test' && isAdmin()) &&
          <DeleteButton />
        }
        {site?.trello_card_id &&
          <a
            href={`https://trello.com/c/${site.trello_card_id}`}
            target="_blank"
            className="inline-flex min-w-max items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
          >
            Open Trello
          </a>
        }
        {
          site.organization_id && 
          <a
            href={`https://manage2.tukioswebsites.com/${site.organization_id}/dashboard`}
            target="_blank"
            className="inline-flex min-w-max items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
          >
            Backend
          </a>
        }
        {
          site.duda_id &&
          <>
            {!isSDR() &&
              <>
                <button
                  type="button"
                  onClick={() => openSiteEditor(site.duda_id, site.id)}
                  className="inline-flex min-w-max items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                >
                  Edit Site
                </button>

                <button
                  type="button"
                  onClick={() => history.push(`/pages/${site.id}`, site)}
                  className="inline-flex min-w-max items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                >
                  View Pages
                </button>
              </>
            }
            <span className="relative z-0 inline-flex items-stretch">
              <a
                href={buildPreviewLink(site.duda_id)}
                target="_blank"
                rel="noreferrer"
                className="relative inline-flex min-w-max flex-grow items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-l-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              >
                Preview
              </a>
              <CopyToClipboard
                text={buildPreviewLink(site.duda_id)}
                onCopy={() => toast.success('Preview link copied to clipboard!')}
              >
                <button
                  className="-ml-px relative min-w-max inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                >
                  <ClipboardCopyIcon className="-mx-1 h-4 w-4" />
                </button>
              </CopyToClipboard>
            </span>
          </>
        }

        {
          ((site.launcher_label === 'Pre-Staging QA' || site.launcher_label === 'Final QA') && site.duda_id) ?
            <button
              type="button"
              onClick={stageSite}
              // onClick={() => fakeObitsRemoved(site.id)}
              className="inline-flex min-w-max items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
              disabled={statusLoading}
            >
              {statusLoading ? 'Loading...' : 'Stage Site'}
            </button>

            : (!site.duda_id && !site.hosted_obit_site && (isDesigner() || isAdmin())) ?
              <Link
                to={{
                  pathname: '/create',
                  state: {
                    fhid: site.id
                  }
                }}
                // type="button"
                className="inline-flex min-w-max items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
              // disabled={statusLoading}
              >
                Create Duda Project
              </Link>

              : (isLaunchSpecialist() || (statusesReadyToScheduleLaunchDate.includes(site.launcher_label) && !site.launcher_label.includes(statusesReadyToScheduleLaunchDate[siteStatuses.revisionsNeeded] && !site.launcher_label.includes(statusesReadyToScheduleLaunchDate[siteStatuses.finalQa]) && isAdmin()))) &&                
                  <GoLiveButton site={site} />                
        }

      </div>
    </div>
  )
}

export default SiteHeader;