import classNames from "classnames"
import { useContext } from "react"
import { toast } from "react-toastify"
import DropdownWithAvatars from "../../../components/ui/DropdownWithAvatars"
import assignAe from "../../assignAe"
import assignCares from "../../assignCares"
import logActivity from "../../logActivity"
import { isAdmin, isQA } from "../../users"
import ContentShimmer from "react-content-shimmer"

export default function SiteCareDropdown({ data, className, Context }) {

  let { sites, setSites, cares, isLoading } = useContext(Context)

  const setSiteCare = (fhid, e) => {

    let careId = e.user_id == 'none' ? null : e.user_id

    assignCares(fhid, careId)
      .then((res) => {

        logActivity({
          message: `${e.name} assigned to site`,
          fhId: fhid,
          logType: 'CARES'
        })

        let currentSites = [...sites]
        let currentSiteIndex = currentSites.map(s => s.id).indexOf(fhid)
        let currentSite = { ...currentSites[currentSiteIndex] }

        currentSite.care_id = e.id == 'none' ? null : e.id
        currentSite.care_name = e.id == 'none' ? null : e.name
        currentSite.care_image = e.id == 'none' ? null : e.image

        currentSites[currentSiteIndex] = currentSite

        setSites(currentSites)

        toast.success(e.name == 'Not assigned' ? 'Unassigned' : `${e.name} assigned to site!`)
        if (res !== true) toast.warn(res)

        console.log('QA updated')
      })
      .catch((err) => {
        toast.error('Error updating site QA')
      })

  }

  return (
    <div className={classNames("whitespace-nowrap text-sm font-medium text-gray-900", className)}>
      {!isLoading ?
        <DropdownWithAvatars
          people={cares}
          current={data.care_id ? {
            name: data.care_name,
            image: data.care_image
          } : null}
          handleSelection={(e) => setSiteCare(data.id, e)}
          disabled={isAdmin() ? false : !isQA()}
        /> : <ContentShimmer style={{ height: '34px', width: '200px', borderRadius: '7px' }} />
      }
    </div>
  )
}