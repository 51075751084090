import classNames from 'classnames';
import SiteFilter from '../sites/SiteFilter';
import { domainsTypeFilters } from '../../utils/utilityLibrary';

export default function TypeDomainsFilter({ handleDomainsTypeFilterChange, typeFilter, className }) {

  let filters = []
  filters.push(...domainsTypeFilters)

  return (
    <div className={classNames(className, 'w-full')}>
          <SiteFilter
            filters={filters}
            selected={typeFilter}
            setSelected={handleDomainsTypeFilterChange} 
          />
    </div>
  )
}
