import axiosConfig from "../../axiosConfig";
import { useEffect, useState } from "react";
import ContentItem from "./ContentItem";
import ContentLoader from "./ContentLoader";

const ContentList = ({ template, setDesign, setContent, content }) => {
  let [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axiosConfig
      .get(`api/sites/${template.baseId}/content`, { withCredentials: true })
      .then((res) => {

        res.data.content.site_images.unshift({
          label: "Business Logo",
          url: res.data.content.business_data.logo_url,
          alt: "Business Logo"
        });
        
        setContent(res.data.content);
        setLoaded(true);
      });
  }, []);

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
      {loaded
        ? (
          content.site_images.map((contentItem) => (
              <ContentItem contentItem={contentItem} content={content} setContent={setContent} />
          ))
        ) : Array.from({ length: 3 }, (e, i) => <ContentLoader />)}
    </div>
  );
};

export default ContentList;
