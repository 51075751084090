import classNames from "classnames"
import { useContext } from "react"
import { ModalContext } from "../../../hooks/ModalContext"
import ContentShimmer from "react-content-shimmer"
import { isAM, isDirectorOfAccountsManagement } from "../../users"
import MarkCoachingSessionCompleteDialog from "../../../components/pages/MarkCoachingSessionCompleteDialog"

export default function SiteMarkCoached({ data, className, Context }) {

  let { handleModal } = useContext(ModalContext)

  let { isLoading, updateLastCoachedDisplay } = useContext(Context)

  const markCoached = (data) => {
    handleModal(
      <MarkCoachingSessionCompleteDialog data={data} updateLastCoachedDisplay={updateLastCoachedDisplay} />
    )
  }

  return (
    <div className={classNames(className)}>
      {!isLoading ?
        <>
          {(isAM() || isDirectorOfAccountsManagement()) ?
            <button
              type="button"
              className="ml-1 inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              onClick={() => markCoached(data)}
            >
              Mark Coached
            </button> : <>-</>
          }
        </>
        : <ContentShimmer style={{ height: '14px', width: '100px', borderRadius: '7px' }} />}
    </div>
  )
}