
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { ModalContext } from '../../hooks/ModalContext';
import { useContext } from 'react';

const ViewResultDialog = ({ result }) => {

    let { handleModal } = useContext(ModalContext);

    return (
        <>
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => handleModal(false)}
                >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Result
                    </Dialog.Title>
                </div>
            </div>
            <div className="py-4 space-y-6 sm:space-y-5">
                <div className="p-4 bg-black text-white rounded-lg">
                    { result }
                </div>
            </div>

        </>
    )
}

export default ViewResultDialog;