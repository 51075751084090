import classNames from 'classnames'
import React, { useContext } from 'react'

export default function ScrapeActions({ data, className, Context }) {

  let { revokeTask, viewResult, watchScrape } = useContext(Context)

  return (
    <div className={classNames(className, "whitespace-nowrap font-medium")}>
      {
        (data?.result && !['STARTED', 'RECEIVED'].includes(data?.status)) ?
        <button
          type="button"
          onClick={() => viewResult(data?.result)}
          className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
        >
          View Result
        </button>
        :
        <>
          {
            (data?.status && data?.status != 'STARTED') ?
            <button
              type="button"
              onClick={() => revokeTask(data?._id, data?.kwargs?.legacy_fhid)}
              className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
            >
              Revoke Task
            </button>
            :
            <button
              type="button"
              onClick={() => watchScrape(data)}
              className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
            >
              Watch Stream
            </button>
          }
        </>
      }
    </div>
  )
}