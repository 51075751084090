import { Dialog } from '@headlessui/react'
// import { useState, useEffect } from 'react';
import HashLoader from 'react-spinners/HashLoader';

const IntakeLoader = () => {
  return (
    <>
      <div>
        <div className="mx-auto relative flex items-center justify-center h-12 w-12">
          <HashLoader
            color='#76AB23'
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Creating site...
          </Dialog.Title>
        </div>
      </div>
    </>
  )
}

export default IntakeLoader;