import axiosConfig from '../axiosConfig';
import logActivity from './logActivity';

const killTask = (taskId, legacyFhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post('/api/scrapiTasks/killTask', {
      taskId: taskId
    })
      .then((res) => {
        logActivity({
          message: `Task ${taskId} revoked`,
          logType: 'SCRAPE',
          fhId: legacyFhid
        })
        resolve(res)
      })
      .catch((err) => reject(err));
  })

}

export default killTask;