import classNames from 'classnames'
import React from 'react'
import ScrapeMenu from '../../../components/sites/ScrapeMenu'

export default function ScrapeThreeDotMenu({ data, Context, className }) {

  return (
    <div className={classNames(className, "")}>
      <ScrapeMenu
        data={data}
        Context={Context}
      />
    </div>
  )
}