import { EyeIcon, CheckIcon } from '@heroicons/react/solid'
import formatTemplateName from '../../utils/formatTemplateName';
  
const TemplateItem = (props) => {
  let template = props.template;
    return (
      <li
        key={template.template_id}
        className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
      >
        <div className="flex-1 flex flex-col p-8">
          <h3 className="mb-5 text-gray-900 font-medium text-base">{formatTemplateName(template.template_name)}</h3>
          <img className="w-full flex-shrink-0 mx-auto" src={template.thumbnail_url} alt="" />
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="-ml-px w-0 flex-1 flex">
                <a
                  href={template.preview_url}
                  rel="noreferrer"
                  target='_blank'
                  className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                >
                  <EyeIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">Preview</span>
                </a>
            </div>
            <div className="w-0 flex-1 flex">
              <div
                onClick={() => props.setTemplate(template)}
                className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
              >
                  <CheckIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">Select</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  }
  
  export default TemplateItem;