import { Redirect, Route } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import NotFound from './pages/NotFound';
import { hasAllUserData } from '../utils/users';
import { SiteFiltersProvider } from '../hooks/SiteFiltersContext';

const AuthRoute = ({ component: Component, auth, hasAccess, ...rest }) => {

  const [cookies] = useCookies();
  const isLoggedIn = cookies.access_token !== undefined;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn && hasAllUserData() ? (

          auth !== false ? (
            <SiteFiltersProvider>
              <Component {...props} />
            </SiteFiltersProvider>
          ) : <NotFound />

        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default AuthRoute
