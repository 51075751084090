import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

const linkClassNames = (isActive) => {
  return isActive ? 'bg-tukios-dark text-white' : 'text-white hover:bg-tukios-light'
}

const SidebarLink = ({ item, shrink }) => {
  const location = useLocation();
  
  // let isActive = location.pathname.startsWith(item.path);
  let isActive = location.pathname == item.path;
  if (item.path === '/') isActive = location.pathname === item.path;

  return (
    item.path ? 
      <NavLink
        to={item.path}
        key={item.name}
        data-tip={shrink ? item.name : ''}
        className={classNames(linkClassNames(isActive), 'group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all ease-in-out delay-150 duration-500')}
        >
        { shrink && <ReactTooltip />}
        <item.icon className={classNames(shrink ? '' : '', "flex-shrink-0 h-6 w-6 text-white")} aria-hidden="true" />
        <div className={classNames("whitespace-nowrap overflow-hidden opacity-100 transition-all ease-in-out delay-150 duration-500 ml-3", shrink ? 'w-0' : 'w-32')}> {item.name} </div>
      </NavLink> :
      <a
        href={item.href}
        key={item.name}
        className={linkClassNames(isActive)}
        target='_blank'
        rel='noreferrer'
      >
        <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
        {item.name}
      </a>
  )

}

export default SidebarLink;