import classNames from 'classnames'
import { useScrapeRequestForm } from '../../hooks/ScrapeRequestFormContext'
import { scrapeRequestTypes } from '../../utils/utilityLibrary'
import { isAdmin } from '../../utils/users'

const ScrapeRequestForm = ({ inModal = false }) => {

  const inputClasses = 'shadow appearance-none border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'

  let { formValues, setFormValues } = useScrapeRequestForm()

  const handleChange = (e) => {
    let newFormValues = { ...formValues }

    newFormValues[e.target.name] = e.target.value

    setFormValues(newFormValues)
  }

  return (
    <>
      <div className="mt-2 w-full">
        <div className={classNames(inModal ? "py-4" : '', "space-y-6 sm:space-y-5")}>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="col-span-4">
              <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                URL
              </label>
              <div className="mt-1">
                <input
                  name="url"
                  type="text"
                  placeholder={`sonzinimortuary.com/${formValues.type.toLowerCase()}`}
                  className={inputClasses}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Scrape Type*
              </label>
              <select
                name="type"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                initialValues="chooseScrapeType"
                value={formValues.type}
                onChange={handleChange}
              >
                <option value="" disabled>Select one</option>
                {
                  Object.values(scrapeRequestTypes).map(jobType => (
                    isAdmin() || jobType !== scrapeRequestTypes.obituary ? (
                      <option value={jobType} key={jobType}>{jobType}</option>
                    ) : null
                  ))
                }
              </select>
            </div>
          </div>
          <div className="col-span-6">
            <label htmlFor="note" className="block text-sm font-medium text-gray-700">
              Additional Notes
            </label>
            <div className="mt-1">
              <textarea
                name="note"
                type="text"
                placeholder="Anything else the team should know?"
                onChange={handleChange}
                className={classNames(inputClasses, 'h-40')}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ScrapeRequestForm