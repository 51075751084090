import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';

export default function AmSiteFilter({ Context, className }) {

  const { ams, anyAm, amFilter, handleAmChange } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        placeholder={anyAm}
        filters={[{ id: 'corporate', user_id: 'corporate', name: 'Corporate sites', image: null }, ...ams]}
        selected={amFilter}
        setSelected={handleAmChange}
      />
    </div>
  )
}
