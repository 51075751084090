import { useState } from "react"
import DomainRequest from "./DomainRequest"
import Proxify from "./Proxify"
import TypeDomainsFilter from "./TypeDomainsFilter"
import { isLaunchSpecialist, isAE } from "../../utils/users"
import { domainsTypeFilters } from "../../utils/utilityLibrary"

const Domains = ({ site }) => {

  const [typeFilter, setTypeFilter] = useState(isLaunchSpecialist() ? domainsTypeFilters[1] : domainsTypeFilters[0])

  const handleDomainsTypeFilterChange = (e) => {
    setTypeFilter(e)
  }

  return (
    <>
      <div className="flex justify-end w-full">
        {isLaunchSpecialist() &&
          <div className="w-1/3">
            <TypeDomainsFilter className='mb-10' handleDomainsTypeFilterChange={handleDomainsTypeFilterChange} typeFilter={typeFilter} />
          </div>
        }
      </div>
      {typeFilter === domainsTypeFilters[0] && <DomainRequest site={site} />}
      {isLaunchSpecialist() &&
        typeFilter === domainsTypeFilters[1] && <Proxify site={site} />
      }
    </>
  )
}

export default Domains