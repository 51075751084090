import axiosConfig from '../axiosConfig'
import { addTrelloMember } from './users'

const assignDesignerToFirm = (fhid, designerId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/assignDesigner`, {
      designerId: designerId
    })
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const assignDesigner = (fhid, designerUserId) => {
  return new Promise((resolve, reject) => {
    assignDesignerToFirm(fhid, designerUserId)
      .then(() => {
        if (!designerUserId) {
          resolve(true)
        }
        else {
          addTrelloMember(fhid, designerUserId)
          axiosConfig.post(`/api/sites/${fhid}/moveTrelloCard`, {
            targetList: 'DESIGN.ASSIGNED',
            originList: 'INTAKE.READY_FOR_DESIGNER'
          })
            .then(() => resolve(true))
            .catch((err) => reject(err))
        }
      })
      .catch((err) => reject(err))
  })
}

export default assignDesigner