import { toast } from 'react-toastify'
import axiosConfig from '../axiosConfig'
import logActivity from './logActivity'
import { reject } from 'lodash'

const markRevisionsNeeded = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/revisionsNeeded`)
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const markLost = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/lost`)
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const markInReview = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/qa`)
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const markLive = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/live`)
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const goLive = (site_id, updateSiteLabelDisplay) => {
  return new Promise((resolve, reject) => {
    logActivity({
      message: 'Site live',
      logType: 'GO_LIVE',
      fhId: site_id
    }).then(() => {
      markLive(site_id).then(() => {
        if (updateSiteLabelDisplay) updateSiteLabelDisplay(site_id, 'Live')
        resolve()
      }).catch((err) => {
        reject(err)
      })
    }).catch((err) => {
      reject(err)
    })
  })
}

const revisionsNeeded = (site_id, updateSiteLabelDisplay) => {
  logActivity({
    message: 'Revisions Needed',
    fhId: site_id,
    logType: 'OTHER'
  }).then(() => {
    markRevisionsNeeded(site_id)
      .then(() => {
        if (updateSiteLabelDisplay) updateSiteLabelDisplay(site_id, 'Revisions Needed')
        toast.success('Site sent back to designer!')
      })
      .catch((err) => {
        toast.error('Unable to send site back to designer')
      })
  })
    .catch((err) => {
      toast.error('Unable to send site back to designer')
    })
}

const lost = (site_id, updateSiteLabelDisplay) => {
  return new Promise((resolve, reject) => {
    logActivity({
      message: 'Marked as lost',
      fhId: site_id,
      logType: 'OTHER'
    }).then(() => {
      markLost(site_id).then(() => {
        if (updateSiteLabelDisplay) updateSiteLabelDisplay(site_id, 'Lost')
        resolve()
      }).catch((err) => {
        reject(err)
      })
    }).catch((err) => {
      reject(err)
    })
  })
}

const inReview = (site_id, updateSiteLabelDisplay) => {
  return new Promise((resolve, reject) => {
    logActivity({
      message: 'Submitted for review',
      fhId: site_id,
      logType: 'IN_REVIEW'
    }).then(() => {
      markInReview(site_id).then(() => {
        if (updateSiteLabelDisplay) updateSiteLabelDisplay(site_id, 'Pre-Staging QA')
        resolve()
      }).catch((err) => {
        reject(err)
      })
    }).catch((err) => {
      reject(err)
    })
  })
}

export { revisionsNeeded, lost, inReview, goLive }