import axiosConfig from '../axiosConfig'

const getSite = (fhid) => {

  return new Promise((resolve, reject) => {
    axiosConfig.get(`/api/sites/${fhid}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })

}

export default getSite