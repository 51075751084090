import classNames from 'classnames';
import { noteTypeBadgeColors } from '../../utils/utilityLibrary';

const NoteTypeBadge = ({ type }) => {

    const defaultClasses = ['px-3', 'leading-5', 'rounded-full', 'text-xs', 'mx-auto', 'block', 'w-min'];

    return (
        <span className={classNames(...defaultClasses, noteTypeBadgeColors[type])}>
            {type}
        </span>
    )
}

export default NoteTypeBadge;