import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";
import { Link } from "react-router-dom";

export default function SiteName({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={className}>
      {!isLoading ?
        <Link to={{
          pathname: `/sites/id/${data?.id}`,
          state: {
            site: data
          }
        }}>
          {data?.name}
        </Link> : <ContentShimmer style={{ height: '14px', width: '90%', borderRadius: '7px' }} />
      }
    </div>
  )
}