import { createContext, useContext, useEffect, useState } from "react"
import { listAes, listAms, listCares, listDesigners, listFlorists, listCorporations, listSdrs, listReferralPartners } from "../utils/utilityLibrary"
import { toast } from "react-toastify"

const SiteFiltersContext = createContext()

const SiteFiltersProvider = ({ children }) => {

  let [designers, setDesigners] = useState([])
  let [florists, setFlorists] = useState([])
  let [aes, setAes] = useState([])
  let [ams, setAms] = useState([])
  let [sdrs, setSdrs] = useState([])
  let [cares, setCares] = useState([])
  let [corporations, setCorporations] = useState([])
  let [referralPartners, setReferralPartners] = useState([])

  useEffect(() => {
    Promise.all([listDesigners(), listAes(), listCorporations(), listReferralPartners(), listCares(), listFlorists(), listAms(), listSdrs()])
      .then(([designers, aes, corporations, referralPartners, cares, florists, ams, sdrs]) => {
        setDesigners([{ id: 'none', name: 'Not assigned', image: null }, ...designers])
        setAes([{ id: 'none', name: 'Not assigned', image: null }, ...aes])
        setCorporations(corporations)
        setReferralPartners(referralPartners)
        setCares([{ id: 'none', user_id: 'none', name: 'Not assigned', image: null }, ...cares])
        setFlorists([{ id: 'none', user_id: 'none', name: 'Not assigned', image: null }, ...florists])
        setAms([{ id: 'none', user_id: 'none', name: 'Not assigned', image: null }, ...ams])
        setSdrs([{ id: 'none', user_id: 'none', name: 'Not assigned', image: null }, ...sdrs])
      })
      .catch((err) => {
        console.log(err)
        toast.error('Unable to load user data')
      })
  }, [])

  return (
    <SiteFiltersContext.Provider value={{ designers, florists, aes, ams, sdrs, cares, corporations, referralPartners }}>
      {children}
    </SiteFiltersContext.Provider>
  )
}

const useSiteFilters = () => {
  return useContext(SiteFiltersContext)
}

export { SiteFiltersProvider, useSiteFilters }
