
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { ModalContext } from '../../hooks/ModalContext';
import { useContext, useEffect, useRef, useState } from 'react';
import useWebSocket from 'react-use-websocket';

const WatchScrapeDialog = ({ data }) => {

    let { handleModal, setWidth } = useContext(ModalContext)
    let listRef = useRef(null)

    setWidth('sm:max-w-4xl')

    let [changes, setChange] = useState(['Obituary Stream...'])

    const url = 'wss://scrapiweb.com' + `/ws/record_changes/${data?.kwargs?.organization_id}`

    useWebSocket(url, {
        onOpen: () => {
            console.log('WebSocket connection established.');
        },
        onClose: () => {
            console.log('Websocket connection closed.')
        },
        onError: (error) => {
            console.log(error)
        },
        onMessage: (message) => {
            setChange([...changes, message.data.replaceAll("\"", "")])
            listRef.current?.lastElementChild?.scrollIntoView();
        }
    })

    return (
        <>
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => handleModal(false)}
                >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Obituary Stream
                    </Dialog.Title>
                </div>
            </div>
            <div className="py-4 space-y-6 sm:space-y-5">
                <div className="p-4 bg-black text-white rounded-lg max-h-96 h-96 overflow-y-auto" ref={listRef}>
                    {
                        changes.map(change => (
                            <p>
                                <a href={change} target="_blank">{change}</a>
                            </p>
                        ))
                    }
                </div>
            </div>

        </>
    )
}

export default WatchScrapeDialog;