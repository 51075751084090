import axiosConfig from '../axiosConfig'

const logActivity = ({ message, fhId, logType, mute=false }) => {
return new Promise((resolve, reject) =>{
  axiosConfig.post('/api/log', {
    message: message,
    fhid: fhId,
    logType: logType ?? 'OTHER',
    mute: mute
  }).then(() => {
    resolve()
  })
  .catch((err) => {
    reject(err)
  })
})
}

export default logActivity;