import classNames from "classnames"

export default function RevisionSeverity({ handleSiteSeverityChange, defaultSeverity = "Low", className }) {

  return (
    <div className={classNames("flex gap-4 w-fit ml-auto mr-5 justify-end", className)}>
      <div className="flex items-center w-fit">
        <input
          id="High"
          name="newSeverity"
          type="radio"
          onClick={handleSiteSeverityChange}
          defaultChecked={"High" == defaultSeverity}
          className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
        />
        <div>
          <label
            htmlFor="Instant"
            className="ml-1 block text-sm font-medium text-gray-700"
          >
            High
          </label>
        </div>
      </div>
      <div className="flex items-center w-fit">
        <input
          id="Medium"
          name="newSeverity"
          type="radio"
          onClick={handleSiteSeverityChange}
          defaultChecked={"Medium" == defaultSeverity}
          className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
        />
        <div>
          <label
            htmlFor="Instant"
            className="ml-1 block text-sm font-medium text-gray-700"
          >
            Medium
          </label>
        </div>
      </div>
      <div className="flex items-center w-fit">
        <input
          id="Low"
          name="newSeverity"
          type="radio"
          onClick={handleSiteSeverityChange}
          defaultChecked={"Low" == defaultSeverity}
          className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
        />
        <div>
          <label
            htmlFor="Instant"
            className="ml-1 block text-sm font-medium text-gray-700"
          >
            Low
          </label>
        </div>
      </div>
    </div>
  )
}
