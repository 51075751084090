import { useContext } from "react"
import ContentShimmer from "react-content-shimmer"

export default function SiteProvider({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={className}>
      {!isLoading ?
        <>
          {data.provider ?? 'Unknown'}
        </> : <ContentShimmer style={{ height: '20px', width: '100px', borderRadius: '7px' }} />
      }
    </div>
  )
}