import classNames from "classnames";
import moment from "moment";

export default function ScrapeScheduledDate({ data, className }) {

  let scheduledDate = data?.kwargs?.scheduled_time ? moment(data?.kwargs?.scheduled_time).format('MMM D, YYYY') : ''

  return (
    <div className={classNames(className)}>
      {
        scheduledDate &&
        <>
          {scheduledDate}
        </>
      }
    </div>
  )
}