import {
  UserIcon,
  LightningBoltIcon,
  FireIcon,
  SparklesIcon,
  DatabaseIcon,
  ColorSwatchIcon,
  CashIcon,
  CurrencyDollarIcon,
  ClipboardIcon,
  CalculatorIcon,
  ChatIcon,
  PhoneIcon,
  TerminalIcon,
  GlobeAltIcon,
  KeyIcon,
  DocumentIcon
} from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import Moment from 'react-moment';
import ActivityLoader from './ActivityLoader';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import getActivityLogs from '../../utils/getActivityLogs';
import { BeakerIcon } from '@heroicons/react/outline';

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

const getLogIcon = (logType) => {
  const iconDict = {
    'INTAKE': {
      icon: CashIcon,
      iconBackground: 'bg-sky-400'
    },
    'CREATE': {
      icon: LightningBoltIcon,
      iconBackground: 'bg-yellow-400'
    },
    'IN_REVIEW': {
      icon: ClipboardIcon,
      iconBackground: 'bg-purple-600'
    },
    'STAGE': {
      icon: SparklesIcon,
      iconBackground: 'bg-blue-500'
    },
    'GO_LIVE': {
      icon: FireIcon,
      iconBackground: 'bg-green-500'
    },
    'OBIT': {
      icon: DatabaseIcon,
      iconBackground: 'bg-purple-400'
    },
    'DESIGNER': {
      icon: ColorSwatchIcon,
      iconBackground: 'bg-teal-400'
    },
    'AE': {
      icon: CurrencyDollarIcon,
      iconBackground: 'bg-red-400'
    },
    'AM': {
      icon: ChatIcon,
      iconBackground: 'bg-sky-400'
    },
    'SDR': {
      icon: PhoneIcon,
      iconBackground: 'bg-red-400'
    },
    'CARES': {
      icon: BeakerIcon,
      iconBackground: 'bg-pink-400'
    },
    'FLORIST': {
      icon: CalculatorIcon,
      iconBackground: 'bg-teal-400'
    },
    'OTHER': {
      icon: UserIcon,
      iconBackground: 'bg-gray-400'
    },
    'SCRAPE': {
      icon: TerminalIcon,
      iconBackground: 'bg-green-500'
    },
    'DOMAIN': {
      icon: GlobeAltIcon,
      iconBackground: 'bg-yellow-500'
    },
    'PROXY': {
      icon: KeyIcon,
      iconBackground: 'bg-blue-300'
    },
    'DNS_INSTRUCTION': {
      icon: DocumentIcon,
      iconBackground: 'bg-blue-500'
    }
  }

  return iconDict[logType];
}

const buildLogsFromAPI = (logs) => {
  return logs.map((log) => {
    return {
      id: log.id,
      content: `${log.message} by`,
      target: log.name,
      date: <Moment format={'MMM D'}>{log.when}</Moment>,
      datetime: log.when,
      ...getLogIcon(log.log_type)
    }
  })
}

const ActivityFeed = ({ path, site_id }) => {

  let [logs, setLogs] = useState(undefined);

  useEffect(() => {
    getActivityLogs(site_id)
      .then((logs) => setLogs(buildLogsFromAPI(logs)));
    // eslint-disable-next-line
  }, [])

  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
        Recent Updates
      </h2>

      <div className="mt-6 flow-root">
        <ul className="mb-0">
          {logs ? logs.map((event, eventIdx) => (
            <li key={event.id}>
              <div className={classNames('relative', {
                'pb-8': eventIdx < logs.length - 1
              })}>
                {eventIdx !== logs.length - 1 ? (
                  <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        event.iconBackground,
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                      )}
                    >
                      <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <p className="text-sm text-gray-500">
                        {event.content}{' '}
                        <span className="font-medium text-gray-900">
                          {event.target}
                        </span>
                      </p>
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={event.datetime}>{event.date}</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )) : <ActivityLoader />}
        </ul>
      </div>
      {/* <div className="mt-5 flex items-center justify-center text-sm text-gray-500">
        <Link
          to={`${path}/log`}
          className="flex items-center justify-center"
        >
          <span className="inline-flex">view all</span>
          <span className="inline-flex ml-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            8
          </span>
        </Link>
      </div> */}
    </div>
  )
}

export default ActivityFeed;
