import axios from 'axios';

var instance = axios.create({
  baseURL: process.env.REACT_APP_TUKIOS_API_URL,
  withCredentials: true
})

// instace.defaults.headers.common['Authorization'] = '';

export default instance;
