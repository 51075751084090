import classNames from "classnames";
import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";

export default function SiteRooftops({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={classNames("whitespace-nowrap text-sm", className)}>
      {!isLoading ?
        <>
          <span className="lg:hidden">Rooftops</span> {data.rooftops}
        </> : <ContentShimmer style={{ height: '20px', width: '18px', borderRadius: '7px' }} />
      }
    </div>
  )
}