import { useState, useEffect, useContext } from "react";
import TemplateItem from "./TemplateItem";
// import axios from 'axios';
import { EyeIcon, CheckIcon } from '@heroicons/react/solid'
import axiosConfig from '../../axiosConfig';
import TemplateLoader from "./TemplateLoader";
import TemplateSearch from "./TemplateSearch";
import ContentShimmer from 'react-content-shimmer'
import { ModalContext } from '../../hooks/ModalContext';
import DangerDialog from '../ui/modal/dialogs/Danger';

const TemplateList = ({ setTemplate }) => {

  let [ templates, setTemplates ] = useState([]);
  let [ loaded, setLoaded ] = useState(false);
  let [ searchTerm, setSearchTerm ] = useState('');
  let [ existingSite, setExistingSite ] = useState({});
  let [ existingSiteId, setExistingSiteId ] = useState('');
  let [ existingLoaded, setExistingLoaded ] = useState(false);
  let [ existingReady, setExistingReady ] = useState(false);
  let { handleModal } = useContext(ModalContext);
  let [ existingSiteName, setExistingSiteName ] = useState('Sonzini Mortuary');

  useEffect(() => {
    axiosConfig.get('/api/templates', { withCredentials: true })
    .then((res) => {
      setTemplates(res.data);
      setLoaded(true);
    })
    .catch((error) => console.log(error))
    getDudaSite('7034b4e1');
  }, []);

  const getDudaSite = (dudaId) => {
    setExistingLoaded(false);
    axiosConfig.get(`/api/sites/${dudaId}/duda`, { withCredentials: true })
    .then((res) => {
      setExistingSite(res.data);
      setExistingLoaded(true);
    })
    .catch((error) => console.error(error))
  }

  const fhIdSearch = (e) => {
    let fhid = e.target.value;
    axiosConfig.get(`/api/sites/search?fhid=${fhid}`).then((res) => {

      let fhInfo = res.data.firm;

      if (fhInfo) {
        if (fhInfo.duda_id) {
          getDudaSite(fhInfo.duda_id);
          setExistingSiteName(fhInfo.name);
        } else {
          handleModal(
            <DangerDialog
              title="Hang on!"
              messageText={`It looks like ${fhInfo.name} (ID: ${fhInfo.id}) doesn't have a Duda site.`}
              actionText={false}
              cancelText="Enter a different ID"
              actionFn={() => {
                window.location.href = `/sites/${fhInfo.id}`;
              }}
            />
          );
        }
      } else {
        handleModal(
          <DangerDialog
            title="Hang on!"
            messageText={`ID: ${fhid} doesn't exist`}
            actionText={false}
            cancelText="Enter a different ID"
            actionFn={() => {
              window.location.href = `/sites/${fhInfo.id}`;
            }}
          />
        );
      }
      setExistingReady(true);
    });
  };

  const clearSearch = () => {
    setSearchTerm('');
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  }

  return (
    <>
      <TemplateSearch
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
      />
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
        <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
          {existingLoaded && loaded ? (
            <div className="flex-1 flex flex-col p-8 h-5">
              <h3 className="mb-5 text-gray-900 font-medium text-base">
                {existingSiteName == 'Sonzini Mortuary' ? 'Existing Site' : existingSiteName}
              </h3>
              <div className="h-96 overflow-hidden">
                <img
                  className="w-full flex-shrink-0 mx-auto"
                  src={existingSite.thumbnail_url}
                />
              </div>
              <input
                id="fhId"
                type="number"
                placeholder="Funeral Home ID"
                value={existingSiteId}
                onChange={
                  e => { 
                    setExistingSiteId(e.target.value); 
                    setExistingReady(false);
                  }
                }
                onBlur={fhIdSearch}
                className="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none mt-8"
              />
            </div>
          ) : (
            <div className="flex-1 flex flex-col p-8">
              <ContentShimmer
                style={{
                  width: "65%",
                  height: "24px",
                  borderRadius: "7px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "20px"
                }}
              />
              {loaded &&
                <ContentShimmer
                  style={{
                    width: "100%",
                    height: "384px",
                    borderRadius: "7px",
                  }}
              />
              }
              {!loaded &&
                <ContentShimmer
                  style={{
                    width: "100%",
                    height: "350px",
                    borderRadius: "7px",
                    marginTop: "10px",
                  }}
              />
              }
              {loaded &&
                <input
                  id="fhId"
                  type="number"
                  placeholder="Funeral Home ID"
                  value={existingSiteId}
                  onChange={e => setExistingSiteId(e.target.value)}
                  onBlur={fhIdSearch}
                  className="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none mt-8"
                />
              }
            </div>
          )}
          <div>
            {existingLoaded && loaded ? (
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="-ml-px w-0 flex-1 flex">
                  <a
                    href={existingSite.preview_site_url}
                    rel="noreferrer"
                    target="_blank"
                    className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                  >
                    <EyeIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">Preview</span>
                  </a>
                </div>
                <div className="w-0 flex-1 flex">
                  <button
                    onClick={() => {
                      if(existingReady) {
                        setTemplate(
                          {
                            template_id: false,
                            base_site_name: existingSite.site_name,
                            template_name: existingSiteId == '' ? 10012 + ' ' + existingSiteName : existingSiteId + ' ' + existingSiteName
                          }
                        )
                      }
                    }}
                    className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <CheckIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">Select</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="-ml-px w-0 flex-1 flex">
                  <div className="relative w-full flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                    <ContentShimmer
                      style={{
                        width: "100px",
                        height: "20px",
                        borderRadius: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="w-0 flex-1 flex">
                  <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                    <ContentShimmer
                      style={{
                        width: "100px",
                        height: "20px",
                        borderRadius: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </li>
        {loaded
          ? templates
              .filter((template) =>
                searchTerm === ""
                  ? true
                  : template.template_name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
              )
              .map((template) => (
                <TemplateItem
                  template={template}
                  key={template.template_id}
                  setTemplate={setTemplate}
                />
              ))
          : Array.from({ length: 2 }, (e, i) => <TemplateLoader />)}
      </ul>
    </>
  );

}

export default TemplateList;

// export default class TemplateList extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       templates: [],
//       loaded: false
//     }
//   }

//   componentDidMount() {
//     axiosConfig.get('/api/templates', { withCredentials: true })
//     .then((res) => {
//       console.log(res);
//       this.setState({
//         templates: res.data,
//         loaded: true
//       })
//     })
//   }

//   // selectTemplate(templateId) {
//   //   console.log(`selected a template: ${templateId}`);
//   // }

//   render() {
//     return (
//       <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
//         {
//           this.state.loaded ? (
//             this.state.templates.map((template) => (
//               <TemplateItem
//                 template={template}
//                 key={template.template_id}
//                 setTemplate={this.props.setTemplate} />
//             ))
//           ) : (
//             Array.from({ length: 3 }, (e, i) => (
//               <TemplateLoader />
//             ))
//           )
//         }
//       </ul>
//     )
  
//   }


// }