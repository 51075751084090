import axios from 'axios'
import axiosConfig from '../axiosConfig'

const listSites = ({ query, page, perPage, designer, florist, corporation, label, ae, am, sdr, care, urgent_build_filter, exclude_hosted_obit_sites, orderByLatestActivity, launchSchedule, preLiveSeo, postLiveSeo, aeMySites, designerMySites, careMySites, sdrMySites, allSites, coachingSessions, scrapeRequest, unassignedSitesFlorist, cancelToken }) => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/sites', {
      params: {
        page: page,
        perPage: perPage,
        query: query,
        designer: designer,
        florist: florist,
        label: label,
        ae: ae,
        am: am,
        sdr: sdr,
        corporation: corporation,
        care: care,
        urgent_build_filter: urgent_build_filter,
        exclude_hosted_obit_sites: exclude_hosted_obit_sites,
        orderByLatestActivity: orderByLatestActivity,
        launchSchedule: launchSchedule,
        preLiveSeo: preLiveSeo,
        postLiveSeo: postLiveSeo,
        aeMySites: aeMySites,
        designerMySites: designerMySites,
        careMySites: careMySites,
        sdrMySites: sdrMySites,
        allSites: allSites,
        scrapeRequest: scrapeRequest,
        unassignedSitesFlorist: unassignedSitesFlorist,
        coachingSessions: coachingSessions
      },
      cancelToken: cancelToken
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (!axios.isCancel(err)) {
          reject(err)
        }
      })
  })

}

export default listSites