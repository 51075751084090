import axiosConfig from '../axiosConfig'

const listSitePages = (dudaId) => {

  return new Promise((resolve, reject) => {
    axiosConfig.get(`api/sites/${dudaId}/pages`, { withCredentials: true })
      .then((res) => resolve(res.data.pages))
      .catch((err) => reject(err))
  })

}

export default listSitePages