import axiosConfig from '../axiosConfig';
import { toast } from 'react-toastify';
import logActivity from './logActivity';

const getDudaSSO = (duda_id) => {
  return new Promise((resolve, reject) => {
    axiosConfig.get(`/api/sites/${duda_id}/sso`)
    .then((res) => resolve(res))
    .catch((err) => reject(err));
  })
}

const openSiteEditor = (duda_id, fhid) => {
  toast.promise(
    getDudaSSO(duda_id),
    {
      pending: 'Loading site editor',
      success: 'Opening site editor!',
      error: 'Unable to load site editor'
    }
  ).then((res) => {

    logActivity({
      message: `Accessed site editor`,
      fhId: fhid,
      mute: true
    })

    window.open(
      res.data.url,
      '_blank'
    );
  });
}

export default openSiteEditor;
