import { createContext, useEffect, useState } from "react"
import { checklistTypeFilters, siteStatuses } from "../../utils/utilityLibrary"
import ChecklistTypeFilter from "./ChecklistTypeFilter"
import classNames from "classnames"
import ChecklistCategory from "./ChecklistCategory"
import getChecklist from "../../utils/getChecklist"
import ChecklistCategoryLoader from "./ChecklistCategoryLoader"
import { isAM, isQA, isDesigner, isFlorist, isLaunchSpecialist } from "../../utils/users"

export const ChecklistContext = createContext()

const Checklist = ({ site, className, updateSiteInfoDisplay=null }) => {

  const getDefaultSiteFilter = () => {
    if(isLaunchSpecialist()) return checklistTypeFilters[3]
    if(isDesigner()) return checklistTypeFilters[0]
    if(isFlorist()) return checklistTypeFilters[4]
    if(isQA()) {
      if(site.status == siteStatuses.preStagingQa) {
        return checklistTypeFilters[1]
      }
      else if(site.status == siteStatuses.finalQa) {
        return checklistTypeFilters[2]
      }
      else {
        return checklistTypeFilters[6]
      }
    }
    if(isAM()) {
      return checklistTypeFilters[5]
    }
    return checklistTypeFilters[3]
  }

  let [checklistFilter, setChecklistFilter] = useState(getDefaultSiteFilter)
  let [checklist, setChecklist] = useState()
  let [loading, setLoading] = useState(true)

  const handleChecklistTypeFilterChange = (e) => {
    setChecklistFilter(e)
  }

  useEffect(() => {
    setLoading(true)
    getChecklist(site.id, checklistFilter.id).then(res => {

      setChecklist(res)
      setLoading(false)
    })
  }, [checklistFilter])

  return (
    <ChecklistContext.Provider
      value={{
        loading,
        setLoading,
        site,
        updateSiteInfoDisplay
      }}>
      <div className="w-48 ml-auto mb-1">
        <ChecklistTypeFilter checklistFilter={checklistFilter} handleChecklistTypeFilterChange={handleChecklistTypeFilterChange} />
      </div>
      <div className={classNames("flex flex-col gap-2 mb-2 divide-y", className)}>
        {!loading ? (
          checklist.map(category => (
            <ChecklistCategory category={category} />
          ))
        ) : (
          <>
            {[...Array(3).keys()].map(() => <ChecklistCategoryLoader />)}
          </>
        )
        }
      </div>
    </ChecklistContext.Provider>
  )
}

export default Checklist