import { faTrello } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

export default function ScrapeTaskID({ data, className }) {
  return (
    <>
      <ReactTooltip />
      <div className={classNames("flex items-center relative", className)}>
        {data?._id}
      </div>
    </>
  )
}