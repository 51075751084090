import axiosConfig from "../../../../axiosConfig";
import { Dialog } from "@headlessui/react";
import { useState, useContext, useRef, useEffect } from "react";
import { ModalContext } from "../../../../hooks/ModalContext";

const PreviewImageDialog = ({ contentItem, content, setContent }) => {
    let { handleModal } = useContext(ModalContext);
    const [selectedImage, setSelectedImage] = useState(contentItem.url);
    const [selectedImageObj, setSelectedImageObj] = useState();
    const [comment, setComment] = useState();

    useEffect(() => {
        if (!selectedImage) {
            setSelectedImage(undefined);
            return;
        }

        if (typeof selectedImage === "object") {
            setSelectedImageObj(selectedImage);
            const objectUrl = URL.createObjectURL(selectedImage);
            setSelectedImage(objectUrl);
        }
    });

    function handleSubmit(event) {

        event.preventDefault();

        content.site_images.map((selectedContentItem) => {
            if (selectedContentItem.label === contentItem.label) {
                if(comment) {
                    selectedContentItem['comment'] = comment; 
                }
                selectedContentItem.url = selectedImage;
                if (selectedImageObj) {
                    selectedContentItem.file = selectedImageObj;
                }
            }
        });

        handleModal(false);
    }

    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    return (
        <>
            <div className="pb-5">
                <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                >
                    {contentItem.label}
                </Dialog.Title>
            </div>
            <img
                style={{ backgroundColor: "rgb(148 163 184)" }}
                className="w-full ml-auto mr-auto"
                src={selectedImage}
            ></img>
            <div class="sm:col-span-2">
                <div class="mt-1 pt-3">
                    <textarea
                        placeholder="Use a picture of the mississippi river"
                        value={contentItem.comment}
                        id="message"
                        onChange={(event) => {setComment(event.target.value)}}
                        name="message"
                        rows="4"
                        class="h-20 py-3 px-4 block w-full shadow-sm focus:ring-tukios-green focus:border-tukios-green border border-gray-300 rounded-md"
                    ></textarea>
                </div>
            </div>
            <form className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Save
                </button>
                <input
                    type="file"
                    name="upload_file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    onChange={(event) => {
                        setSelectedImage(event.target.files[0]);
                    }}
                />
                <button
                    onClick={handleClick}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Upload
                </button>
                <button
                    type="button"
                    onClick={() => handleModal(false)}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                    Close
                </button>
            </form>
        </>
    );
};

export default PreviewImageDialog;
