import axiosConfig from '../axiosConfig'

const siteStatuses = {
  test: 'Test',
  readyForDesign: 'Ready for Design',
  inDesign: 'In Design',
  revisionsNeeded: 'Revisions Needed',
  waitingForContent: 'Waiting for Content',
  preStagingQa: 'Pre-Staging QA',
  staged: 'Staged',
  finalQa: 'Final QA',
  approved: 'Approved',
  ready: '100% Ready',
  missedLaunch: 'Missed Launch',
  live: 'Live',
  rebuild: 'Rebuild',
  lost: 'Lost'
}

const activityType = {
  domain: 'DOMAIN',
  scrape: 'SCRAPE',
  create: 'CREATE',
  inReview: 'IN_REVIEW',
  stage: 'STAGE',
  goLive: 'GO_LIVE',
  obit: 'OBIT',
  other: 'OTHER',
  designer: 'DESIGNER',
  intake: 'INTAKE',
  ae: 'AE',
  am: 'AM',
  sdr: 'SDR',
  cares: 'CARES', 
  florist: 'FLORIST',
  proxy: 'PROXY',
  dnsInstructions: 'DNS_INSTRUCTION'
}

const noteTypes = {
  launch: 'Launch',
  floral: 'Floral',
  design: 'Design',
  account: 'Account',
  seo: 'SEO'
}

const noteTypeFilters = [
  { id: noteTypes.launch, name: noteTypes.launch, color: 'bg-green-400' },
  { id: noteTypes.floral, name: noteTypes.floral, color: 'bg-pink-400' },
  { id: noteTypes.design, name: noteTypes.design, color: 'bg-purple-400' },
  { id: noteTypes.account, name: noteTypes.account, color: 'bg-red-500' },
  { id: noteTypes.seo, name: noteTypes.seo, color: 'bg-yellow-400' }
]

const domainsTypes = {
  domainRequest: 'Domain Request',
  proxy: 'Proxy'
}

const domainsTypeFilters = [
  { id: domainsTypes.domainRequest, name: domainsTypes.domainRequest, color: 'bg-green-400' },
  { id: domainsTypes.proxy, name: domainsTypes.proxy, color: 'bg-purple-400' }
]

const noteTypeBadgeColors = {
  'Launch': 'bg-green-100 text-green-800',
  'Floral': 'bg-pink-100 text-pink-800',
  'Design': 'bg-indigo-100 text-indigo-800',
  'Account': 'bg-red-100 text-red-800',
  'SEO': 'bg-yellow-100 text-yellow-800'
}

const checklistTypeFilters = [
  {
    id: 1,
    name: "Design",
    color: "bg-purple-400"
  },
  {
    id: 2,
    name: "Pre-Staging QA",
    color: "bg-blue-400"
  },
  {
    id: 3,
    name: "Function Check",
    color: "bg-blue-400"
  },
  {
    id: 4,
    name: "Launch",
    color: "bg-green-400"
  },
  {
    id: 5,
    name: "Floral",
    color: "bg-pink-400"
  },
  {
    id: 6,
    name: 'Account',
    color: 'bg-red-500'
  },
  {
    id: 7,
    name: 'SEO',
    color: 'bg-yellow-400'
  }
]

const providers = {
  tributeTech: "Tribute Tech",
  articDesigns: "Artic Designs",
  batesville: "Batesville",
  cfs: "CFS",
  fhwSolutions: "FHW Solutions",
  frazerConsultants: "Frazer Consultants",
  frm: "FRM",
  frontRunner: "FrontRunner",
  funeralInnovations: "Funeral Innovations",
  funeralOne: "Funeral One",
  funeralTech: "Funeral Tech",
  gather: "Gather",
  gemini: "Gemini",
  leapTie: "Leap Tie",
  legacy: "Legacy",
  mkj: 'MKJ',
  newcomer: 'Newcomer',
  srsComputing: "SRS Computing",
  tributes: "Tributes",
  newSite: "New Website",
  other: "Other",
}

const fundingPartners = {
  none: "None",
  wellable: "Wellabe (Great Western)",
  fdl: "FDL",
  atlanticCoastLife: "Atlantic Coast Life",
  SNL: "SNL",
  Precoa: "precoa",
  NGL: "NGL",
  cyrusWorks: "Cyrus Works",
}

const rescheduleReasons = {
  funeralHomeRequest: 'Funeral home request',
  noDomain: 'No domain',
  scrapeIssue: 'Scrape issue',
  waitingOnFlorist: 'Waiting on florist',
  waitingOnObitOnlyDev: 'Waiting on obit only developer'
}

const workingScrapeProviders = [
  providers.articDesigns,
  providers.batesville,
  providers.cfs,
  providers.fhwSolutions,
  providers.funeralOne,
  providers.funeralTech,
  providers.funeralInnovations,
  providers.frontRunner,
  providers.gather,
  providers.gemini,
  providers.leapTie,
  providers.other
]

const tributeTechProviders = [
  providers.cfs,
  providers.frazerConsultants,
  providers.frontRunner,
  providers.funeralTech,
  providers.funeralInnovations,
  providers.mkj,
  providers.funeralTech
]

const providerEmails = {
  'Tribute Tech': ['Jules@tributetech.com', 'chelsea.davidson@tributetech.com'],
  [providers.articDesigns]: 'mail@articdesigns.com ',
  [providers.batesville]: 'technology.Solutions@batesville.com',
  [providers.funeralOne]: 'changes@funeralone.com',
  [providers.other]: 'other'
}

const corporations = {
  parkLawn: {name: 'Park Lawn', id: 2},
  carriage: {name: 'Carriage', id: 3},
  vertin: {name: 'Vertin', id: 4},
  legacyFuneralGroup: {name: 'Legacy Funeral Group', id: 5},
  anthem: {name: 'Anthem Partners', id: 6},
  fidelity: {name: 'Fidelity', id: 7},
  impact: {name: 'Impact Funeral Group', id: 8},
  heritage: {name: 'Heritage Family', id: 9},
  legacySuccession: {name: 'Legacy Succession', id: 10},
}

const corporationContactInfo = {
  [corporations.parkLawn.name]: {
    id: corporations.parkLawn.id,
    contacts: [
      {
        name: 'Derek Beard',
        email: 'dbeard@plcorp.com',
        type: 'primary',
        domainContact: true,
        priority: 1
      },
      {
        name: 'Blake Ingenthron',
        email: 'bingenthron@plcorp.com',
        type: 'secondary',
        domainContact: true,
        priority: 2
      }
    ],
  },
  [corporations.carriage.name]: {
    id: corporations.carriage.id,
    contacts: [],
  },
  [corporations.vertin.name]: {
    id: corporations.vertin.id,
    contacts: [
      {
        name: 'Leah O\'Leary',
        email: 'leah.oleary@vertin.com',
        type: 'primary',
        domainContact: false,
      },
      {
        name: 'Kyrie Akerley',
        email: 'kyrie.akerley@vertin.com',
        type: 'secondary',
        domainContact: true,
        priority: 1
      }
    ],
  },
  [corporations.legacyFuneralGroup.name]: {
    id: corporations.legacyFuneralGroup.id,
    contacts: [
      {
        name: 'Steve Boudreaux',
        email: 'sboudreaux@legacyfuneragroup.com',
        type: 'primary',
        domainContact: false
      },
      {
        name: 'Bill Wimberly',
        email: 'wimberly@legacyfuneralgroup.com',
        type: 'secondary',
        domainContact: true,
        priority: 1
      }
    ], 
  },
  [corporations.anthem.name]: {
    id: corporations.anthem.id,
    contacts: [
      {
        name: 'Chad Jackson',
        email: 'cjackson@anthempartners.com',
        type: 'primary',
        domainContact: false
      },
      {
        name: 'Shawn Maggard',
        email: 'smaggard@anthempartners.com',
        type: 'secondary',
        domainContact: true,
        priority: 1
      }
    ],
  },
  [corporations.fidelity.name]: {
    contacts: [],
    domainContacts: []
  },
  [corporations.impact.name]: { 
    id: corporations.impact.id,
    contacts: [
      {
        name: 'Robbie Hulme',
        email: 'robbie@impactfuneral.com',
        type: 'primary',
        domainContact: true,
        priority: 1
      },
      {
        name: 'Josh McDougal',
        email: 'josh@impactfuneral.com',
        type: 'secondary'
      }
    ],
  },
  [corporations.heritage.name]: {
    id: corporations.heritage.id,    
    contacts: [
      {
        name: 'Patrick E. Mahoney',
        email: 'patrick@heritagefamily.com',
        type: 'primary',
        domainContact: false
      },
      {
        name: 'Chad Sams',
        email: 'chad@heritagefamily.com', 
        type: 'domain_primary',
        domainContact: true,
        priority: 1
      }
    ], 
  },
  [corporations.legacySuccession.name] : {  
    id: corporations.legacySuccession.id,  
    contacts: [
      {
        name: 'Amber Wesdorp',
        email: 'amber@legacysuccessionpartners.com',
        type: 'primary',
        domainContact: true,
        priority: 1
      },
    ],  
  },
}

const scrapeJobTypes = {
  obituaries: 'Obituaries',
  testimonials: 'Testimonials',
  merchandise: 'Merchandise',
  blogs: 'Blogs'
}

const scrapeRequestTypes = {
  obituary: 'Obituary',
  testimonial: 'Testimonial',
  blog: 'Blog',
  merchandise: 'Merchandise'
}

const statusBadgeColors = {
  'Test': 'bg-red-100 text-red-800',
  'Ready for Design': 'bg-purple-100 text-purple-800',
  'In Design': 'bg-indigo-100 text-indigo-800',
  'Revisions Needed': 'bg-teal-100 text-teal-800',
  'Waiting for Content': 'bg-pink-100 text-pink-800',
  'Pre-Staging QA': 'bg-sky-100 text-sky-800',
  'Staged': 'bg-yellow-100 text-yellow-800',
  'Final QA': 'bg-sky-100 text-sky-800',
  'Approved': 'bg-teal-100 text-teal-800',
  '100% Ready': 'bg-pink-100 text-pink-800',
  'Live': 'bg-green-100 text-green-800',
  'Rebuild': 'bg-teal-100 text-teal-800',
  'Lost': 'bg-red-100 text-red-800',
}

const scrapeRequestTypeBadgeColors = {
  'Obituary': 'bg-red-100 text-red-800',
  'Testimonial': 'bg-pink-100 text-pink-800',
  'Blog': 'bg-purple-100 text-purple-800',
  'Merchandise': 'bg-sky-100 text-sky-800'
}

const scrapeStatusBadgeColors = {
  'FAILURE': 'bg-red-100 text-red-800',
  'SUCCESS': 'bg-green-100 text-green-800',
  'REVOKED': 'bg-purple-100 text-purple-800',
  'PENDING': 'bg-yellow-100 text-yellow-800',
  'RECEIVED': 'bg-yellow-100 text-yellow-800',
  'STARTED': 'bg-yellow-100 text-yellow-800'
}

const ScrapeStatusFilters = [
  { id: '', name: 'Any status', color: 'bg-gray-400' },
  { id: 'SUCCESS', name: 'Success', color: 'bg-green-400' },
  { id: 'FAILURE', name: 'Failure', color: 'bg-red-400' },
  { id: 'REVOKED', name: 'Revoked', color: 'bg-purple-400' },
  { id: 'PENDING', name: 'Pending', color: 'bg-yellow-400' },
  { id: 'RECEIVED', name: 'Received', color: 'bg-yellow-400' },
  { id: 'STARTED', name: 'Started', color: 'bg-yellow-400' },
]

const ScrapeProviderFilters = [
  { id: '', name: 'Any provider', color: 'bg-gray-400' },
  { id: providers.cfs, name: providers.cfs, color: 'bg-gray-400' },
  { id: providers.funeralOne.replace(' ', ''), name: providers.funeralOne, color: 'bg-gray-400' },
  { id: providers.batesville, name: providers.batesville, color: 'bg-gray-400' },
  { id: providers.frazerConsultants.split(' ', ''), name: providers.frazerConsultants, color: 'bg-gray-400' }
]

const ScrapeTypeFilters = [
  { id: '', name: 'Any type', color: 'bg-gray-400' },
  { id: 'scrape', name: 'Scrape', color: 'bg-gray-400' },
  { id: 'parse', name: 'Parse', color: 'bg-gray-400' },
  { id: 'scheduled', name: 'Scheduled', color: 'bg-gray-400' },
]

const statusesReadyToScheduleLaunchDate = [
  siteStatuses.staged,
  siteStatuses.finalQa,
  siteStatuses.approved,
  siteStatuses.ready,
  siteStatuses.revisionsNeeded,
  siteStatuses.waitingForContent
]

const excludeFromLatestActivity = [
  siteStatuses.test,
  siteStatuses.live,
  siteStatuses.lost
]

const notableUsers = {
  'Indiana': '724',
  'Trey': '879',
  'Tyson': '474',
  'Landon': '702',
  'Sage': '704',
  'Ryan': '1125',
  'Coulter': '333'
}

const statusFilters = [
  { id: siteStatuses.readyForDesign, name: siteStatuses.readyForDesign, color: 'bg-purple-400' },
  { id: siteStatuses.inDesign, name: siteStatuses.inDesign, color: 'bg-indigo-400' },
  { id: siteStatuses.revisionsNeeded, name: siteStatuses.revisionsNeeded, color: 'bg-teal-400' },
  { id: siteStatuses.waitingForContent, name: siteStatuses.waitingForContent, color: 'bg-pink-400' },
  { id: siteStatuses.preStagingQa, name: siteStatuses.preStagingQa, color: 'bg-sky-400' },
  { id: siteStatuses.staged, name: siteStatuses.staged, color: 'bg-yellow-400' },
  { id: siteStatuses.finalQa, name: siteStatuses.finalQa, color: 'bg-sky-400' },
  { id: siteStatuses.approved, name: siteStatuses.approved, color: 'bg-teal-400' },
  { id: siteStatuses.ready, name: siteStatuses.ready, color: 'bg-pink-400' },
  { id: siteStatuses.missedLaunch, name: siteStatuses.missedLaunch, color: 'bg-purple-400'},
  { id: siteStatuses.live, name: siteStatuses.live, color: 'bg-green-400' },
  { id: siteStatuses.lost, name: siteStatuses.lost, color: 'bg-red-400' },
]

const setSiteProvider = (fhid, provider) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/provider`, {
      provider: provider
    })
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

const setSiteFundingPartner = (fhid, fundingPartner) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/fundingPartner`, {
      fundingPartner
    })
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

const setSiteCorporation = (fhid, corporationId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/corporation`, {
      corporationId: corporationId
    })
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

const setSiteReferralPartner = (fhid, referralPartnerId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/referralPartner`, {
      referralPartnerId: referralPartnerId
    })
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

const listDesigners = () => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/designers')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const listFlorists = () => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/florists')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const listAms = () => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/ams')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const listSdrs = () => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/sdrs')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const listAes = () => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/aes')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const listCares = () => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/cares')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const listCorporations = () => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/corporations')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const listReferralPartners = () => {
  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/referralPartners')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export { activityType, listDesigners, setSiteProvider, setSiteFundingPartner, setSiteCorporation, setSiteReferralPartner, listFlorists, listAms, listAes, listCorporations, listReferralPartners, listCares, listSdrs, providers, fundingPartners, rescheduleReasons, workingScrapeProviders, providerEmails, corporationContactInfo, tributeTechProviders, scrapeJobTypes, scrapeRequestTypes, scrapeRequestTypeBadgeColors, statusFilters, ScrapeStatusFilters, ScrapeTypeFilters, ScrapeProviderFilters, statusBadgeColors, scrapeStatusBadgeColors, excludeFromLatestActivity, siteStatuses, notableUsers, statusesReadyToScheduleLaunchDate, noteTypes, noteTypeFilters, noteTypeBadgeColors, checklistTypeFilters, domainsTypes, domainsTypeFilters }