import AmSiteFilter from "../../utils/site table/AmSiteFilter";
import SiteAssignAm from "../../utils/site table/columns/SiteAssignAm";
import SiteID from "../../utils/site table/columns/SiteID";
import SiteLastCoachingSession from "../../utils/site table/columns/SiteLastCoachingSession";
import SiteLaunchDate from "../../utils/site table/columns/SiteLaunchDate";
import SiteMarkCoached from "../../utils/site table/columns/SiteMarkCoached";
import SiteMembers from "../../utils/site table/columns/SiteMembers";
import SiteName from "../../utils/site table/columns/SiteName";
import SiteStatus from "../../utils/site table/columns/SiteStatus";
import CorporationSiteFilter from "../../utils/site table/CorporationSiteFilter";
import SiteSearch from "../../utils/site table/SiteSearch";
import SitesTable from "../../utils/site table/SitesTable";
import { siteStatuses } from "../../utils/utilityLibrary";
import SidebarPage from "../SidebarPage";

const CoachingSessions = () => {

  const filters = [
    {
      Component: SiteSearch,
      className: 'w-full lg:1/3'
    },
    {
      Component: AmSiteFilter,
      className: 'w-full lg:1/3'
    },
    {
      Component: CorporationSiteFilter,
      className: 'w-full lg:1/3'
    },
  ]

  const columns = [
    {
      label: "ID",
      Component: SiteID,
      className: 'lg:w-32'
    },
    {
      label: "Name",
      Component: SiteName,
      className: 'lg:w-1/6'
    },
    {
      label: "Status",
      Component: SiteStatus,
      className: 'lg:w-1/6 lg:text-center lg:flex lg:justify-center'
    },
    {
      label: "Members",
      Component: SiteMembers,
      className: 'lg:w-28 lg:flex lg:justify-center'
    },
    {
      label: "Last Coaching Session",
      Component: SiteLastCoachingSession,
      className: 'lg:w-1/6 lg:flex lg:justify-center lg:text-center'
    },
    {
      label: "Mark Coached",
      Component: SiteMarkCoached,
      className: 'lg:w-1/6 lg:flex lg:justify-center'
    },
    {
      label: "Assign",
      Component: SiteAssignAm,
      className: 'lg:w-1/4 lg:ml-auto lg:text-right lg:justify-end'
    }
  ]

  return (
    <SidebarPage docTitle='Ready for Coaching | Tukios Website Admin'>
      <div style={{ minWidth: '1300px' }}>
        <SitesTable filters={filters} columns={columns} defaultSiteParams={{
          label: Object.values(siteStatuses).filter(status => ['Live'].includes(status)),
          coachingSessions: true
        }}
        />
      </div>
    </SidebarPage>
  )
}

export default CoachingSessions;
