import classNames from 'classnames';
import SiteFilter from '../sites/SiteFilter';
import { checklistTypeFilters } from '../../utils/utilityLibrary';

export default function ChecklistTypeFilter({ handleChecklistTypeFilterChange, checklistFilter, className }) {

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        filters={checklistTypeFilters}
        selected={checklistFilter}
        setSelected={handleChecklistTypeFilterChange}
      />
    </div>
  )
}
