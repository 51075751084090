import classNames from "classnames";
import moment from "moment";


const ChecklistItem = ({ item, onClick }) => {

  return (
    <>
      <div className="relative flex items-start w-fit">
        <div className="flex h-5 items-center">
          <input
            id={item.id}
            name={item.id}
            type="checkbox"
            onClick={() => onClick(item)}
            checked={item.checked}
            className="h-4 w-4 rounded border-gray-300 text-tukios-navy focus:ring-tukios-navy"
          />
        </div>
        <span className={classNames("ml-3 text-sm")}>
          <span className={classNames(item.checked ? 'line-through' : '')}>{item.description}</span>
          {(item.checked == 1 && item.name) &&
            <span className={"text-sm text-gray-400"}> - {item.name} {moment(item.when).format('MM/DD/YYYY')}</span>
          }
        </span>
      </div>
    </>
  )
}

export default ChecklistItem;
