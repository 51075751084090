const buildPagination = ({ page, totalPages }) => {
  const current = page,
    last = totalPages,
    delta = 2,
    left = current - delta,
    right = current + delta + 1
  
  let range = [],
    rangeWithDots = [],
    l
  
    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || i >= left && i < right) {
        range.push(i)
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
        rangeWithDots.push(l + 1)
        } else if (i - l !== 1) {
        rangeWithDots.push('...')
        }
      }
      rangeWithDots.push(i)
      l = i
    }

    return rangeWithDots
}

export default buildPagination