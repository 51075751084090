import classNames from "classnames";
import moment from "moment";

export default function ScrapeScheduledTime({ data, className }) {

  let scheduledTime = data?.kwargs?.scheduled_time ? moment(data?.kwargs?.scheduled_time).format('h:mm A') : ''

  return (
    <div className={classNames(className)}>
      {
        scheduledTime &&
        <>
          {scheduledTime}
        </>
      }
    </div>
  )
}