import { BanIcon, CheckIcon, DotsHorizontalIcon, ExclamationIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import classNames from "classnames";

const PromoteProgressStep = ({ fhid, step, completeStep }) => {

  // const statusStyle = {
  //   WAIT: {
  //     bg: 'bg-gray-400',
  //     icon: DotsHorizontalIcon
  //   },
  //   WARN: {
  //     bg: 'bg-yellow-400',
  //     icon: ExclamationIcon
  //   },
  //   GOOD: {
  //     bg: 'bg-green-500',
  //     icon: CheckIcon
  //   }
  // }

  // const statusIcon = (status) => {
  //   return statusStyle[status].icon;
  // }

  useEffect(() => {

    if (step.status === undefined) {

      setTimeout(() => {
        step.validator(fhid)
        .then((validation) => {
          completeStep(step.id, validation.status, validation.description);
        })  
      }, 1000)
      
      
      // let timer = Math.floor(Math.random() * 3000);
      // let options = ['GOOD', 'WARN', 'ERROR'];
      // // let options = ['GOOD', 'WARN'];
      // let randomStatus = options[Math.floor(Math.random() * options.length)];
  
      // console.log(timer);
      // setTimeout(() => {
      //   completeStep(step.id, randomStatus, randomStatus === 'WARN' ? 'Only 5 found' : randomStatus === 'ERROR' ? 'Invalid configuration' : '');
      // }, timer);
    }

  }, []);

  return (
    <div className="flex items-center space-x-3">
      <div>
        <span
          className={classNames(
            'bg-gray-400 h-7 w-7 rounded-full flex items-center justify-center',
            { 'bg-green-500': step.status === 'GOOD' },
            { 'bg-yellow-500': step.status === 'WARN' },
            { 'bg-red-500': step.status === 'ERROR' },
          )}
        >
          {
            step.status === undefined ?
            <DotsHorizontalIcon className="h-5 w-5 text-white" aria-hidden="true" />
            : step.status === 'GOOD' ?
            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
            : step.status === 'WARN' ?
            <ExclamationIcon className="h-5 w-5 text-white" aria-hidden="true" />
            : step.status === 'ERROR' ?
            <BanIcon className="h-5 w-5 text-white" aria-hidden="true" />
            : null
          }
          
        </span>
      </div>
      <div className="min-w-0 flex-1 flex">
        <p className={classNames(
          'text-sm text-gray-500 text-left',
          { 'text-gray-900 font-medium': step.status !== undefined }
        )}>
          {step.content}
          {
            step.description &&
              <>
                <br/>
                <span className='font-normal text-gray-500'>{step.description}</span>
              </>
          }
        </p>
      </div>
    </div>
  )
}

export default PromoteProgressStep;
