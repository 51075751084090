import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ClipboardCopyIcon, ExclamationIcon } from '@heroicons/react/solid'
import { toast } from 'react-toastify'
import buildPreviewLink from "../../utils/buildPreviewLink"
import openSiteEditor from '../../utils/openSiteEditor'
import ContentShimmer from 'react-content-shimmer'
import StatusBadge from './StatusBadge'
import { useContext } from 'react'
import { ModalContext } from '../../hooks/ModalContext'
import GeneratePagesMetaDialog from '../pages/GeneratePagesMetaDialog'
import { setPagesMeta } from '../../utils/seoUtility'
import { useEffect } from 'react'
import { useState } from 'react'


const PagesHeader = ({ SitePagesContext }) => {

  let { site, edit, setEdit, pages, refreshPageList, isLoading, presetInformation, id, updateSite } = useContext(SitePagesContext)

  let { handleModal, setWidth } = useContext(ModalContext)

  const generateMeta = () => {
    setWidth('sm:max-w-3xl')
    handleModal(
      <GeneratePagesMetaDialog site={site} edit={edit} updateSite={updateSite} id={id} />
    )
  }

  const exportToCsv = () => {
    const columns = ['Name', 'Title', 'Path', 'Description']
    let csvContent = "data:text/csvcharset=utf-8,"

    csvContent += columns.join(",") + "\n"

    pages.forEach((page) => {
      const row = [
        `"${page.title}"`,
        `"${page.seo.title}"`,
        `"${page.path}"`,
        `"${page.seo.description ? page.seo.description : ''}"` || '',
      ]
      csvContent += row.join(",") + "\n"
    })

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", `${site.id} - ${site.name}.csv`)

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <div className="md:flex md:items-start md:justify-between md:space-x-5">
        <div className="flex items-start space-x-5">
          <div className="flex-shrink-0">
            {site.id ?
              <div className="relative mt-1">
                <span className="inline-flex items-center px-3 py-0.5 rounded text-sm font-medium bg-transparent text-tukios-dark border border-tukios-dark">
                  <>{site.id}</>
                </span>
              </div>
              :
              <ContentShimmer style={{ height: '26px', width: '78px', borderRadius: '7px' }} />
            }
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">
              {
                site.name ? site.name :
                  <ContentShimmer
                    rounded='7px'
                    style={{
                      width: '200px',
                      height: '28px'
                    }}
                  />
              }
            </h1>
            <StatusBadge siteStatus={site.launcher_label} />
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          {
            (!edit && site.duda_id && !isLoading) &&
            <button
              type="button"
              onClick={() => { exportToCsv() }}
              className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
            >
              Export to CSV
            </button>
          }
          {
            (!edit && site.duda_id) &&
            <button
              type="button"
              onClick={() => { generateMeta() }}
              className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
            >
              Generate Page Metadata
            </button>
          }
          {
            site.duda_id &&
            <>
              <button
                type="button"
                onClick={() => openSiteEditor(site.duda_id, site.id)}
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              >
                Edit Site
              </button>

              {
                edit === false &&
                <button
                  type="button"
                  onClick={() => setEdit(true)}
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                >
                  Edit Pages
                </button>
              }
              {
                edit === true &&
                <button
                  type="button"
                  onClick={() => {
                    setEdit(false)
                    setPagesMeta(site.id, pages, refreshPageList)
                  }}
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                >
                  Save Pages
                </button>
              }
              <span className="relative z-0 inline-flex items-stretch">
                <a
                  href={buildPreviewLink(site.duda_id)}
                  target="_blank"
                  rel="noreferrer"
                  className="relative inline-flex flex-grow items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-l-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                >
                  Preview
                </a>
                <CopyToClipboard
                  text={buildPreviewLink(site.duda_id)}
                  onCopy={() => toast.success('Preview link copied to clipboard!')}
                >
                  <button
                    className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                  >
                    <ClipboardCopyIcon className="-mx-1 h-4 w-4" />
                  </button>
                </CopyToClipboard>
              </span>
            </>
          }
        </div>
      </div>
      {
        !isLoading && presetInformation && presetInformation
          .filter(item => item.page_metadata_preset_id === site.page_metadata_preset_id)
          .length > 0 && (
          <div className="bg-yellow-100 mt-3 rounded-md text-yellow-700 p-4 flex text-sm " role="alert">
            <ExclamationIcon className="w-10" />
            <div className="ml-5">
              <p className="font-bold">Warning</p>
              <p>
                Preset {site.page_metadata_preset_id === 0 ? "\"default\"" : site.page_metadata_preset_id} is used on{' '}
                {presetInformation.filter(item => item.page_metadata_preset_id === site.page_metadata_preset_id)[0].Uses}{' '}
                other site(s) in {site.city}, {site.state}.
              </p>
            </div>
          </div>
        )
      }
    </>
  )
}

export default PagesHeader