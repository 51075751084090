import axiosConfig from '../axiosConfig';
import { toast } from 'react-toastify';
import logActivity from './logActivity';

const syncContentLibrary = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/syncContentLibrary/${fhid}`)
    .then((res) => resolve(res))
    .catch((err) => reject(err));
  })
}

const updateContentLibrary = (fhid) => {
  toast.promise(
    syncContentLibrary(fhid),
    {
      pending: 'Updating content library!',
      success: 'Finished updating content library!',
      error: 'Unable to update content library'
    }
  ).then((res) => {

    logActivity({
      message: `Updated content library`,
      fhId: fhid,
      mute: true
    })
  });
}

export default updateContentLibrary;
