import axiosConfig from '../axiosConfig'
import { addTrelloMember } from './users'

const assignFloristToFirm = (fhid, floristId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/assignFlorist`, {
      floristId: floristId
    })
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const assignFlorist = (fhid, floristId) => {
  return new Promise((resolve, reject) => {
    assignFloristToFirm(fhid, floristId)
      .then(() => {
        if (!floristId) {
          resolve(true)
        }
        else {
          addTrelloMember(fhid, floristId)
          resolve(true)
        }
      })
      .catch((err) => reject(err))
  })
}

export default assignFlorist