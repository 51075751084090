import { GlobeAltIcon, ShoppingCartIcon, TerminalIcon, SearchCircleIcon, HeartIcon } from "@heroicons/react/solid"
import classNames from "classnames"
import { useContext } from "react"
import ContentShimmer from "react-content-shimmer"

export default function SiteInfo({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  const checklistIds = !isLoading ? data?.site_checklist_ids : ''

  const iconClassNames = "h-5 w-5"
  const hasDomain = !isLoading ? checklistIds?.includes(156) : ''
  const hasPartialDomain = !isLoading ? checklistIds?.includes(191) : ''
  const hasScrape = !isLoading ? checklistIds?.includes(190) : ''
  const hasPartialScrape = !isLoading ? checklistIds?.includes(195) : ''
  const hasFlowers = !isLoading ? [165, 166, 167, 168, 169, 170].every(value => checklistIds?.includes(value)) : ''
  const hasPartialFlowers = !isLoading ? hasFlowers ? false : [165, 170].every(value => checklistIds?.includes(value)) : ''
  const hasPreLiveSeo = !isLoading ? [13, 15, 16].every(value => checklistIds?.includes(value)) : ''
  
  return (
    <div className={classNames(className)}>
      {!isLoading ?
        <div className={'flex-shrink-0 items-center gap-1 flex'}>
          <GlobeAltIcon data-tip={`${hasDomain ? 'Domain secured' : hasPartialDomain ? 'Domain requested' : 'Domain pending'}`} className={classNames(iconClassNames, hasDomain ? 'text-green-500' : hasPartialDomain ? 'text-yellow-300' : 'text-red-500')} />
          <TerminalIcon data-tip={`${hasScrape ? 'Scrape finished' : hasPartialScrape ? 'Scrape started' : 'Scrape pending'}`} className={classNames(iconClassNames, hasScrape ? 'text-green-500' : hasPartialScrape ? 'text-yellow-300' : 'text-red-500')} />
          <ShoppingCartIcon data-tip={`${hasFlowers ? 'Florist ready' : hasPartialFlowers ? 'Florist connected' : 'Florist pending'}`} className={classNames(iconClassNames, hasFlowers ? 'text-green-500' : hasPartialFlowers ? 'text-yellow-300' : 'text-red-500')} />
          <SearchCircleIcon data-tip={`${hasPreLiveSeo ? 'Pre-live SEO complete' : 'Pre-live SEO pending'}`} className={classNames(iconClassNames, hasPreLiveSeo ? 'text-green-500' : 'text-red-500')} />         
        </div> : <ContentShimmer style={{ height: '20px', width: '18px', borderRadius: '7px' }} />
      }
    </div>
  )
}