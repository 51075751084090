import classNames from "classnames"
import { useContext } from "react"
import { toast } from "react-toastify"
import DropdownWithAvatars from "../../../components/ui/DropdownWithAvatars"
import assignAm from "../../assignAm"
import logActivity from "../../logActivity"
import { isAM, isAdmin } from "../../users"
import ContentShimmer from "react-content-shimmer"

export default function SiteAmDropdown({ data, className, Context }) {

  let { sites, setSites, ams, isLoading } = useContext(Context)

  const setSiteAm = (fhid, e) => {

    let amId = e.user_id == 'none' ? null : e.user_id

    assignAm(fhid, amId)
      .then((res) => {

        logActivity({
          message: `${e.name} claimed the site`,
          fhId: fhid,
          logType: 'AM'
        })

        let currentSites = [...sites]
        let currentSiteIndex = currentSites.map(s => s.id).indexOf(fhid)
        let currentSite = { ...currentSites[currentSiteIndex] }

        currentSite.am_id = e.id == 'none' ? null : e.id
        currentSite.am_name = e.id == 'none' ? null : e.name
        currentSite.am_image = e.id == 'none' ? null : e.image

        currentSites[currentSiteIndex] = currentSite

        setSites(currentSites)

        toast.success(e.name == 'Not assigned' ? 'Unassigned' : `${e.name} assigned to site!`)
        if (res !== true) toast.warn(res)
      })
      .catch((err) => {
        toast.error('Error updating site am')
      })

  }

  return (
    <div className={classNames("whitespace-nowrap text-sm font-medium text-gray-900", className)}>
      {!isLoading ?
        <>
          {
            data.corporation_id === 1 ?
              <DropdownWithAvatars
                people={ams}
                current={data.am_id ? {
                  name: data.am_name,
                  image: data.am_image
                } : null}
                handleSelection={(e) => setSiteAm(data.id, e)}
                disabled={isAdmin() ? false : !isAM()}
              /> : <div className="ml-6">Corporate site</div>
          } </> : <ContentShimmer style={{ height: '34px', width: '200px', borderRadius: '7px' }} />
      }
    </div>
  )
}