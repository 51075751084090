import axiosConfig from '../axiosConfig'

const updateAddonCommitted = (fhid, addonId, committed, previousCustomer=false) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`api/addons/${fhid}/updateCommitted`, {
      addonId: addonId,
      committed: committed,
      previousCustomer: previousCustomer
    })
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const updateAddonSetup = (fhid, addonId, setup, previousCustomer=false) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`api/addons/${fhid}/updateSetup`, {
      addonId: addonId,
      setup: setup,
      previousCustomer: previousCustomer
    })
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export { updateAddonCommitted, updateAddonSetup }