import axiosConfig from '../axiosConfig';

const deleteNote = (noteId) => {

  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/notes/delete`, {
      noteId: noteId
    })
    .then((res) => resolve())
    .catch((err) => reject(err));
  })

}

export default deleteNote;