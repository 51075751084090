import classNames from 'classnames';

const badgeColors = {
    'Pre-Staging QA': 'bg-sky-100 text-sky-800',
    'Final QA': 'bg-sky-100 text-sky-800',
    'Post-Live QA': 'bg-green-100 text-green-800',
    'Client Request' : 'bg-pink-100 text-pink-800',
}

const QAStatusBadge = ({ status }) => {

    return (
        <span className={classNames('px-3 inline-flex leading-5 rounded-full text-xs w-fit ml-auto mr-auto', badgeColors[status])}>
            {status}
        </span>
    )
}

export default QAStatusBadge;