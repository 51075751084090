import classNames from 'classnames'
import React from 'react'
import ContentShimmer from 'react-content-shimmer'

export default function ScrapeActionsLoading({ data, className }) {

  return (
    <div className={classNames(className, "whitespace-nowrap font-medium")}>
      <ContentShimmer style={{ height: '30px', width: '110px', borderRadius: '7px', marginRight: '7px' }} />
    </div>
  )
}