import classNames from "classnames";
import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";

export default function ScrapeRequestNeededBy({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <>
      <div className={classNames(className)}>
        {!isLoading ?
          <>
            {
              data.pretty_needed_before ?
              <>
                {data.pretty_needed_before}
              </> : <>-</>
            }
          </>
          : <ContentShimmer style={{ height: '14px', width: '100px', borderRadius: '7px' }} />
        }
      </div>
    </>
  )
}