import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';

export default function AeSiteFilter({ Context, className }) {

  const { aes, aeFilter, handleAeChange, anyAe } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        placeholder={anyAe}
        filters={aes}
        selected={aeFilter}
        setSelected={handleAeChange}
      />
    </div>
  )
}
