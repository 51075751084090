import axiosConfig from '../axiosConfig';

const markVerified = (issueId, verified, fhid) => {

  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/siteRevisions/markVerified`, {
      issueId: issueId,
      fhid: fhid,
      verified: verified
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err));
  })

}

export default markVerified;