import { Menu } from '@headlessui/react'
import { useContext, useState } from "react"
import { ModalContext } from "../../hooks/ModalContext"
import DangerDialog from "../ui/modal/dialogs/Danger"
import deleteSite from "../../utils/deleteSite"
import ThreeDotMenu from "../ui/ThreeDotMenu"
import { isAdmin, isAE, isQA, isDesigner, isLaunchSpecialist } from "../../utils/users"
import { lost, inReview, goLive } from '../../utils/updateSite'
import ScheduleLaunchDialog from '../pages/ScheduleLaunchDialog'
import { siteStatuses, statusesReadyToScheduleLaunchDate } from '../../utils/utilityLibrary'
import { toast } from 'react-toastify'
import RequestScrapeDialog from '../pages/ScrapeRequestDialog'
import { ScrapeRequestFormProvider } from '../../hooks/ScrapeRequestFormContext'
import UnScheduleLaunchDialog from '../ui/modal/dialogs/UnScheduleLaunchDialog'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SiteMenu = ({ site_name, site_id, is_test, launcher_label, trello_card_id, updateSiteLabelDisplay, updateLaunchDateDisplay, data }) => {

  let { handleModal } = useContext(ModalContext)

  const scheduleLaunch = (site) => {
    handleModal(
      <ScheduleLaunchDialog site={site} updateLaunchDateDisplay={updateLaunchDateDisplay} reschedule={true} />
    )
  }

  const requestScrape = (site) => {
    handleModal(
      <ScrapeRequestFormProvider>
        <RequestScrapeDialog site={site} />
      </ScrapeRequestFormProvider>
    )
  }

  const unscheduleSiteConfirmation = (site) => {
    handleModal(<UnScheduleLaunchDialog site={site} updateLaunchDateDisplay={updateLaunchDateDisplay} />)
  }



  const markSiteLostConfirmation = (site_id, updateSiteLabelDisplay) => {
    handleModal(<DangerDialog
      title={`${site_id} - ${site_name}`}
      actionText='Mark Lost'
      messageText='Are you sure you want to mark this site as lost?'
      actionFn={() => markSiteLostToast(site_id, updateSiteLabelDisplay)}
    />)
  }

  const markSiteLostToast = (site_id, updateSiteLabelDisplay) => {
    toast.promise(
      lost(site_id, updateSiteLabelDisplay),
      {
        pending: 'Marking site as lost...',
        success: 'Marked as lost!',
        error: 'Unable to mark site as lost'
      }
    ).then(() => {
      handleModal(false)
    }).catch(() => {
      handleModal(false)
    })
  }

  const markInReviewSiteConfirmation = (site_id, updateSiteLabelDisplay) => {
    handleModal(<DangerDialog
      title={`${site_id} - ${site_name}`}
      actionText='Submit'
      messageText='Are you sure you want to submit this site for review?'
      actionFn={() => markInReviewSiteToast(site_id, updateSiteLabelDisplay)}
    />)
  }

  const markInReviewSiteToast = (site_id, updateSiteLabelDisplay) => {
    toast.promise(
      inReview(site_id, updateSiteLabelDisplay),
      {
        pending: 'Submitting site for review...',
        success: 'Site submitted for review!',
        error: 'Unable to submit site for review'
      }
    ).then(() => {
      handleModal(false)
    }).catch(() => {
      handleModal(false)
    })
  }

  const markLiveSiteConfirmation = (site_id, updateSiteLabelDisplay) => {
    handleModal(<DangerDialog
      title={`${site_id} - ${site_name}`}
      actionText='Mark Live'
      messageText='Are you sure you want to mark this site as live?'
      actionFn={() => markLiveSiteToast(site_id, updateSiteLabelDisplay)}
    />)
  }

  const markLiveSiteToast = (site_id, updateSiteLabelDisplay) => {
    toast.promise(
      goLive(site_id, updateSiteLabelDisplay),
      {
        pending: 'Marking site as live...',
        success: 'Marked as live!',
        error: 'Unable to mark site as live'
      }
    ).then(() => {
      handleModal(false)
    }).catch(() => {
      handleModal(false)
    })
  }

  const siteDeleted = () => {
    handleModal(false)
  }

  const deleteSiteConfirmation = (site_id) => {
    handleModal(<DangerDialog
      title={`Delete Site ${site_id}`}
      actionText='Delete'
      messageText='Are you sure you want to delete this site? This action cannot be undone.'
      actionFn={() => deleteSite(site_id, siteDeleted)}
    />)
  }

  return (
    <ThreeDotMenu>
      {
        trello_card_id &&
        <Menu.Item>
          {({ active }) => (
            <a
              href={`https://trello.com/c/${trello_card_id}`}
              target='_blank'
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              Trello Card
            </a>
          )}
        </Menu.Item>
      }
      {
        (isAE() || isDesigner() || isQA() || isAdmin()) &&
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => requestScrape(data)}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              Request Scrape
            </button>
          )}
        </Menu.Item>
      }
      {
        (data?.scheduled_launch_date && statusesReadyToScheduleLaunchDate.includes(data.launcher_label)) && (isAE() || isAdmin()) &&
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => scheduleLaunch(data)}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              Reschedule Launch Date
            </button>
          )}
        </Menu.Item>
      }
      {
        (data?.scheduled_launch_date && data.launcher_label != siteStatuses.live && (isLaunchSpecialist() || isAdmin())) &&
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => unscheduleSiteConfirmation(data)}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              Unschedule Launch
            </button>
          )}
        </Menu.Item>
      }
      {
        ([siteStatuses.inDesign, siteStatuses.readyForDesign].includes(launcher_label) && (isDesigner() || isAdmin())) &&
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => markInReviewSiteConfirmation(site_id, updateSiteLabelDisplay)}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              Submit for Review
            </button>
          )}
        </Menu.Item>
      }
      {
        launcher_label != 'Live' &&
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => markLiveSiteConfirmation(site_id, updateSiteLabelDisplay)}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              Go Live
            </button>
          )}
        </Menu.Item>
      }
      {
        (!['Lost', 'Test'].includes(launcher_label)) &&
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => markSiteLostConfirmation(site_id, updateSiteLabelDisplay)}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              Mark Lost
            </button>
          )}
        </Menu.Item>
      }
      {
        (is_test && isAdmin()) &&
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={() => deleteSiteConfirmation(site_id)}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full text-left px-4 py-2 text-sm'
              )}
            >
              Delete test site
            </button>
          )}
        </Menu.Item>
      }
    </ThreeDotMenu>
  )
}

export default SiteMenu