import classNames from "classnames"

const IconTabs = ({ tabs, selectedTab, setSelectedTab }) => {

  return (
    <div className="pb-6">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-tukios-navy focus:ring-tukios-navy"
          defaultValue={tabs.find((tab) => tab.name == selectedTab.name).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setSelectedTab(tab)}
                className={classNames(
                  tab.name == selectedTab.name
                    ? 'border-tukios-navy text-tukios-navy'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 pb-4 px-1 text-sm font-medium'
                )}
              >
                <tab.icon
                  className={classNames(
                    tab.name == selectedTab.name ? 'text-tukios-navy' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default IconTabs