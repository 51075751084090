import React, { useEffect } from 'react';
import classNames from 'classnames';

const SidebarPage = ({ docTitle, pageTitle, children, fullWidth, maxWidth, scroll = true }) => {

  useEffect(() => {
    if (docTitle) {
      document.title = docTitle;
    }
  }, []);

  let maxWidthClass = maxWidth ? 'max-w-7xl mx-auto' : ''
  
  return (
    <main className={classNames("flex-1 relative focus:outline-none", scroll ? "overflow-y-scroll" : "overflow-y-auto")}>
      <div className={classNames("py-6", maxWidthClass)}>
        {
          pageTitle &&
          <div className="max-w-7x mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">{pageTitle}</h1>
          </div>
        }
        <div className='mx-auto px-4 sm:px-6 md:px-5'>
          {children}
        </div>
      </div>
    </main>
  )
}

export default SidebarPage;
