import classNames from "classnames"
import { useContext } from "react"
import { toast } from "react-toastify"
import DropdownWithAvatars from "../../../components/ui/DropdownWithAvatars"
import assignDesigner from "../../assignDesigner"
import logActivity from "../../logActivity"
import { isDesigner } from "../../users"
import ContentShimmer from "react-content-shimmer"

export default function SiteDesignerDropdown({ data, className, Context }) {

  let { sites, setSites, designers, isLoading } = useContext(Context)

  const setSiteDesigner = (fhid, e) => {

    let designerId = e.user_id == 'none' ? null : e.user_id
    let currentDesigner = sites.find(site => site.id == fhid).designer_name

    if(!designerId && !currentDesigner) {
      return
    }

    assignDesigner(fhid, designerId)
      .then((res) => {

        logActivity({
          message: !designerId ? `${currentDesigner} unassigned from site` : `${e.name} assigned to site`,
          fhId: fhid,
          logType: 'DESIGNER'
        })

        let currentSites = [...sites]
        let currentSiteIndex = currentSites.map(s => s.id).indexOf(fhid)
        let currentSite = { ...currentSites[currentSiteIndex] }

        currentSite.designer_id = e.id == 'none' ? null : e.id
        currentSite.designer_name = e.id == 'none' ? null : e.name
        currentSite.designer_image = e.id == 'none' ? null : e.image

        currentSites[currentSiteIndex] = currentSite

        setSites(currentSites)

        toast.success(!designerId ? `${currentDesigner} unassigned from site!` : `${e.name} assigned to site!`)
        if (res !== true) toast.warn(res)
      })
      .catch((err) => {
        toast.error('Error updating site designer')
      })
  }

  return (
    <div className={classNames("whitespace-nowrap text-sm font-medium text-gray-900", className)}>
      {!isLoading ?
        <DropdownWithAvatars
          people={designers}
          current={data.designer_id ? {
            name: data.designer_name,
            image: data.designer_image
          } : null}
          handleSelection={(e) => setSiteDesigner(data.id, e)}
        /> : <ContentShimmer style={{ height: '34px', width: '200px', borderRadius: '7px' }} />
      }
    </div>
  )
}