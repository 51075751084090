import classNames from "classnames"
import { useContext } from "react"
import ContentShimmer from "react-content-shimmer"

export default function AddonCommitted({ data, className, Context }) {

  let { isLoading, commitAddon, checkboxLoading } = useContext(Context)

  return (
    <>
      <div className={className}>
        {!isLoading ?
          <>
            <span className="lg:hidden">Committed: </span>
            <input
              id={data.id}
              name={data.id}
              type="checkbox"
              onClick={(e) => commitAddon(e, data)}
              defaultChecked={data.committed}
              disabled={checkboxLoading}
              className={classNames("h-4 w-4 rounded border-gray-300 text-tukios-navy focus:ring-tukios-navy", checkboxLoading ? 'opacity-75' : '')}
            />
          </> :
          <ContentShimmer style={{ height: '14px', width: '14px', borderRadius: '7px' }} />
        }
      </div>
    </>
  )
}