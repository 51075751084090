import classNames from 'classnames';
import { excludeFromLatestActivity, statusBadgeColors } from '../../utils/utilityLibrary';

const StatusBadge = ({ siteStatus, latestActivity }) => {

    const status = latestActivity && !excludeFromLatestActivity.includes(siteStatus)  ? `${siteStatus} (${latestActivity} day${latestActivity == 1 ? '' : 's'})` : siteStatus;

    return (
        <span className={classNames('px-3 inline-flex leading-5 rounded-full text-xs', statusBadgeColors[siteStatus])}>
            {status}
        </span>
    )
}

export default StatusBadge;