import React, { Component } from 'react';
import SidebarPage from '../SidebarPage';
import AllSitesTable from '../sites/AllSitesTable';

export default class SitesPage extends Component {

  render() {
    return (
      <SidebarPage docTitle='All Sites | Tukios Website Admin'>
        <AllSitesTable />
      </SidebarPage>
    )
  }
}