import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';
import { ScrapeStatusFilters } from '../utilityLibrary';

export default function ScrapeStatusFilter({ Context, className }) {

  let { scrapeStatusFilter, handleScrapeStatusChange } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        filters={ScrapeStatusFilters}
        selected={scrapeStatusFilter}
        setSelected={handleScrapeStatusChange}
      />
    </div>
  )
}
