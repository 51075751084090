import classNames from "classnames"
import { useContext } from "react"
import ContentShimmer from "react-content-shimmer"

export default function SiteMembers({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={classNames(className)}>
      {!isLoading ?
        <div className={'flex-shrink-0 -space-x-1 items-center flex'}>
          {
            data?.designer_id ? <img data-tip={data?.designer_name} class="max-w-none h-6 w-6 rounded-full ring-2 ring-white" src={data?.designer_image} alt={data?.designer_name} /> : <></>
          }
          {
            data?.ae_id ? <img data-tip={data?.ae_name} class="max-w-none h-6 w-6 rounded-full ring-2 ring-white" src={data?.ae_image} alt={data?.ae_name} /> : <></>
          }
          {
            data?.florist_id ? <img data-tip={data?.florist_name} class="max-w-none h-6 w-6 rounded-full ring-2 ring-white" src={data?.florist_image} alt={data?.florist_name} /> : <></>
          }
          {
            data?.care_id ? <img data-tip={data?.care_name} class="max-w-none h-6 w-6 rounded-full ring-2 ring-white" src={data?.care_image} alt={data?.care_name} /> : <></>
          }
          {
            data?.am_id ? <img data-tip={data?.am_name} class="max-w-none h-6 w-6 rounded-full ring-2 ring-white" src={data?.am_image} alt={data?.am_name} /> : <></>
          }
          {
            data?.sdrs_id ? <img data-tip={data?.sdr_name} class="max-w-none h-6 w-6 rounded-full ring-2 ring-white" src={data?.sdr_image} alt={data?.sdr_name} /> : <></>
          }
        </div> :
        <div class="flex-shrink-0 -space-x-1 items-center flex">
          <ContentShimmer style={{ height: '24px', width: '24px', borderRadius: '20px', marginLeft: 'auto', marginRight: 'auto' }} />
          <ContentShimmer style={{ height: '24px', width: '24px', borderRadius: '20px', marginLeft: 'auto', marginRight: 'auto' }} />
          <ContentShimmer style={{ height: '24px', width: '24px', borderRadius: '20px', marginLeft: 'auto', marginRight: 'auto' }} />
        </div>
      }
    </div>
  )
}