import axiosConfig from '../axiosConfig';

const createNote = ( fhid, note, type ) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post('/api/notes', {
      fhid: fhid,
      note: note,
      type: type
    }).then((res) => resolve(res))
    .catch((err) => reject(err));
  })
}

export default createNote;