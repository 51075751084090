import { SearchIcon, XIcon } from "@heroicons/react/outline";

const TemplateSearch = ({ searchTerm, handleSearch, clearSearch }) => {
  return (
    <div className="flex flex-col mb-4">
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            className="focus:outline-none focus:border-none block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
            placeholder="Search templates..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <button
          type="button"
          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:border-none"
          onClick={clearSearch}
        >
          <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          {/* <span>Clear</span> */}
        </button>
      </div>

      {/* <div className='flex flex-col lg:flex-row justify-between items-start mb-1'>
        <div className='w-full lg:w-1/2'>
          
          <div className="flex rounded-lg mb-2 lg:mb-0 lg:mr-2 border">
            <span className="inline-flex shadow-none items-center px-3 rounded-l-lg border border-r-0 bg-gray-50 text-gray-500 sm:text-sm">
              <SearchIcon className='w-4 h-4' />
            </span>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className='appearance-none border rounded-r-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>
          <button
            onClick={clearSearch}
            className='text-sm text-gray-400'
          >
            Clear search
          </button>
        </div>
      </div> */}
    </div>
  )
}

export default TemplateSearch;