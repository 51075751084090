import axiosConfig from '../axiosConfig'
import { addTrelloMember } from './users'

const assignCareTeamToFirm = (fhid, careId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/assignCares`, {
      careId: careId
    })
      .then(() => resolve())
      .catch((err) => reject(err))
  })
}

const assignCares = (fhid, careId) => {
  return new Promise((resolve, reject) => {
    assignCareTeamToFirm(fhid, careId)
      .then(() => {
        if (!careId) {
          resolve(true)
        }
        else {
          addTrelloMember(fhid, careId)
          resolve(true)
        }
      })
      .catch((err) => reject(err))
  })
}

export default assignCares