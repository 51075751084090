
import SidebarLink from './SidebarLink'
import SidebarDropdown from './SidebarDropdown'
import navItemsGenerator from './NavItems'

const DesktopNavigation = ({ shrink }) => {

  return (
    <div className="mt-5 flex-1 flex flex-col">
      <nav className="flex-1 px-2 space-y-1">
        {
          navItemsGenerator().map((item) => (
            !item.subPages ?
              // <SidebarLink badge={ sidebarData?.[item?.path] } item={item} key={item.path ? item.path : item.href} />
              <SidebarLink item={item} shrink={shrink} key={item.path ? item.path : item.href} />
              :
              <SidebarDropdown item={item} shrink={shrink} key={item.name} />
          ))
        }
      </nav>
    </div>
  )
}

export default DesktopNavigation