import { Menu } from '@headlessui/react';
import { useContext } from "react";
import ThreeDotMenu from "../ui/ThreeDotMenu";
import classNames from 'classnames';

const ScrapeMenu = ({ data, Context }) => {

  let { revokeTask, viewResult } = useContext(Context)

  return (
    <ThreeDotMenu>
      {
        (['STARTED', 'RECEIVED'].includes(data?.status)) ?
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => revokeTask(data?._id, data?.kwargs?.legacy_fhid)}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full text-left px-4 py-2 text-sm'
                )}
              >
                Revoke Task
              </button>
            )}
          </Menu.Item>
          :
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => viewResult(data?.result)}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full text-left px-4 py-2 text-sm'
                )}
              >
                View Result
              </button>
            )}
          </Menu.Item>
      }
    </ThreeDotMenu>
  )
}

export default ScrapeMenu;