import React, { createContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { debounce } from "lodash"
import listSites from "../../utils/listSites"
import Table from "../../utils/table/Table"
import SitePagination from "../../utils/site table/SitePagination"
import moment from "moment"
import axios from 'axios'
import { useSiteFilters } from "../../hooks/SiteFiltersContext"

const SiteContext = createContext()

export default function SitesTable({ columns, filters = [], leadingIcon, trailingIcon, defaultPerPage = 20, defaultSiteParams = {} }) {

  const anyDesigner = {
    id: '*',
    name: 'Any designer',
    color: 'bg-gray-400'
  }

  const anyAe = {
    id: '*',
    name: 'Any account executive',
    color: 'bg-gray-400'
  }

  const anyAm = {
    id: '*',
    name: 'Any account manager',
    color: 'bg-gray-400'
  }

  const anySdr = {
    id: '*',
    name: 'Any sales developement rep.',
    color: 'bg-gray-400'
  }

  const anyFlorist = {
    id: '*',
    name: 'Any floral specialist',
    color: 'bg-gray-400'
  }

  const anyCare = {
    id: '*',
    name: 'Any launch team',
    color: 'bg-gray-400'
  }

  const anyCorporation = {
    id: '*',
    name: 'Any corporation',
    color: 'bg-gray-400'
  }

  const anyStatus = {
    id: '*',
    name: 'Any status',
    color: 'bg-gray-400'
  }

  const { designers, florists, aes, ams, sdrs, cares, corporations } = useSiteFilters()
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [sites, setSites] = useState([])
  const [assignLoading, setAssignLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(defaultPerPage)
  const [totalSites, setTotalSites] = useState(0)
  const [statusFilter, setStatusFilter] = useState(anyStatus)
  const [designerFilter, setDesignerFilter] = useState(anyDesigner)
  const [floristFilter, setFloristFilter] = useState(anyFlorist)
  const [careFilter, setCareFilter] = useState(anyCare)
  const [aeFilter, setAeFilter] = useState(anyAe)
  const [amFilter, setAmFilter] = useState(anyAm)
  const [sdrFilter, setSdrFilter] = useState(anySdr)
  const [corporationFilter, setCorporationFilter] = useState(anyCorporation)
  const searchRef = useRef()


  const search = () => {
    updateSites({ query: searchRef.current.value, tempPage: 1 })
    setPage(1)
  }

  const updateSiteLabelDisplay = (fhid, label) => {
    let currentSites = [...sites]

    currentSites.find(site => site.id == fhid).launcher_label = label
    setSites(currentSites)
  }

  const updateLaunchDateDisplay = (fhid, launchDate) => {
    let prettyLaunchDate = launchDate ? moment(launchDate, 'YYYYMMDD').format('MMM D, YYYY').toString() : null

    let currentSites = [...sites]

    let currentSite = currentSites.find(site => site.id == fhid)
    currentSite.scheduled_launch_date = launchDate
    currentSite.pretty_scheduled_launch_date = prettyLaunchDate
    setSites(currentSites)
  }

  const updateLastCoachedDisplay = (fhid) => {
    let currentSites = [...sites]

    let currentSite = currentSites.find(site => site.id == fhid)
    currentSite.last_coaching_session = moment().toString()
    currentSite.pretty_last_coaching_session = moment().format('MMM D, YYYY').toString()
    setSites(currentSites)
  }

  const removeScrape = (fhid, type) => {
    let currentSites = [...sites];

    let indexToRemove = currentSites.findIndex((site) => {
      return site.type === type && site.id === fhid
    })

    if (indexToRemove !== -1) {
      currentSites.splice(indexToRemove, 1)
      setSites(currentSites)
    } else {
      console.log("No matching item found to remove.")
    }
  }

  const updateSiteInfoDisplay = (fhid, item) => {
    let currentSites = [...sites]

    let currentSite = currentSites.find(site => site.id == fhid)
    let currentSiteChecklistIds = currentSite.site_checklist_ids ?? []

    if (item.checked) {
      currentSiteChecklistIds.push(item.id)
    } else {
      currentSiteChecklistIds = currentSiteChecklistIds.filter(checklistId => checklistId !== item.id)
    }

    currentSite.site_checklist_ids = currentSiteChecklistIds

    setSites(currentSites)
  }

  const goToPage = (pageNumber) => {
    setPage(pageNumber)
  }

  const handleDesignerChange = (e) => {
    setPage(1)
    setDesignerFilter(e)
  }

  const handleFloristChange = (e) => {
    setPage(1)
    setFloristFilter(e)
  }

  const handleAeChange = (e) => {
    setPage(1)
    setAeFilter(e)
  }

  const handleAmChange = (e) => {
    setPage(1)
    setAmFilter(e)
  }

  const handleSdrChange = (e) => {
    setPage(1)
    setSdrFilter(e)
  }

  const handleCareChange = (e) => {
    setPage(1)
    setCareFilter(e)
  }

  const handleCorporationChange = (e) => {
    setPage(1)
    setCorporationFilter(e)
  }

  const handleStatusChange = (e) => {
    setPage(1)
    setStatusFilter(e)
  }

  const handleSearch = debounce(search, 300)

  const updateSites = () => {

    if (cancelTokenSource) {
      cancelTokenSource.cancel('Request canceled by user')
    }

    const newCancelTokenSource = axios.CancelToken.source()
    setCancelTokenSource(newCancelTokenSource)

    setIsLoading(true)
    listSites({
      label: defaultSiteParams?.label ? statusFilter.id != '*' ? statusFilter.id : defaultSiteParams.label : statusFilter.id,
      query: searchRef?.current?.value,
      designer: defaultSiteParams?.designer ? defaultSiteParams.designer.id : designerFilter.user_id,
      florist: defaultSiteParams?.florist ? defaultSiteParams.florist.id : floristFilter.user_id,
      ae: defaultSiteParams?.ae ? defaultSiteParams.ae.id : aeFilter.user_id,
      am: defaultSiteParams?.am ? defaultSiteParams.am.id : amFilter.user_id,
      sdr: defaultSiteParams?.sdr ? defaultSiteParams.sdr.id : sdrFilter.user_id,
      care: defaultSiteParams?.care ? defaultSiteParams.care.id : careFilter.user_id,
      page: page,
      perPage: perPage,
      corporation: corporationFilter.id,
      urgent_build_filter: defaultSiteParams?.urgent_build_filter ?? null,
      exclude_hosted_obit_sites: defaultSiteParams?.exclude_hosted_obit_sites ?? null,
      orderByLatestActivity: defaultSiteParams?.orderByLatestActivity ?? null,
      launchSchedule: defaultSiteParams?.launchSchedule ?? null,
      preLiveSeo: defaultSiteParams?.preLiveSeo ?? null,
      postLiveSeo: defaultSiteParams?.postLiveSeo ?? null,
      aeMySites: defaultSiteParams?.aeMySites ?? null,
      designerMySites: defaultSiteParams?.designerMySites ?? null,
      careMySites: defaultSiteParams?.careMySites ?? null,
      sdrMySites: defaultSiteParams?.sdrMySites ?? null,
      allSites: defaultSiteParams?.allSites ?? null,
      coachingSessions: defaultSiteParams?.coachingSessions ?? null,
      scrapeRequest: defaultSiteParams?.scrapeRequest ?? null,
      unassignedSitesFlorist: defaultSiteParams?.unassignedSitesFlorist ?? null,
      cancelToken: newCancelTokenSource.token
    })
      .then((sitesData) => {
        setSites(sitesData.data)
        setTotalSites(sitesData.totalSites)
        setIsLoading(false)
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.log(err)
          toast.error('Unable to load sites')
        }
      })
  }

  const clearFilters = () => {
    searchRef.current.value = ''
    setPage(1)
    setAeFilter(anyAe)
    setDesignerFilter(anyDesigner)
    setFloristFilter(anyFlorist)
    setAmFilter(anyAm)
    setCareFilter(anyCare)
    setSdrFilter(anySdr)
    setCorporationFilter(anyCorporation)
    setStatusFilter(anyStatus)
  }

  useEffect(() => {
    updateSites()

    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Request canceled by user')
      }
    }
  }, [page, statusFilter, designerFilter, floristFilter, careFilter, aeFilter, amFilter, sdrFilter, corporationFilter])


  return (
    <div className="w-full">
      <SiteContext.Provider
        value={{
          sites,
          setSites,
          assignLoading,
          setAssignLoading,
          handleSearch,
          defaultSiteParams,
          clearFilters,
          goToPage,
          searchRef,
          page,
          perPage,
          totalSites,
          cares,
          designers,
          florists,
          aes,
          ams,
          sdrs,
          corporations,
          anyDesigner,
          anyAe,
          anyAm,
          anySdr,
          anyFlorist,
          anyCorporation,
          designerFilter,
          aeFilter,
          amFilter,
          careFilter,
          sdrFilter,
          floristFilter,
          corporationFilter,
          statusFilter,
          handleDesignerChange,
          handleAeChange,
          handleAmChange,
          handleCareChange,
          handleSdrChange,
          handleFloristChange,
          handleCorporationChange,
          handleStatusChange,
          updateSiteLabelDisplay,
          updateLaunchDateDisplay,
          updateLastCoachedDisplay,
          updateSiteInfoDisplay,
          removeScrape,
          isLoading,
          setIsLoading
        }}>
        <div className="flex flex-col gap-1 lg:flex-row justify-between items-start">
          {filters.map((filter, i) => (
            <filter.Component Context={SiteContext} className={filter.className} />
          ))}
        </div>
        {filters.length != 0 &&
          <button
            onClick={clearFilters}
            className='text-sm text-gray-400 mb-1'
          >
            Clear filters
          </button>
        }
        <Table columns={columns} Context={SiteContext} data={sites} LeadingIcon={leadingIcon} TrailingIcon={trailingIcon} />
        <SitePagination Context={SiteContext} />
      </SiteContext.Provider>
    </div>
  )

}