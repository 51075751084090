import classNames from "classnames"
import { useContext } from "react"
import ContentShimmer from "react-content-shimmer"
import { isAM, isAdmin, isDirectorOfAccountsManagement } from "../../users"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid"

export default function AddonSetup({ data, className, Context }) {

  let { isLoading, setupAddon, checkboxLoading } = useContext(Context)

  return (
    <>
      <div className={className}>
        {!isLoading ?
          <>
            <span className="lg:hidden">Setup: </span>
            {isAM() || isAdmin() || isDirectorOfAccountsManagement() ?
              <input
                id={data.id}
                name={data.id}
                type="checkbox"
                onClick={(e) => setupAddon(e, data)}
                defaultChecked={data.setup}
                disabled={checkboxLoading}
                className={classNames("h-4 w-4 rounded border-gray-300 text-tukios-navy focus:ring-tukios-navy", checkboxLoading ? 'opacity-75' : '')}
              /> : data.setup ? <CheckCircleIcon data-tip="Live" className='h-5 w-5 text-green-500 mr-1' /> : <XCircleIcon className='h-5 w-5 text-red-500 mr-1' />
            }
          </> :
          <ContentShimmer style={{ height: '14px', width: '14px', borderRadius: '7px' }} />
        }
      </div>
    </>
  )
}