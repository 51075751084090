import axiosConfig from '../axiosConfig';

const fakeObitsRemoved = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/validate/countFakeObits`)
    .then((res) => {
      resolve(res.data.count === 0 ? true : res.data.count);
    })
    .catch((err) => reject(err));  
  })
}

const realObitsAdded = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/validate/countRealObits`)
    .then((res) => {
      resolve(res.data.count >= 12 ? true : res.data.count);
    })
    .catch((err) => reject(err));  
  })
}

const validCollectionURLs = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/validate/collectionURLs`)
    .then((res) => {
      resolve(res);
    })
    .catch((err) => reject(err));  
  })
}

const validFloralURLs = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/validate/floralURLs`)
    .then((res) => {
      resolve(res);
    })
    .catch((err) => reject(err));  
  })
}

const nonZeroEmailSubscribers = (fhid) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/validate/emailSubscribers`)
    .then((res) => {
      resolve(res);
    })
    .catch((err) => reject(err));  
  })
}

export {
  fakeObitsRemoved,
  realObitsAdded,
  validCollectionURLs,
  validFloralURLs
}