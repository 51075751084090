import axiosConfig from '../axiosConfig';
import { toast } from "react-toastify";

const deleteSite = (fhid, cb) => {

  toast.promise(
    axiosConfig.delete(`/api/sites/${fhid}`),
    {
      pending: 'Deleting site...',
      success: 'Site deleted!',
      error: 'Unable to delete site'
    }
  ).then((res) => {
    cb();
  })
  .catch((err) => {
    cb(false);
  });
}

export default deleteSite;