import axiosConfig from '../axiosConfig';

const deleteIssue = (issueId) => {

  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/siteRevisions/delete`, {
      issueId: issueId
    })
    .then((res) => resolve())
    .catch((err) => reject(err));
  })

}

export default deleteIssue;