import ContentShimmer from "react-content-shimmer"

const ChecklistCategoryLoader = () => {

  return (
    <div className="flex flex-col gap-2 mb-2 pl-5">
      <ContentShimmer
        style={{
          width: "150px",
          height: "18px",
          borderRadius: "9999px",
          marginBottom: "10px",
          marginTop: "10px"
        }}
      />
      <div className="relative flex-col items-start">
        {[...Array(5).keys()].map(() => (
          <div className="flex">
            <div className="flex items-center">
              <ContentShimmer
                style={{
                  width: "18px",
                  height: "18px",
                  borderRadius: "5px",
                  marginBottom: "10px"
                }}
              />
            </div>
            <div className={"ml-3 text-sm"}>
              <ContentShimmer
                style={{
                  width: "300px",
                  height: "18px",
                  borderRadius: "5px",
                  marginBottom: "10px"
                }}
              />
            </div>
          </div>
        ))
        }
      </div>
    </div>
  )
}

export default ChecklistCategoryLoader
