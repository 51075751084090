import { UserAddIcon } from "@heroicons/react/solid"
import classNames from "classnames"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import DropdownWithAvatars from "../../../components/ui/DropdownWithAvatars"
import assignCares from "../../assignCares"
import logActivity from "../../logActivity"
import { getUserObject, isAdmin, isQA } from "../../users"
import ContentShimmer from "react-content-shimmer"
import { siteStatuses } from "../../utilityLibrary"

export default function SiteAssignCare({ data, className, Context }) {

  let { sites, setSites, cares, assignLoading, setAssignLoading, isLoading } = useContext(Context)

  const setSiteQA = (fhid, e) => {

    setAssignLoading(true)

    let careId = e.user_id == 'none' ? null : e.user_id

    assignCares(fhid, careId).then((res) => {
      logActivity({
        message: `${e.name} assigned for ${[siteStatuses.preStagingQa, siteStatuses.finalQa].includes(data.launcher_label) ? 'QA' : 'SEO'} on this site`,
        fhId: fhid,
        logType: 'CARES'
      })

      let currentSites = [...sites]
      let currentSiteIndex = currentSites.map(s => s.id).indexOf(fhid)
      let currentSite = { ...currentSites[currentSiteIndex] }

      currentSite.care_id = e.id == 'none' ? null : e.id
      currentSite.care_name = e.id == 'none' ? null : e.name
      currentSite.care_image = e.id == 'none' ? null : e.image

      currentSites[currentSiteIndex] = currentSite

      setSites(currentSites)

      toast.success(e.name == 'Not assigned' ? 'Unassigned' : `${e.name} assigned to site!`)
      if (res !== true) toast.warn(res)
      setAssignLoading(false)

    })
      .catch((err) => {
        toast.error('Error updating QA')
        console.log(err)
        setAssignLoading(false)
      })
  }

  return (
    <div className={classNames("text-sm text-gray-900 flex gap-2", className)}>
      {(!isLoading && !assignLoading) ?
        <>
          {isQA() ?
            <button
              onClick={() => setSiteQA(data.id, getUserObject())}
              type="button"
              className={classNames("w-8 h-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-tukios-green-dark focus:outline-none", isQA() ? 'hover:bg-tukios-green' : '')}
            >
              <UserAddIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            : <></>}
          <div
            className='w-full'
          >
            {
              cares !== undefined &&
              <DropdownWithAvatars
                people={cares}
                current={data.care_id ? {
                  name: data.care_name,
                  image: data.care_image
                } : null}
                handleSelection={(e) => setSiteQA(data.id, e)}
                disabled={isAdmin() ? false : !isQA()}
              />
            }
          </div>
        </> : <ContentShimmer style={{ height: '34px', width: '200px', borderRadius: '7px' }} />
      }
    </div>
  )
}