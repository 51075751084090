import classNames from "classnames";


export default function TableHeader({ columns, Context, className, LeadingIcon = false, TrailingIcon = false }) {

  return (
    <div className="bg-gray-50 hidden lg:flex border px-4">
      <div className="min-w-0 flex-1 flex items-center">
        {LeadingIcon && <LeadingIcon Context={Context} className="invisible" />}
        <div className="min-w-0 flex-1 lg:flex lg:gap-4">
          {columns.map((column, i) => (
            <div key={i} className={classNames('px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-center flex justify-start', column.className, className)} >
              {column.label}
            </div>
          ))}
        </div>
        {TrailingIcon && <TrailingIcon Context={Context} className="invisible" />}
      </div>
    </div>
  )
} 