import { Disclosure } from '@headlessui/react'
import { NavLink, useLocation } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SidebarDropdown = ({ item }) => {

  const location = useLocation();

  return (
    <Disclosure as="div" className="space-y-1">
    {({ open }) => (
      <>
        <Disclosure.Button
          className='text-white hover:bg-tukios-light group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none'
        >
          <item.icon
            className="mr-3 flex-shrink-0 h-6 w-6"
            aria-hidden="true"
          />
          <span className="flex-1">{item.name}</span>
          <svg
            className={classNames(
              open ? 'rotate-90' : '',
              'ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150'
            )}
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
          </svg>
        </Disclosure.Button>
        <Disclosure.Panel className='space-y-1'>
          {
            item.subPages.map((child) => (
              <NavLink
                // as={NavLink}
                to={child.path ? child.path : child.href}
                key={child.name}
                className={classNames(
                  location.pathname == child.path ? 'bg-tukios-dark text-white' : 'text-white hover:bg-tukios-light',
                  'group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md hover:bg-tukios-light'
                )}
              >
                {child.name}
              </NavLink>
            ))
          }
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>

  )
}

export default SidebarDropdown;



// <Disclosure.Panel className="space-y-1">
// <Disclosure.Button
//     as="a"
//     href='#'
//     className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
//   >
//     Test Child
//   </Disclosure.Button>
// </Disclosure.Panel>
