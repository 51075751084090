import ContentShimmer from 'react-content-shimmer'

const TemplateLoader = () => {
  return (
    <li
      className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
    >
      <div className="flex-1 flex flex-col p-8">
        <ContentShimmer style={{ width: '65%', height: '24px', borderRadius: '7px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px' }} />
        <ContentShimmer style={{ width: '100%', height: '350px', borderRadius: '7px'}} />
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px w-0 flex-1 flex">
              <div className="relative w-full flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                <ContentShimmer style={{ width: '100px', height: '20px', borderRadius: '7px' }} />
              </div>
          </div>
          <div className="w-0 flex-1 flex">
            <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <ContentShimmer style={{ width: '100px', height: '20px', borderRadius: '7px' }} />
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default TemplateLoader;