import ContentShimmer from "react-content-shimmer";
import { EyeIcon, UploadIcon } from "@heroicons/react/solid";

const ContentLoader = () => {
  return (
    <div className="text-center bg-white space-y-4 shadow rounded w-full divide-y divide-gray-200">
      <div className="flex-1 flex flex-col pr-4 pl-4 pt-5">
        <h3 className="mb-5 text-gray-900 font-medium text-base">
          <ContentShimmer
            style={{
              width: "30%",
              height: "22px",
              borderRadius: "7px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </h3>
        <ContentShimmer
          style={{ width: "100%", height: "10rem", borderRadius: "7px" }}
        />
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px w-0 flex-1 flex">
            <div className="relative w-full flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <ContentShimmer
                style={{ width: "100px", height: "22px", borderRadius: "7px" }}
              />
            </div>
          </div>
          <div className="w-0 flex-1 flex">
            <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <ContentShimmer
                style={{ width: "100px", height: "22px", borderRadius: "7px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentLoader;
