/* This example requires Tailwind CSS v2.0+ */
import {
  DatabaseIcon,
  ArchiveIcon,
  MailIcon,
  UserGroupIcon
} from '@heroicons/react/outline'

const items = [
  {
    title: 'Obituary Scrape',
    description: 'Request an obituary scrape or import',
    icon: DatabaseIcon,
    background: 'bg-pink-500',
  },
  // {
  //   title: 'Obituary Scrape',
  //   description: 'Request an obituary scrape for staging or go-live',
  //   icon: DatabaseIcon,
  //   background: 'bg-yellow-500',
  // },
  {
    title: 'Fake Obituaries',
    description: 'Add or remove fake obituaries from this site',
    icon: UserGroupIcon,
    background: 'bg-yellow-500',
  },
  {
    title: 'Subscriber Import',
    description: 'Import obituary or GriefSteps email subscribers',
    icon: MailIcon,
    background: 'bg-green-500',
  },
  {
    title: 'Run Site Backup',
    description: 'Archive an existing site prior to going live',
    icon: ArchiveIcon,
    background: 'bg-indigo-500',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Actions = () => {

  return (

      // <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <ul className="grid lg:grid-cols-2 gap-6">
          {items.map((item, itemIdx) => (
            <li key={itemIdx} className="flow-root">
              <div className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50">
                <div
                  className={classNames(
                    item.background,
                    'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
                  )}
                >
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    {/* eslint-disable-next-line */}
                    <a href="#" className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      {item.title}
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      // </div>
  )
}

export default Actions;
