import axiosConfig from '../axiosConfig';
import { toast } from 'react-toastify';
import logActivity from './logActivity';

const updatePages = (fhid, preset) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/genPagesMeta`, {
      preset: preset
    })
    .then((res) => resolve(res))
    .catch((err) => reject(err));
  })
}

const generatePagesMeta = (fhid, cb, preset) => {
  toast.promise(
    updatePages(fhid, preset),
    {
      pending: 'Updating pages',
      success: 'Pages updated successfully',
      error: 'Unable to update Pages'
    }
  ).then(() => {
    cb();
    logActivity({
      message: `Updated page meta`,
      fhId: fhid,
      mute: true
    })
  });
}

export default generatePagesMeta;
