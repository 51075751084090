import React from 'react';
import SidebarPage from '../SidebarPage';

const NotFound = () => {

  return (
    <SidebarPage
      docTitle='Page Not Found | Tukios Website Admin'
    >
      <h1>An error has occurred.</h1>
      <a href="/">Click here to go home.</a>
    </SidebarPage>
  )

}

export default NotFound;
