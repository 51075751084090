import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import logIssue from "../../utils/logIssue";
import markFixed from "../../utils/markFixed";
import { isAdmin, isQA, isDesigner, userId } from "../../utils/users";
import SeverityBadge from "../sites/SeverityBadge";
import QAStatusBadge from "../sites/QAStatusBadge";
import RevisionsLoader from "./RevisionsLoader";
import RevisionType from "./RevisionType";
import RevisionSeverity from "./RevisionSeverity";
import { siteStatuses } from "../../utils/utilityLibrary";
import UpdateRevisionDialog from "../pages/UpdateRevisionDialog";
import { ModalContext } from "../../hooks/ModalContext";
import listSiteRevisions from "../../utils/listSiteRevisions";
import markVerified from "../../utils/markVerified";
import ReactTooltip from "react-tooltip";

const SiteRevisions = ({ site }) => {

  let { handleModal } = useContext(ModalContext);

  let [siteRevisions, setsiteRevisions] = useState([])
  let [isLoading, setIsLoading] = useState(true)
  let [newSeverity, setNewSeverity] = useState('Low')
  let [saving, setSaving] = useState(false)
  let textBoxRef = useRef();

  const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none';

  const handleSiteSeverityChange = (e) => {
    setNewSeverity(e.target.id)
  }

  const handleSiteStatusChange = (e) => {
    setNewStatus(e.target.id)
  }

  const updateQaIssue = (issue) => {
    handleModal(
      <UpdateRevisionDialog issue={issue} />
    )
  }

  let [newStatus, setNewStatus] = useState(isQA() || isAdmin() ? ((site.launcher_label == siteStatuses.preStagingQa || site.launcher_label == siteStatuses.finalQa) ? site.launcher_label : 'Post-Live QA') : 'Client Request')

  const confirmFixed = (issueId, e) => {
    let fixed = e.target.checked

    for (let i = 0; i < siteRevisions.length; i++) {
      if (siteRevisions[i].id == issueId) {
        siteRevisions[i].fixed = fixed
        siteRevisions[i].verified = isQA() && fixed ? true : false
      }
    }
    setsiteRevisions([...siteRevisions]);

    markFixed(issueId, fixed, isQA() && fixed ? true : false, site.id)
      .catch(err => {
        console.log(err)
      })
  }

  const confirmVerified = (issueId, e) => {
    let verified = e.target.checked

    for (let i = 0; i < siteRevisions.length; i++) {
      if (siteRevisions[i].id == issueId) {
        siteRevisions[i].verified = verified
      }
    }
    setsiteRevisions([...siteRevisions]);

    markVerified(issueId, verified, site.id)
      .catch(err => {
        console.log(err)
      })
  }

  const submitIssue = () => {
    if (saving || textBoxRef.current.value == '') return
    setSaving(true)
    logIssue(site.id, textBoxRef.current.value, newSeverity, newStatus, isQA() ? 0 : 1).then((res) => {
      textBoxRef.current.value = ''
      refreshIssues()
      setSaving(false)
    })
  }

  const refreshIssues = () => {
    setIsLoading(true)
    listSiteRevisions(site.id).then((issues) => {
      setsiteRevisions(issues)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    refreshIssues()
  }, [])

  return (
    <>
      <div>
        <textarea
          name="newIssue"
          ref={textBoxRef}
          type="text"
          placeholder="Incorrect email address linked in the footer."
          className={classNames(inputClasses, 'h-36')}
        />
        <div className="pt-3">
          <div className="mb-6 flex items-center">
            <div className="flex-col w-fit ml-auto divide-y divide-gray-200">
              <RevisionSeverity handleSiteSeverityChange={handleSiteSeverityChange} defaultSeverity={newSeverity} className={"ml-auto pb-2"} />
              {(isQA() || isAdmin()) &&
                <RevisionType handleSiteStatusChange={handleSiteStatusChange} defaultStatus={newStatus} className={"ml-auto pt-2"} />
              }
            </div>
            <button
              type="button"
              className={classNames(saving ? 'opacity-50' : '', "block w-fit items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none")}
              onClick={() => submitIssue()}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ul role="list" className="divide-y divide-gray-200">
        {
          (siteRevisions.length == 0 && !isLoading) && <div className="mb-4">No revisions logged</div>
        }
        {
          !isLoading ? (
            siteRevisions.map((issue) => (
              <li
                key={issue.id}
                className={classNames("relative py-5 px-4 focus-within:ring-inset focus-within:ring-0 hover:bg-gray-50 bg-white")}
              >
                <div className="flex gap-3">
                  <ReactTooltip />
                  <div className="flex-col flex gap-1 align-center min-w-max w-28 shrink-0">
                    <img className="max-w-none h-14 w-14 rounded-full ring-2 ring-white mx-auto" src={issue.user_image} />
                    <QAStatusBadge status={issue.status} />
                    <SeverityBadge severity={issue.severity} />
                  </div>
                  <div className="flex-col justify-between">
                    <div className="min-w-0 flex-1 flex flex-col">
                      <p className="truncate text-sm font-medium text-gray-900">{issue.user_name}</p>
                    </div>
                    <p className="text-sm text-gray-600 line-clamp-2 shrink-1" style={{ whiteSpace: 'pre-line' }}>{issue.issue.replace(/\\n/g, '\n')}</p>
                  </div>
                  <div className="ml-auto mt-auto mb-auto flex-col gap-1 w-1/6">
                    {(isDesigner() || isQA() || isAdmin()) ?
                      <>
                        <div className="flex h-5 justify-end">
                          <span className="mr-2 text-sm">Fixed:</span>
                          <input
                            id={issue.id}
                            className="h-4 w-4 mt-auto mb-auto rounded border-gray-300 text-tukios-navy focus:ring-tukios-navy"
                            type="checkbox"
                            checked={issue.fixed}
                            onClick={(e) => confirmFixed(issue.id, e)}
                          />
                        </div>
                      </>
                      : (issue.fixed ? issue.verified ? <CheckCircleIcon data-tip="Completed" className="text-green-500 ml-auto w-7 h-7 mt-auto mb-auto" /> : <ExclamationCircleIcon data-tip="Waiting for verification" className="text-blue-500 ml-auto w-7 h-7 mt-auto mb-auto" /> : <ExclamationCircleIcon data-tip="Waiting for designer" className="text-red-500 ml-auto w-7 h-7 mt-auto mb-auto" />)}
                    {(isQA() || isAdmin() && issue.fixed == 1) &&
                      <div className="flex h-5 justify-end items-center mt-1">
                        <span className="mr-2 text-sm">Verified:</span>
                        <input
                          id={issue.id}
                          className="h-4 w-4 mt-auto mb-auto rounded border-gray-300 text-tukios-navy focus:ring-tukios-navy"
                          type="checkbox"
                          checked={issue.verified}
                          onClick={(e) => confirmVerified(issue.id, e)}
                        />
                      </div>
                    }
                    {(issue.user_id == userId()) &&
                      <button
                        className="block mt-2 ml-auto items-center justify-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
                        onClick={() => updateQaIssue(issue)}
                      >Edit</button>
                    }
                  </div>
                </div>
              </li>
            ))
          ) : (<RevisionsLoader />)}
      </ul>
    </>
  )
}

export default SiteRevisions;
