import { SearchIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useContext } from "react";

export default function SiteSearch({ Context, className }) {

  let { handleSearch, searchRef } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <div className="flex rounded-md mb-2 lg:mb-0 border border-gray-300 shadow-sm">
        <span className="inline-flex shadow-none pointer items-center px-3 rounded-l-lg border border-t-0 border-l-0 border-b-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          <SearchIcon className='w-4 h-4' />
        </span>
        <input
          type="text"
          placeholder="Search..."
          ref={searchRef}
          onChange={handleSearch}
          className='appearance-none border-none rounded-r-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
        />
      </div>
    </div>
  )
}