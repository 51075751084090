import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import moment from "moment";
import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";

export default function SiteLastCoachingSession({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  const coachingSessionNeeded = (lastCoachingSession) => {
    let today = moment().format('YYYYMMDD')
    let coachingDueDateObj = moment(lastCoachingSession, 'MMM D, YYYY').add(3, 'months').format('YYYYMMDD')

    return today.valueOf() > coachingDueDateObj.valueOf()
  }

  const coachingSessionComingUp = (lastCoachingSession) => {
    let today = moment().format('YYYYMMDD')
    let coachingDueDateObj = moment(lastCoachingSession, 'MMM D, YYYY').add(2, 'months').format('YYYYMMDD')

    return today.valueOf() > coachingDueDateObj.valueOf()
  }


  return (
    <>
      <div className={classNames(className)}>
        {!isLoading ?
          <>
            {
              data.pretty_last_coaching_session ?
                <div className="flex">
                  {
                    coachingSessionNeeded(data.pretty_last_coaching_session) ?
                      <XCircleIcon data-tip="Coaching session due" className='h-5 w-5 text-red-500 mr-1' />
                      :
                      <>
                        {coachingSessionComingUp(data.pretty_last_coaching_session) ?
                          <ExclamationCircleIcon data-tip="Coaching session coming up" className="h-5 w-5 text-yellow-300 mr-1" /> :
                          <CheckCircleIcon data-tip="Coached" className='h-5 w-5 text-green-500 mr-1' />
                        }
                      </>
                  }
                  {data.pretty_last_coaching_session}
                </div>
                :
                <>
                  Unknown
                </>
            }
          </> : <ContentShimmer style={{ height: '14px', width: '100px', borderRadius: '7px' }} />}
      </div>
    </>
  )
}