import { Dialog } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { useContext, useState } from 'react'
import { ModalContext } from '../../../../hooks/ModalContext'
import classNames from 'classnames'
import { unscheduleLaunchDate } from '../../../../utils/updateLaunchDate'
import { toast } from 'react-toastify'
import { rescheduleReasons } from '../../../../utils/utilityLibrary'

const UnScheduleLaunchDialog = ({ site, updateLaunchDateDisplay }) => {

  const { handleModal } = useContext(ModalContext)
  const [actionPending, setActionPending] = useState(false)
  const [reason, setReason] = useState("")
  const [error, setError] = useState("")
  const [note, setNote] = useState("")

  const inputClasses = 'shadow appearance-none border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'


  const unscheduleLaunch = () => {
    if (!actionPending) {
      setError('')
      setActionPending(true)
      unscheduleLaunchToast()
    }
  }

  const unscheduleLaunchToast = () => {
    if (!reason) {
      setError('Please select a reason')
      setActionPending(false)
    }
    else {
      toast.promise(
        unscheduleLaunchDate(site.id, reason, note, updateLaunchDateDisplay),
        {
          pending: 'Unscheduling launch...',
          success: 'Unscheduled!',
          error: 'Unable to unschedule launch'
        }
      ).then(() => {
        handleModal(false)
        setActionPending(false)
      }).catch(() => {
        handleModal(false)
        setActionPending(false)
      })
    }
  }

  return (
    <>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => handleModal(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title as="h3" className="text-lg mt-auto mb-auto leading-6 font-medium text-gray-900">
            {site.id} - {site.name}
          </Dialog.Title>
        </div>
      </div>
      <div className="py-4 space-y-6 sm:space-y-5">
        <div>
          <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
            Reason for unscheduling
          </label>
          <select
            name="reason"
            className={inputClasses}
            initialValues="chooseReason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            <option value="" disabled>Select one</option>
            {
              Object.values(rescheduleReasons).map(reason => (
                <option value={reason}>{reason}</option>
              ))
            }
          </select>
          {error &&
            <div className="text-red-500 font-medium mt-1 text-sm">{error}</div>
          }
        </div>
        <div className="mt-6">
          <label htmlFor="note" className="block text-sm font-medium text-gray-700">
            Note
          </label>
          <div className="mt-1">
            <textarea
              name="note"
              type="text"
              placeholder="Anything else the team should know?"
              onChange={(e) => setNote(e.target.value)}
              className={classNames(inputClasses, 'h-40')}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className={classNames(actionPending ? 'opacity-90' : '', "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm")}
          onClick={unscheduleLaunch}
        >
          Unschedule
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => handleModal(false)}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default UnScheduleLaunchDialog