
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useContext, useState } from 'react'
import { ModalContext } from '../../hooks/ModalContext'
import ScrapeRequestForm from './ScrapeRequestForm'
import { useScrapeRequestForm } from '../../hooks/ScrapeRequestFormContext'
import classNames from 'classnames'
import { toast } from 'react-toastify'

const ScrapeRequestDialog = ({ site }) => {

  let { handleModal } = useContext(ModalContext)

  let { submitScrapeRequest, checkForScrapeRequestErrors } = useScrapeRequestForm()
  let [isSubmitting, setIsSubmitting] = useState(false)
  let [error, setError] = useState()

  const handleSubmit = () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    setError('')
    let scrapeRequestErrors = checkForScrapeRequestErrors()
    if (scrapeRequestErrors) {
      setError(scrapeRequestErrors)
      setIsSubmitting(false)
    }
    else {
      submitScrapeRequest(site.id).then(() => {
        toast.success('Scrape request submitted successfully')
        setIsSubmitting(false)
        handleModal(false)
      }).catch(err => {
        console.log(err)
        toast.error('Error while submitting scrape request')
        setIsSubmitting(false)
        handleModal(false)
      })
    }
  }

  return (
    <>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
          onClick={() => handleModal(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start w-full">
        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Scrape Request - {site.name}
          </Dialog.Title>
        </div>
      </div>
      <ScrapeRequestForm inModal={true} />
      {error &&
        <div className="text-red-500 font-medium mt-1 text-sm text-center">{error}</div>
      }
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={(e) => { handleSubmit() }}
          className={classNames("w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm", isSubmitting ? 'opacity-75' : 'hover:bg-green-700')}
        >
          Submit
        </button>
        <button
          type="button"
          onClick={() => handleModal(false)}
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>

    </>
  )
}

export default ScrapeRequestDialog