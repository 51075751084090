import classNames from "classnames";
import StatusBadge from "../../../components/sites/StatusBadge";
import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";

export default function SiteStatus({ data, className, Context }) {

  let { isLoading } = useContext(Context)

  return (
    <div className={classNames("whitespace-nowrap text-sm", className)}>
      {!isLoading ?
        <StatusBadge siteStatus={data.launcher_label} latestActivity={data.latest_activity} />
        :
        <ContentShimmer style={{ height: '20px', width: '100px', borderRadius: '7px' }} />
      }
    </div>
  )
}