import { TerminalIcon } from "@heroicons/react/solid"
import { useContext, useEffect, useState } from "react"
import { scrapeJobTypes, providers, workingScrapeProviders } from "../../utils/utilityLibrary"
import startScrape from "../../utils/startScrape"
import classNames from 'classnames'
import { toast } from 'react-toastify'
import DangerDialog from "../ui/modal/dialogs/Danger"
import { ModalContext } from "../../hooks/ModalContext"
import { isAdmin } from "../../utils/users"
import ScrapeRequestForm from "../pages/ScrapeRequestForm"
import SiteFilter from "../sites/SiteFilter"
import { useScrapeRequestForm } from "../../hooks/ScrapeRequestFormContext"

const SiteScrape = ({ site }) => {

  let { handleModal } = useContext(ModalContext)
  let { submitScrapeRequest, formValues, checkForScrapeRequestErrors } = useScrapeRequestForm()

  let [url, setUrl] = useState(site?.url ? site.url.replace('https://www.', '').replace('/', '') : '')
  let [provider, setProvider] = useState(site?.provider ? site.provider : '')
  let [organizationId, setOrganizationId] = useState('')
  let [startPage, setStartPage] = useState(0)
  let [endPage, setEndPage] = useState('')
  let [jobType, setJobType] = useState(scrapeJobTypes.obituaries)
  let [saving, setSaving] = useState(false)
  let [error, setError] = useState('')

  const scrapeForms = [
    { name: 'Scrape Request', color: 'bg-green-400' },
  ]

  if (isAdmin()) scrapeForms.unshift({ name: 'Scrape Job', color: 'bg-purple-400' })

  let [selectedForm, setSelectedForm] = useState(scrapeForms[0])

  useEffect(() => {
    setUrl(site?.url ? site.url.replaceAll('www.', '').replaceAll('/', '').replaceAll(':', '').replaceAll('https', '').replaceAll('http', '') : '')
    setProvider(workingScrapeProviders.includes(site?.provider) ? site.provider : '')
    setOrganizationId(site?.organization_id)
  }, [site])

  const handleChange = (e, set) => {
    set(e.target.value)
  }

  const startScrapeConfirmation = (organizationId, siteId, url, jobType, provider, startPage, endPage) => {
    handleModal(<DangerDialog
      title={`Scrape Job Confirmation`}
      actionText='Start Job'
      messageText={`Are you sure you want to scrape ${jobType.toLowerCase()} from ${url} into organization ID: ${organizationId}.`}
      actionFn={() => startScrapeToast(organizationId, siteId, url, jobType, provider, startPage, endPage)}
    />)
    setSaving(false)
    setError('')
  }

  const startScrapeToast = (organizationId, siteId, url, jobType, provider, startPage, endPage) => {
    toast.promise(
      startScrape(organizationId, siteId, url, jobType, provider, startPage, endPage),
      {
        pending: 'Starting job...',
        success: 'Job started!',
        error: 'Unable to start job'
      }
    ).then(() => {
      handleModal(false)
    }).catch(() => {
      handleModal(false)
    })
    setSaving(false)
  }

  const startJob = () => {
    if (saving) return
    setSaving(true)
    if (!organizationId || (startPage < 0) || !endPage && provider == providers.batesville || !url) {
      let errors = []
      setError('')
      if (!provider) {
        errors.push('\'Provider\'')
      }
      if (!url) {
        errors.push('\'URL\'')
      }
      if (!organizationId) {
        errors.push('\'Organization ID\'')
      }
      if (startPage < 0) {
        errors.push('\'Start Page\'')
      }
      if (!endPage && provider == providers.batesville) {
        errors.push('\'End Page\'')
      }
      setError('Missing ' + errors.join(', ').replace(/, ([^,]*)$/, ' and $1'))
      setSaving(false)
    }
    else {
      startScrapeConfirmation(organizationId, site.id, url, jobType, provider, startPage, endPage)
    }
  }

  const startScrapeRequestConfirmation = (siteId) => {
    handleModal(<DangerDialog
      title={`Scrape Request Confirmation`}
      actionText='Submit Request'
      messageText={`Are you sure you want to request a ${formValues.type.toLowerCase()} scrape for ${site.name}?`}
      actionFn={() => submitScrapeRequestToast(siteId)}
    />)
    setSaving(false)
    setError('')
  }

  const submitScrapeRequestToast = (siteId) => {
    toast.promise(
      submitScrapeRequest(siteId),
      {
        pending: 'Requesting scrape...',
        success: 'Scrape requested!',
        error: 'Unable to request scrape'
      }
    ).then(() => {
      handleModal(false)
    }).catch(() => {
      handleModal(false)
    })
    setSaving(false)
  }

  const submitRequest = () => {
    if (saving) return
    setSaving(true)
    setError('')
    let scrapeRequestErrors = checkForScrapeRequestErrors()
    if (scrapeRequestErrors) {
      setError(scrapeRequestErrors)
      setSaving(false)
    }
    else {
      startScrapeRequestConfirmation(site.id)
    }
  }

  return (
    <>
      {isAdmin() &&
        <div className="w-48 ml-auto mb-1">
          <SiteFilter
            filters={scrapeForms}
            selected={selectedForm}
            setSelected={(e) => {
              setError('')
              setSelectedForm(e)
            }}
          />
        </div>
      }
      {selectedForm.name === 'Scrape Job' &&
        <>
          <div className='text-lg font-medium flex items-center mb-5'>
            <TerminalIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Scrape Job</div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                URL* <span className="text-gray-400 text-xs"> - Do not include `http` or `www`</span>
              </label>
              <div className="mt-1 flex shadow rounded-md">
                <span className="inline-flex shadow-none items-center px-3 rounded-l-md border border-r-0 bg-gray-50 text-gray-500 sm:text-sm">
                  www.
                </span>
                <input
                  name="url"
                  type="text"
                  placeholder="sonzinimortuary.com"
                  className="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none rounded-l-none"
                  onChange={(e) => handleChange(e, setUrl)}
                  value={url}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="provider" className="block text-sm font-medium text-gray-700">
                Provider*
              </label>
              <select
                name="provider"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                initialValues="chooseProvider"
                value={provider}
                onChange={(e) => handleChange(e, setProvider)}
              >
                <option value="" disabled>Select one</option>
                {
                  workingScrapeProviders.map(provider => (
                    <option value={provider}>{provider}</option>
                  ))
                }
              </select>
            </div>
            <div class="sm:col-span-3">
              <label className="block text-sm font-medium text-gray-700">
                Job Type*
              </label>
              <select
                name="jobType"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                initialValues="chooseProvider"
                onChange={(e) => handleChange(e, setJobType)}
                value={jobType}
              >
                <option value="" disabled>Select one</option>
                {
                  Object.values(scrapeJobTypes).map(jobType => (
                    <option value={jobType}>{jobType}</option>
                  ))
                }
              </select>
            </div>
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Organization ID*
              </label>
              <div className="mt-1">
                <input
                  name="organizationId"
                  type="number"
                  className="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  placeholder="123"
                  onChange={(e) => handleChange(e, setOrganizationId)}
                  value={organizationId}
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                className="block text-sm font-medium text-gray-700"
              >
                Start Page*
              </label>
              <div class="mt-1">
                <input
                  name="startPage"
                  type="number"
                  placeholder="1"
                  className="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  onChange={(e) => handleChange(e, setStartPage)}
                  value={startPage}
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                className="block text-sm font-medium text-gray-700"
              >
                End Page {provider == providers.batesville && <span className="text-gray-400 text-xs">*</span>}
              </label>
              <div class="mt-1">
                <input
                  name="endPage"
                  type="text"
                  placeholder="125"
                  class="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  onChange={(e) => handleChange(e, setEndPage)}
                  value={endPage}
                />
              </div>
            </div>
          </div>
          <div>
            <dd className="py-4 sm:gap-4">
              <button
                type="button"
                onClick={() => startJob()}
                disabled={saving}
                className={classNames(saving ? 'opacity-80' : '', "block ml-auto mr-auto items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none")}
              >
                Start Job
              </button>
            </dd>
            {error &&
              <div className="text-red-500 font-medium mt-1 text-sm text-center">{error}</div>
            }
          </div>
        </>
      }
      {selectedForm.name == 'Scrape Request' &&
        <>
          <div className='text-lg font-medium flex items-center mb-5'>
            <TerminalIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Scrape Request</div>
          </div>
          <ScrapeRequestForm />
          <div>
            <dd className="py-4 sm:gap-4">
              <button
                type="button"
                onClick={() => submitRequest()}
                disabled={saving}
                className={classNames(saving ? 'opacity-80' : '', "block ml-auto mr-auto items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none")}
              >
                Submit Request
              </button>
            </dd>
            {error &&
              <div className="text-red-500 font-medium mt-1 text-sm text-center">{error}</div>
            }
          </div>
        </>
      }
    </>
  )
}

export default SiteScrape
