import React, { useState, useEffect } from 'react';
import { MenuAlt2Icon } from '@heroicons/react/outline'
import UserDropdown from './UserDropdown';
import DesktopSidebar from './DesktopSidebar';
import MobileSidebar from './MobileSidebar';
import { useHistory } from 'react-router-dom';

const AppWrapper = ({ children }) => {

  let [sidebarOpen, setSidebarOpen] = useState(false);

  let history = useHistory()

  const userName = localStorage.getItem('name');

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  }

  return (
    <React.Fragment>
      <div className="h-screen flex overflow-hidden bg-gray-100">

        <MobileSidebar
          toggleSidebar={toggleSidebar}
          sidebarOpen={sidebarOpen}
        />

        <DesktopSidebar />

        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow px-4 sm:px-6 md:px-8">
            {/* { history.location.pathname.includes('sites/id') ? (
              <button className="whitespace-nowrap text-sm font-semibold text-tukios-navy hover:text-gray-600" onClick={() => history.goBack()}>← Go Back</button>
              ) :
              (
                <div className='flex gap-10 items-center w-full whitespace-nowrap text-sm font-semibold text-tukios-navy'>
                  <div className="flex items-center"><div className='bg-yellow-300 mr-2 rounded-full w-2 h-2'/>Staged 55</div>
                  <div className="flex items-center"><div className='bg-blue-500 mr-2 rounded-full w-2 h-2'/>In Review: 55</div>
                  <div className="flex items-center"><div className='bg-green-400 mr-2 rounded-full w-2 h-2'/>Live: 55</div>
                  <div className="flex items-center"><div className='bg-purple-500 mr-2 rounded-full w-2 h-2'/>Rooftops: 55</div>
                </div>
              )
            } */}
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => toggleSidebar(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-end">
              <div className="ml-4 flex items-center md:ml-6">
                <span>{userName}</span>
                <UserDropdown />
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AppWrapper;
