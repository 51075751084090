import TemplateList from "../launch/TemplateList"
import formatTemplateName from "../../utils/formatTemplateName"

const SelectTemplate = ({ templateSelected }) => {

  const selectTemplate = (e) => {
    templateSelected(e);
  }

  return (
    <TemplateList
      setTemplate={selectTemplate}
    />
  )

}

export default SelectTemplate;