import {createContext, useContext, useEffect, useState} from 'react'
import ContentShimmer from 'react-content-shimmer'
import {toast} from 'react-toastify'
import {ModalContext} from '../../hooks/ModalContext'
import updateContentLibrary from '../../utils/updateContentLibrary'
import {isAdmin, isAE, isLaunchSpecialist} from '../../utils/users'
import {providers, fundingPartners, setSiteCorporation, setSiteProvider, setSiteReferralPartner, statusesReadyToScheduleLaunchDate, setSiteFundingPartner} from '../../utils/utilityLibrary'
import ScheduleLaunchDialog from '../pages/ScheduleLaunchDialog'
import Table from '../../utils/table/Table'
import AddonName from '../../utils/addon table/columns/AddonName'
import AddonCommitted from '../../utils/addon table/columns/AddonCommitted'
import AddonSetup from '../../utils/addon table/columns/AddonSetup'
import {updateAddonCommitted, updateAddonSetup} from '../../utils/siteAddons'
import {useSiteFilters} from '../../hooks/SiteFiltersContext'
import PreviousVideoProviderConfirmation from '../pages/PreviousVideoProviderConfirmation'

const LineShimmer = () => {
  return (
    <ContentShimmer
      rounded='7px'
      style={{
        width: '80%',
        height: '16px'
      }}
    />
  )
}

const AddonContext = createContext()

const DetailCard = ({site, isLoading}) => {

  let {handleModal} = useContext(ModalContext)
  let [provider, setProvider] = useState("")
  let [fundingPartner, setFundingPartner] = useState("")
  let [corporation, setCorporation] = useState("")
  let [referralPartner, setReferralPartner] = useState("")
  let [checkboxLoading, setCheckboxLoading] = useState(false)

  let {corporations, referralPartners} = useSiteFilters()

  useEffect(() => {
    setProvider(site.provider ?? "")
    setFundingPartner(site.funding_partner ?? "")
    setCorporation(site.corporation_id ?? "")
    setReferralPartner(site.referral_partner_id ?? "")
  }, [isLoading])

  const scheduleLaunch = (site) => {
    handleModal(
      <ScheduleLaunchDialog site={site} />
    )
  }

  const previousVideoProvider = (addon, e, setup, commit) => {
    handleModal(
      <PreviousVideoProviderConfirmation addon={addon} e={e} setup={setup} commit={commit} setupAddon={setupAddon} commitAddon={commitAddon}/>
    )
  }

  const columns = [
    {
      label: 'Name',
      Component: AddonName,
      className: 'lg:w-1/2'
    },
    {
      label: 'Committed',
      Component: AddonCommitted,
      className: 'lg:w-1/4 lg:flex lg:items-center lg:justify-center'
    },
    {
      label: 'Setup',
      Component: AddonSetup,
      className: 'lg:w-1/4 lg:flex lg:items-center lg:justify-center'
    },
  ]

  const setupAddon = (e, addon, fromModal=false, previousCustomer=false) => {
    if (checkboxLoading) return
    if (e.target.checked === true && addon.id === 1 && fromModal === false) {
      e.target.checked = false
      previousVideoProvider(addon, e, true, false)
    }
    else {
      setCheckboxLoading(true)
      updateAddonSetup(site.id, addon.id, e.target.checked, previousCustomer)
        .then(() => {
          toast.success(`Setup ${e.target.checked ? 'checked' : 'unchecked'}`)
          setCheckboxLoading(false)
        })
        .catch(err => {
          toast.error('Unable to update setup')
          setCheckboxLoading(false)
        })
    }
  }

  const commitAddon = (e, addon, fromModal=false, previousCustomer=false) => {
    if (checkboxLoading) return
    if (e.target.checked === true && addon.id === 1 && fromModal === false) {
      e.target.checked = false
      previousVideoProvider(addon, e, false, true)
    }
    else {
      setCheckboxLoading(true)
      updateAddonCommitted(site.id, addon.id, e.target.checked, previousCustomer)
        .then(() => {
          toast.success(`Committed ${e.target.checked ? 'checked' : 'unchecked'}`)
          setCheckboxLoading(false)
        })
        .catch(err => {
          toast.error('Unable to update committed')
          setCheckboxLoading(false)
        })
    }
  }

  const handleProviderChange = (e) => {
    setProvider(e.target.value)
    setSiteProvider(site.id, e.target.value).then(() => {
      toast.success("Provider updated")
    })
      .catch((err) => {
        toast.error("Error updating site provider")
      })
  }

  const handleFundingPartnerChange = (e) => {
    setFundingPartner(e.target.value)
    setSiteFundingPartner(site.id, e.target.value)
      .then(() => {
      toast.success("Funding partner updated")
    })
      .catch((err) => {
        toast.error("Error updating site provider")
      })
  }

  const handleCorporationChange = (e) => {
    setCorporation(e.target.value)
    setSiteCorporation(site.id, e.target.value).then(() => {
      toast.success("Corporation updated")
    })
      .catch((err) => {
        toast.error("Error updating site corporation")
      })
  }

  const handleReferralPartnerChange = (e) => {
    setReferralPartner(e.target.value)
    setSiteReferralPartner(site.id, e.target.value).then(() => {
      toast.success("Referral partner updated")
    })
      .catch((err) => {
        toast.error("Error updating site referral partner")
      })
  }

  return (
    <dl className="divide-y divide-gray-200">
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Firm name</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {isLoading ? <LineShimmer /> : site.name ? site.name : "-"}
          </span>
        </dd>
      </div>
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500 mt-auto mb-auto">Prev. provider</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {!isLoading ?
              <div className="sm:col-span-2">
                <select
                  name="provider"
                  className="mt-1 block w-1/2 pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                  initialValues="chooseProvider"
                  value={provider}
                  onChange={handleProviderChange}
                >
                  <option value="" disabled>Select one</option>
                  {
                    Object.values(providers).map(provider => (
                      <option value={provider}>{provider}</option>
                    ))
                  }
                </select>
              </div>
              : <LineShimmer />}
          </span>
        </dd>
      </div>
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500 mt-auto mb-auto">Corporation</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {!isLoading ?
              <div className="sm:col-span-2">
                <select
                  name="corporation"
                  className="mt-1 block w-1/2 pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                  initialValues="chooseCorporation"
                  value={corporation}
                  onChange={handleCorporationChange}
                >
                  <option value="" disabled>Select one</option>
                  {
                    corporations.map(corporation => (
                      <option value={corporation.id}>{corporation.name}</option>
                    ))
                  }
                </select>
              </div>
              : <LineShimmer />}
          </span>
        </dd>
      </div>
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500 mt-auto mb-auto">Referral partner</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {!isLoading ?
              <div className="sm:col-span-2">
                <select
                  name="referralPartner"
                  className="mt-1 block w-1/2 pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                  initialValues="chooseCorporation"
                  value={referralPartner}
                  onChange={handleReferralPartnerChange}
                >
                  <option value="" disabled>Select one</option>
                  {
                    referralPartners.map(referralPartner => (
                      <option value={referralPartner.id}>{referralPartner.name}</option>
                    ))
                  }
                </select>
              </div>
              : <LineShimmer />}
          </span>
        </dd>
      </div>
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500 mt-auto mb-auto">Funding partner</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {!isLoading ?
              <div className="sm:col-span-2">
                <select
                  name="fundingPartner"
                  className="mt-1 block w-1/2 pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                  initialValues="chooseProvider"
                  value={fundingPartner}
                  onChange={handleFundingPartnerChange}
                >
                  <option value="" disabled>Select one</option>
                  {
                    Object.values(fundingPartners).map(fundingPartner => (
                      <option value={fundingPartner}>{fundingPartner}</option>
                    ))
                  }
                </select>
              </div>
              : <LineShimmer />}
          </span>
        </dd>
      </div>
      <div className="py-4 items-center sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Domain</dt>
        <dd className="mt-1 items-center flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span>
            {isLoading ? <LineShimmer /> : site.apex ? site.apex : "-"}
          </span>
          {site.hosted_zone_id && <a href={`https://us-east-1.console.aws.amazon.com/route53/v2/hostedzones?region=us-east-1#ListRecordSets/${site.hosted_zone_id}`} className='block ml-auto mr-auto items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none'>View Aws Zone</a>}
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Email address</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {isLoading ? <LineShimmer /> : site.email ? site.email : "-"}
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Phone number</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {isLoading ? <LineShimmer /> : site.phone ? site.phone : "-"}
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Initial location</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {isLoading ? (
              <LineShimmer />
            ) : site.address ? (
              <>
                {site.address}
                <br />
                {site.city} {site.state} {site.zip}
              </>
            ) : (
              "-"
            )}
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Launch date</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            {isLoading ? (
              <LineShimmer />
            ) : site.pretty_scheduled_launch_date ? (
              <>
                {site.pretty_scheduled_launch_date}
              </>
            ) : (
              (isLaunchSpecialist() || statusesReadyToScheduleLaunchDate.includes(site.launcher_label) && (isAE() || isAdmin())) ?
                <button
                  type="button"
                  className="block mr-auto items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
                  onClick={() => scheduleLaunch(site)}
                >
                  Schedule Launch Date
                </button>
                :
                <>
                  -
                </>
            )}
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Add-ons</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <AddonContext.Provider value={{
            isLoading,
            setupAddon,
            commitAddon,
            checkboxLoading,
            setCheckboxLoading
          }}
          >
            <Table data={site.addons} columns={columns} Context={AddonContext} className={"w-full"} />
          </AddonContext.Provider>
        </dd>
      </div>
      <div>
        <dd className="py-4 sm:gap-4">
          <button
            type="button"
            onClick={() => updateContentLibrary(site.id)}
            className="block ml-auto mr-auto items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
          >
            Update Content Library
          </button>
        </dd>
      </div>
    </dl>
  )
}

export default DetailCard
