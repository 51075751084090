import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'

import classNames from 'classnames';

const Stat = (props) => {
  let item = props.stat;
  return (
    <div
      key={item.id}
      className="relative bg-white py-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
      <dt>
        <div className="absolute bg-tukios-green rounded-md p-2">
          <item.icon className="h-5 w-5 text-white" aria-hidden="true" />
        </div>
        <p className="ml-12 text-sm font-medium text-gray-500 truncate">{item.name}</p>
      </dt>
      <dd className="ml-12 flex items-baseline">
        <p className="text-xl font-semibold text-gray-900">{item.stat}</p>
        {/* <p
          className={classNames(
            item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
            'ml-2 flex items-baseline text-sm font-semibold'
          )}
        >
          {item.changeType === 'increase' ? (
            <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
          ) : (
            <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
          )}

          <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
          {item.change}
        </p> */}
        {/* <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 cursor-pointer">
          <div className="text-sm">
            <div className="font-medium text-tukios hover:text-tukios-dark">
              {' '}
              View all<span className="sr-only"> {item.name} stats</span>
            </div>
          </div>
        </div> */}
      </dd>
    </div>
  )
}

export default Stat;