import TemplateList from "../launch/TemplateList"
import formatTemplateName from "../../utils/formatTemplateName"

const IntakeTemplate = ({ currentStep, setCurrentStepById, setTemplate, completeStep }) => {

  const selectTemplate = (e) => {
    setTemplate({
      id: e.template_id,
      baseId: e.base_site_name,
      name: formatTemplateName(e.template_name)
    })

    completeStep('template', true, formatTemplateName(e.template_name));

    setCurrentStepById('design');
  }

  if ( currentStep.id !== 'template' ) return null;
  return (
    <TemplateList
      setTemplate={selectTemplate}
    />
  )

}

export default IntakeTemplate;