import classNames from 'classnames'
import React, { useContext } from 'react'
import SiteMenu from '../../../components/sites/SiteMenu'
import { ModalContext } from '../../../hooks/ModalContext';
import ViewChecklistDialog from '../../../components/pages/ViewChecklistDialog';
import ViewNotesDialog from '../../../components/pages/ViewNotesDialog';
import { ClipboardIcon, PencilIcon } from '@heroicons/react/solid';
import ContentShimmer from 'react-content-shimmer';

export default function ThreeDotMenu({ data, className, Context }) {

  let { updateSiteLabelDisplay, updateLaunchDateDisplay, updateSiteInfoDisplay, mySites, isLoading } = useContext(Context)

  let { handleModal, setWidth } = useContext(ModalContext);

  const openChecklist = (data) => {
    handleModal(
      <ViewChecklistDialog data={data} updateSiteInfoDisplay={updateSiteInfoDisplay} />
    )
  }

  const openNotes = (data) => {
    setWidth('sm:max-w-4xl')
    handleModal(
      <ViewNotesDialog data={data} />
    )
  }

  return (
    <div className={classNames(className, "flex")}>
      {!isLoading ?
        <>
          <div className="flex gap-1 ml-2 mr-1">
            <a className="cursor-pointer text-gray-700 hover:text-gray-900" onClick={() => openChecklist(data)} data-tip="Open checklist">
              <ClipboardIcon className="w-5 h-5" />
            </a>
            <a className="cursor-pointer text-gray-700 hover:text-gray-900" onClick={() => openNotes(data)} data-tip="Open notes">
              <PencilIcon className="w-5 h-5" />
            </a>
          </div>
          <SiteMenu
            site_name={data?.name}
            site_id={data?.id}
            is_test={data?.launcher_label == 'Test'}
            launcher_label={data?.launcher_label}
            updateSiteLabelDisplay={updateSiteLabelDisplay}
            updateLaunchDateDisplay={updateLaunchDateDisplay}
            trello_card_id={data?.trello_card_id}
            mySites={mySites}
            data={data}
          />
        </>
        :
        <>
          <div className="flex gap-1 ml-2 mr-1">
            <ContentShimmer style={{ height: '20px', width: '20px', borderRadius: '7px' }} />
            <ContentShimmer style={{ height: '20px', width: '20px', borderRadius: '7px' }} />
          </div>
          <ContentShimmer style={{ height: '20px', width: '20px', borderRadius: '7px' }} />
        </>
      }
    </div>
  )
}