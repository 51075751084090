import { CheckIcon } from '@heroicons/react/solid'

const IntakeSteps = ({ allSteps, currentStep, setCurrentStep }) => {

  return (

    <nav aria-label="Progress">
      <ol className="border bg-white border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 mb-4">
        {allSteps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {step.complete ? (
              <div
                // onClick={() => setCurrentStep(step)}
                className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-tukios-green rounded-full">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="block ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                    {
                      step.details !== undefined &&
                        <>
                          <br/>
                          <span className="font-normal text-gray-500">
                            {step.details}
                          </span>
                        </>
                    }
                  </span>
                </span>
              </div>
            ) : step.id === currentStep.id ? (
              <div
                // onClick={() => setCurrentStep(step)}
                className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-tukios-green-dark rounded-full">
                  <span className="text-tukios-green-dark">{stepIdx + 1}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-tukios-green-dark">
                  {step.name}
                  {
                    step.details !== undefined &&
                      <>
                        <br/>
                        <span className="font-normal text-gray-500">
                          {step.details}
                        </span>
                      </>
                  }
                </span>
              </div>
            ) : (
              <div
                // onClick={() => setCurrentStep(step)}
                className="group flex items-center">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full ">
                    <span className="text-gray-500 ">{stepIdx + 1}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 ">
                    {step.name}
                    {
                      step.details !== undefined &&
                        <>
                          <br/>
                          <span className="font-normal text-gray-500">
                            {step.details}
                          </span>
                        </>
                    }
                  </span>
                </span>
              </div>
            )}

            {stepIdx !== allSteps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )

}

export default IntakeSteps;