import axiosConfig from '../axiosConfig';

const updateNote = (noteId, note) => {

  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/notes/update`, {
      noteId: noteId,
      note: note
    })
    .then((res) => resolve())
    .catch((err) => reject(err));
  })

}

export default updateNote;