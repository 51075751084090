import classNames from "classnames";

export default function ScrapeOrganizationId({ data, className }) {
  return (
    <div className={classNames("flex items-center relative", className)}>
        <a
          href={data?.kwargs?.organization_id ? `https://manage2.tukioswebsites.com/${data?.kwargs?.organization_id}/dashboard` : ''}
          target="_blank"
        >
          {data?.kwargs?.organization_id ?? '-'}
        </a>
    </div>
  )
}