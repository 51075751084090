
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { ModalContext } from '../../hooks/ModalContext'
import { useContext, useState } from 'react'
import generatePagesMeta from '../../utils/generatePagesMeta'
import classNames from 'classnames'
import getSite from '../../utils/getSite'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const GeneratePagesMetaDialog = ({ site, edit, updateSite, id }) => {

    let { handleModal } = useContext(ModalContext)
    let [isLoading, setIsLoading] = useState(false)

    const refresh = () => {
        setIsLoading(true)
        getSite(id).then(site => {
            updateSite(site)
            handleModal(false)
            setIsLoading(false)
        })
    }

    return (
        <>
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => handleModal(false)}
                >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Generate Page Meta Data
                        <div className="text-gray-500 text-sm">{site.id} - {site.name}</div>
                    </Dialog.Title>
                </div>
            </div>
            <div className="py-4 space-y-6 sm:space-y-5"></div>

            <div className="flex justify-center gap-4">
                {
                    !edit &&
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                            if (isLoading) {
                                return
                            }
                            setIsLoading(true)
                            generatePagesMeta(site.id, refresh, 1)
                        }}
                        className={classNames(isLoading ? 'opacity-90' : 'hover:bg-tukios-dark', "inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios focus:outline-none")}
                    >
                        Default
                    </button>
                }
                {
                    !edit &&
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                            if (isLoading) {
                                return
                            }
                            setIsLoading(true)
                            generatePagesMeta(site.id, refresh, 2)
                        }}
                        className={classNames(isLoading ? 'opacity-90' : 'hover:bg-tukios-dark', "inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios focus:outline-none")}
                    >
                        Preset 1
                    </button>
                }
                {
                    !edit &&
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                            if (isLoading) {
                                return
                            }
                            setIsLoading(true)
                            generatePagesMeta(site.id, refresh, 3)
                        }}
                        className={classNames(isLoading ? 'opacity-90' : 'hover:bg-tukios-dark', "inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios focus:outline-none")}
                    >
                        Preset 2
                    </button>
                }
                {
                    !edit &&
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                            if (isLoading) {
                                return
                            }
                            setIsLoading(true)
                            generatePagesMeta(site.id, refresh, 4)
                        }}
                        className={classNames(isLoading ? 'opacity-90' : 'hover:bg-tukios-dark', "inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios focus:outline-none")}
                    >
                        Preset 3
                    </button>
                }
                {
                    !edit &&
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                            if (isLoading) {
                                return
                            }
                            setIsLoading(true)
                            generatePagesMeta(site.id, refresh, 5)
                        }}
                        className={classNames(isLoading ? 'opacity-90' : 'hover:bg-tukios-dark', "inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios focus:outline-none")}
                    >
                        Preset 4
                    </button>
                }
                {
                    !edit &&
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                            if (isLoading) {
                                return
                            }
                            setIsLoading(true)
                            generatePagesMeta(site.id, refresh, 6)
                        }}
                        className={classNames(isLoading ? 'opacity-90' : 'hover:bg-tukios-dark', "inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios focus:outline-none")}
                    >
                        Preset 5
                    </button>
                }
                {
                    !edit &&
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                            if (isLoading) {
                                return
                            }
                            setIsLoading(true)
                            generatePagesMeta(site.id, refresh, 7)
                        }}
                        className={classNames(isLoading ? 'opacity-90' : 'hover:bg-tukios-dark', "inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios focus:outline-none")}
                    >
                        Preset 6
                    </button>
                }
            </div>
        </>
    )
}

export default GeneratePagesMetaDialog