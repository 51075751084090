import axiosConfig from '../axiosConfig';

const listTasks = ({page, perPage, searchString, scrapeStatusFilter, scrapeTypeFilter, scrapeProviderFilter}) => {

  return new Promise((resolve, reject) => {
    axiosConfig.get('/api/scrapiTasks', {
      params: {
        page: page,
        perPage: perPage,
        searchString: searchString,
        status: scrapeStatusFilter.id,
        type: scrapeTypeFilter.id,
        provider: scrapeProviderFilter.id
      }
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err));
  })

}

export default listTasks;