import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';

export default function CorporationSiteFilter({ Context, className }) {

  const { anyCorporation, corporations, corporationFilter, handleCorporationChange } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        placeholder={anyCorporation}
        filters={corporations}
        selected={corporationFilter}
        setSelected={handleCorporationChange} />
    </div>
  )
}
