
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import moment from 'moment'
import { useState, useContext, useEffect } from 'react'
import { ModalContext } from '../../hooks/ModalContext'
import { updateLaunchDate, launchDateAvailable } from '../../utils/updateLaunchDate'
import { isDirectorOfAccountsManagement, isLaunchSpecialist } from '../../utils/users'
import { toast } from 'react-toastify'
import { rescheduleReasons } from '../../utils/utilityLibrary'
import classNames from 'classnames'

const ScheduleLaunchDialog = ({ site, updateLaunchDateDisplay = false, reschedule = false }) => {

  const inputClasses = 'shadow appearance-none border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'

  let { handleModal } = useContext(ModalContext)

  const [launchDate, setLaunchDate] = useState(false)
  const [reason, setReason] = useState("")
  const [saving, setSaving] = useState(false)
  const [launchError, setLaunchError] = useState("")
  const [rescheduleError, setRescheduleError] = useState("")
  const [note, setNote] = useState("")

  useEffect(() => {

    if (!launchDate) return

    setSaving(true)

    launchDateAvailable(launchDate)
      .then(launches => {

        let tempError = ''

        let today = moment().format('YYYYMMDD')

        if (!isLaunchSpecialist() && !isDirectorOfAccountsManagement()) {
          if (launches >= 7) {
            tempError = 'Cannot be more than 7 launches on one day'
          }
          else if ([6, 7].includes(moment(launchDate).isoWeekday())) {
            tempError = 'Cannot schedule a launch on the weekend'
          }
          else if (launchDate.valueOf() < today.valueOf()) {
            tempError = 'Cannot schedule a launch date in the past'
          }
          else if (site?.scheduled_launch_date) {
            if (launchDate.valueOf() < moment(site.pretty_scheduled_launch_date, 'MMM D, YYYY').format('YYYYMMDD').valueOf()) {
              tempError = 'New launch date must be after old'
            }
          }
          else {
            if (countBusinessDaysBetween(today, launchDate) < 3) {
              tempError = 'Launches must be scheduled at least 3 business days out'
            }
          }
        }

        setLaunchError(tempError)
        setSaving(false)
      })
  }, [launchDate])

  const handleChange = (e) => {
    setLaunchDate(e.target.value ? moment(e.target.value, 'YYYY-MM-DD').format('YYYYMMDD') : null)
  }

  const countBusinessDaysBetween = (today, launchDate) => {
    let businessDays = 0

    while (today < launchDate) {
      if (![6, 7].includes(moment(today).isoWeekday())) {
        businessDays++
      }
      today = moment(today).add(1, 'days').format('YYYYMMDD')
    }

    return businessDays
  }

  const handleSubmit = () => {
    if (saving) return
    setRescheduleError("")
    if (!launchDate || (!reason && reschedule) || launchError) {
      if (!launchDate) {
        setLaunchError('Please select a launch date')
      }
      if (reason === "" && reschedule) {
        setRescheduleError('Please select a reason')
      }
      return
    }

    setSaving(true)

    toast.promise(
      updateLaunchDate(site.id, launchDate, reason, note),
      {
        pending: 'Scheduling site for launch...',
        success: 'Site scheduled for launch!',
        error: 'Unable to schedule launch date'
      }
    ).then(() => {
      if (updateLaunchDateDisplay) {
        updateLaunchDateDisplay(site.id, launchDate)
      }
      else {
        window.location.reload()
      }
      handleModal(false)
    }).catch(() => {
      handleModal(false)
    })
  }

  return (
    <>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
          onClick={() => handleModal(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start w-full">
        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            {site.id} - {site.name}
          </Dialog.Title>
        </div>
      </div>
      <div className="py-4 space-y-6 sm:space-y-5">
        <div>
          <label htmlFor="launchDate" className="block text-sm font-medium text-gray-700">
            Launch Date {!isLaunchSpecialist() && <span className="text-gray-400 text-xs">- must be 3 business days out and cannot be on the weekend</span>}
          </label>
          <div className="mt-1">
            <input
              name="launchDate"
              type="date"
              className={inputClasses}
              onChange={(e) => { handleChange(e) }}
            />
          </div>
          {launchError &&
            <div className="text-red-500 font-medium mt-1 text-sm">{launchError}</div>
          }
        </div>
      </div>
      {reschedule &&
        <div className="pb-4 space-y-6 sm:space-y-5">
          <div>
            <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
              Reason for rescheduling
            </label>
            <select
              name="reason"
              className={inputClasses}
              initialValues="chooseReason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            >
              <option value="" disabled>Select one</option>
              {
                Object.values(rescheduleReasons).map(reason => (
                  <option value={reason}>{reason}</option>
                ))
              }
            </select>
            {rescheduleError &&
              <div className="text-red-500 font-medium mt-1 text-sm">{rescheduleError}</div>
            }
          </div>
        </div>
      }
      <div className="pb-4 space-y-6 sm:space-y-5">
        <div>
          <label htmlFor="note" className="block text-sm font-medium text-gray-700">
            Note
          </label>
          <div className="mt-1">
            <textarea
              name="note"
              type="text"
              placeholder="Anything else the team should know?"
              onChange={(e) => setNote(e.target.value)}
              className={classNames(inputClasses, 'h-40')}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={(e) => { handleSubmit() }}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
        >
          Submit
        </button>
        <button
          type="button"
          onClick={() => handleModal(false)}
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default ScheduleLaunchDialog