import { Switch, Route } from 'react-router-dom';
import { isAdmin, isAE, isSDR } from '../../utils/users';
import Actions from "./Actions";
import AdminOnlyTab from './AdminOnlyTab';
import DetailCard from "./DetailCard";
import Checklist from './Checklist';
import SiteRevisions from './SiteRevisions';
import SiteScrape from './SiteScrape';
import Notes from './Notes';
import { ScrapeRequestFormProvider } from '../../hooks/ScrapeRequestFormContext';
import Domains from './Domains';

const TabRouter = ({ site, path, isLoading }) => {

  return (
    <Switch>
      <Route
        path={`${path}`}
        exact
        render={() => (
          <DetailCard site={site} isLoading={isLoading} />
        )}
      />
      <Route path={`${path}/scrape`} render={() => (
        <ScrapeRequestFormProvider>
          <SiteScrape site={site} />
        </ScrapeRequestFormProvider>
      )} site={site} />
      <Route path={`${path}/actions`} component={Actions} />
      <Route path={`${path}/qa`} render={() => (
        <SiteRevisions site={site} />
      )} site={site} />
      <Route path={`${path}/notes`} render={() => (
        <Notes data={site} />
      )} site={site} />
      <Route path={`${path}/checklist`} render={() => (
        <Checklist site={site}/>
      )} site={site} />      
      <Route 
        path={`${path}/domains`}
        render={() => (
          (isAdmin() || isAE() || isSDR()) ?
          <Domains site={site} />
          : <AdminOnlyTab />
        )}
      />
    </Switch>
  )
}

export default TabRouter;
