import { useContext } from "react";
import { ModalContext } from "../../hooks/ModalContext";
import { TrashIcon } from "@heroicons/react/solid";
import DangerDialog from "../ui/modal/dialogs/Danger";

const DeleteButton = () => {

  // title, actionText, messageText, setOpen

  let { handleModal } = useContext(ModalContext);

  const faker = (cb) => {
    setTimeout(() => {
      cb();
    }, 1000)
  }

  return (
    <button
      type="button"
      onClick={() => handleModal(
        <DangerDialog
          title='Delete Site'
          actionText='Delete'
          messageText='Are you sure you want to delete this site? This action cannot be undone.'
          actionFn={faker}
        />
      )}
      className="mr-1 inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
    >
      &#8203;
      <span>
        <TrashIcon className='h-4 w-4 text-red-500' />
      </span>
    </button>
  )
}

export default DeleteButton;