import axiosConfig from '../axiosConfig'
import { useCookies } from 'react-cookie'
import { useEffect, useState } from 'react'
import logo from './assets/tukios-logo-green.png'
import { useHistory, useLocation } from 'react-router'
import { FaSpinner } from 'react-icons/fa'

const Login = () => {

  const location = useLocation()

  let { from } = location.state || { from: { pathname: '/' } }
  let history = useHistory()

  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies()
  var [loginError, setLoginError] = useState(false)
  var [loginLoading, setLoginLoading] = useState(false)
  var [account, setAccount] = useState({
    username: '',
    password: ''
  })

  const handleChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    account[name] = value
    setAccount(account)
  }

  const userLogin = (e, preauthAccount = null) => {
    if (e) e.preventDefault()

    setLoginLoading(true)
    axiosConfig.post('/login', preauthAccount ? preauthAccount : account, { withCredentials: true })
      .then((res) => {
        setLoginLoading(false)
        if (res.data.status === 'SUCCESS') {

          setCookie('access_token', res.data.token, { path: '/' })

          localStorage.setItem('name', res.data.user.name)
          localStorage.setItem('user_id', res.data.user.id)
          localStorage.setItem('role', res.data.user.role)
          localStorage.setItem('image', res.data.user.image)
          localStorage.setItem('email', res.data.user.email)
          localStorage.setItem('trello_id', res.data.user.trello_id)

          // update axios config with new bearer token, for safari support
          axiosConfig.defaults.headers.common['Authorization'] = `bearer ${res.data.token}`

          // redirect to original place or home page
          history.replace(res.data.redirect ? res.data.redirect : from)

        } else {
          setLoginError(true)
        }
      })
  }

  const preauthUser = new URLSearchParams(location.search).get('u')
  // let [ preauthUser, setPreauthUser ] = useState(new URLSearchParams(location.search).get('u'))
  let [preauthLoading, setPreauthLoading] = useState(false)

  useEffect(() => {
    if (preauthUser) {
      setPreauthLoading(true)
      axiosConfig.post('/preauth', { u: preauthUser })
        .then((res) => {
          setAccount(res.data)
          userLogin(null, res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // eslint-disable-next-line
  }, [])


  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">

      {
        (preauthLoading) &&
        <div className="flex items-center justify-center absolute top-0 left-0 bg-white opacity-80 h-full w-full">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 flex items-center">
            Authenticating
            <FaSpinner className="ml-2 w-7 h-7 text-gray-900 icon-spin" />
          </h2>
        </div>
      }

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logo}
          alt="Tukios Logo"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={userLogin}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-tukios focus:border-tukios sm:text-sm"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-tukios focus:border-tukios sm:text-sm"
                  onChange={handleChange}
                />
              </div>
            </div>

            {
              loginError && (
                <div className="text-sm font-medium text-red-500 text-center">
                  Invalid email or password
                </div>
              )
            }

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-tukios hover:bg-tukios-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tukios"
                disabled={loginLoading}
              >
                {
                  !loginLoading ? <span>Sign in</span> : <span>Loading...</span>
                }
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  )

}

export default Login