import classNames from "classnames"
import { useContext } from "react"
import { toast } from "react-toastify"
import DropdownWithAvatars from "../../../components/ui/DropdownWithAvatars"
import assignAe from "../../assignAe"
import logActivity from "../../logActivity"
import { isAE, isAdmin } from "../../users"
import ContentShimmer from "react-content-shimmer"

export default function SiteAeDropdown({ data, className, Context }) {

  let { sites, setSites, aes, isLoading } = useContext(Context)

  const setSiteAe = (fhid, e) => {

    let aeId = e.user_id == 'none' ? null : e.user_id

    assignAe(fhid, aeId)
      .then((res) => {

        logActivity({
          message: `${e.name} claimed the site`,
          fhId: fhid,
          logType: 'AE'
        })

        let currentSites = [...sites]
        let currentSiteIndex = currentSites.map(s => s.id).indexOf(fhid)
        let currentSite = { ...currentSites[currentSiteIndex] }

        currentSite.ae_id = e.id == 'none' ? null : e.id
        currentSite.ae_name = e.id == 'none' ? null : e.name
        currentSite.ae_image = e.id == 'none' ? null : e.image

        currentSites[currentSiteIndex] = currentSite

        setSites(currentSites)

        toast.success(e.name == 'Not assigned' ? 'Unassigned' : `${e.name} assigned to site!`)
        if (res !== true) toast.warn(res)
      })
      .catch((err) => {
        toast.error('Error updating site ae')
      })

  }

  return (
    <div className={classNames("whitespace-nowrap text-sm font-medium text-gray-900", className)}>
      {!isLoading ?
        <DropdownWithAvatars
          people={aes}
          current={data.ae_id ? {
            name: data.ae_name,
            image: data.ae_image
          } : null}
          handleSelection={(e) => setSiteAe(data.id, e)}
          disabled={isAdmin() ? false : !isAE()}
        /> : <ContentShimmer style={{ height: '34px', width: '200px', borderRadius: '7px' }} />
      }
    </div>
  )
}