import classNames from "classnames"
import { useContext, useEffect, useRef } from "react"
import { useState } from "react"
import { isAE, isAM, isAdmin, isDesigner, isDirectorOfAccountsManagement, isDirectorOfSalesDevelopement, isFlorist, isLaunchSpecialist, userId } from "../../utils/users"
import RevisionsLoader from "./RevisionsLoader"
import { ModalContext } from "../../hooks/ModalContext"
import createNote from "../../utils/createNote"
import listNotes from "../../utils/listNotes"
import UpdateNoteDialog from "../pages/UpdateNoteDialog"
import NoteTypeBadge from "./NoteTypeBadge"
import NoteType from "./NoteType"
import { toast } from "react-toastify"
import TypeNoteFilter from "./TypeNoteFilter"
import { noteTypeFilters, siteStatuses } from "../../utils/utilityLibrary"

const Notes = ({ data, className }) => {

  let { handleModal } = useContext(ModalContext)

  let [notes, setNotes] = useState([])
  let [isLoading, setIsLoading] = useState(true)
  let [saving, setSaving] = useState(false)
  let [type, setType] = useState(isLaunchSpecialist() || isAE() ? 'Launch' : isDesigner() ? 'Design' : isFlorist() ? 'Floral' : (isAM() || isDirectorOfAccountsManagement()) ? 'Account' : 'Launch')
  let [typeFilter, setTypeFilter] = useState(isLaunchSpecialist() ? noteTypeFilters[0] : isFlorist() ? noteTypeFilters[1] : isDesigner() ? noteTypeFilters[2] : (isAM() || isDirectorOfSalesDevelopement()) && data.launcher_label == siteStatuses.live ? noteTypeFilters[3] : { id: '*', name: 'All Notes', color: 'bg-gray-400' })
  let textBoxRef = useRef()

  const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none'

  const handleNoteTypeChange = (e) => {
    setType(e.target.id)
  }

  const updateQanote = (note) => {
    handleModal(
      <UpdateNoteDialog note={note} />
    )
  }

  const submitnote = () => {
    if (saving || textBoxRef.current.value == '') return
    setSaving(true)
    createNote(data.id, textBoxRef.current.value, type).then((res) => {
      textBoxRef.current.value = ''
      refreshnotes()
      setSaving(false)
      toast.success('Note added')
    }).catch(err => {
      textBoxRef.current.value = ''
      refreshnotes()
      setSaving(false)
      toast.error('Note added but unable to connect to Trello')
    })
  }

  const refreshnotes = () => {
    setIsLoading(true)
    listNotes(data.id, typeFilter.id).then((notes) => {
      setNotes(notes)
      setIsLoading(false)
    })
  }

  const handleNoteTypeFilterChange = (e) => {
    setTypeFilter(e)
  }

  useEffect(() => {
    refreshnotes()
  }, [typeFilter])

  return (
    <div className={className}>
      <div>
        <textarea
          name="newnote"
          ref={textBoxRef}
          type="text"
          placeholder="Incorrect email address linked in the footer."
          className={classNames(inputClasses, 'h-36')}
        />
        <div className="pt-3">
          <div className="mb-6 flex items-center">
            <NoteType handleNoteTypeChange={handleNoteTypeChange} defaultType={type} className={"ml-auto mr-5"} />
            <button
              type="button"
              className={classNames(saving ? 'opacity-50' : '', "block w-fit items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none")}
              onClick={() => submitnote()}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="w-48 ml-auto mb-1">
        <TypeNoteFilter handleNoteTypeFilterChange={handleNoteTypeFilterChange} typeFilter={typeFilter} />
      </div>
      <ul role="list" className="divide-y divide-gray-200">
        {
          (notes.length == 0 && !isLoading) && <div className="mb-4">No {typeFilter.id != '*' && typeFilter.id.toLowerCase()} notes logged</div>
        }
        {
          !isLoading ? (
            notes.map((note) => (
              <li
                key={note.id}
                className={classNames("relative py-5 px-4 focus-within:ring-inset focus-within:ring-0 hover:bg-gray-50 bg-white")}
              >
                <div className="flex gap-3">
                  <div className="flex-col justify-center">
                    <div className="flex-col flex gap-1 align-center min-w-max w-28 shrink-0">
                      <img className="max-w-none h-14 w-14 rounded-full ring-2 ring-white my-auto mx-auto" src={note.user_image} />
                    </div>
                    <div className="flex justify-center mt-1"><NoteTypeBadge type={note.type} /></div>
                  </div>
                  <div className="flex-col justify-between">
                    <div className="min-w-0 flex-1 flex flex-col">
                      <p className="truncate text-sm font-medium text-gray-900">{note.user_name}</p>
                    </div>
                    <div className="text-sm break-words text-gray-600 line-clamp-2 shrink-1" style={{ whiteSpace: 'pre-line' }}>{note.note.replace(/\\n/g, '\n')}</div>
                  </div>
                  <div className="ml-auto mt-auto mb-auto flex-col">
                    <div className="text-sm whitespace-nowrap">{note.date_created}</div>
                    {(isAdmin() || note.user_id == userId()) &&
                      <button
                        className="block ml-auto mr-auto items-center justify-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
                        onClick={() => updateQanote(note)}
                      >Edit</button>
                    }
                  </div>
                </div>
              </li>
            ))
          ) : (<RevisionsLoader />)}
      </ul>
    </div>
  )
}

export default Notes
