import axiosConfig from '../../axiosConfig'
import {useEffect, useState, useContext} from 'react'
import {ModalContext} from '../../hooks/ModalContext'
import ClipLoader from 'react-spinners/ClipLoader'
import {ExclamationCircleIcon, CheckCircleIcon, ChevronUpIcon} from '@heroicons/react/outline'
import {Disclosure, Transition} from '@headlessui/react'
import {toast} from 'react-toastify'
import {goLive} from '../../utils/updateSite'
import {corporationContactInfo, listCorporations} from '../../utils/utilityLibrary'


const GoLiveButton = ({site, updateSiteLabelDisplay}) => {

  const [domainCheck, setDomainCheck] = useState(false)
  const [subscriberCheck, setSubscriberCheck] = useState(false)
  const [obitDataCheck, setObitDataCheck] = useState(false)
  const [dudaCheck, setDudaCheck] = useState(false)
  const [miscDataCheck, setMiscDataCheck] = useState(false)
  const [revisionsCheck, setRevisionsCheck] = useState(false)
  const [iconCheck, setIconCheck] = useState(false)
  const [designCheck, setDesignCheck] = useState(false)
  const [websiteContentCheck, setWebsiteContentCheck] = useState(false)
  const [finalCheck, setFinalCheck] = useState(false)
  const [floralCheck, setFloralCheck] = useState(false)
  // const [accountCheck, setAccountCheck] = useState(false)
  const [seoCheck, setSeoCheck] = useState(false)
  const [manage2DomainCheck, setmanage2DomainCheck] = useState(false)

  const [corporation, setCorporation] = useState()
  const [corporationContacts, setCorporationContacts] = useState()
  const [corpEmail, setCorpEmail] = useState('')
  const [otherEmail, setOtherEmail] = useState()
  const [instructionsSent, setInstructionsSent] = useState(false)

  const [isLive, setIsLive] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [launching, setLaunching] = useState(null)
  const [error, setError] = useState(false)
  let {handleModal} = useContext(ModalContext)

  useEffect(() => {
    setCorporationContacts(Object.values(corporationContactInfo).find(contact => contact.id === site.corporation_id))
    setCorpEmail(corporationContacts?.contacts?.find((contact) => contact.domainContact === true && contact.priority === 1)?.email ?? 'other')
    listCorporations()
      .then((corpList) => {
        setCorporation(Object.values(corpList).find(corporation => corporation.id === site.corporation_id))
      })
      .catch((error) => {        
        console.log(error)
      })
  }, [corporationContacts, site.corporation_id])

  useEffect(() => {
    if (isLoading !== null || launching !== null || isSubmitting !== null) {
      showModal()
    }
  }, [isLoading, launching, isSubmitting, corpEmail, instructionsSent])

  const goLiveCheck = (type, checklistType, listId) => {
    return new Promise((resolve, reject) => {
      axiosConfig.post('/api/goLive', {
        goLiveCheckType: type,
        checklistType: checklistType,
        listId: listId,
        fhid: site.id,
        domain: site.apex
      })
        .then((res) => {
          return resolve(res)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  }  

  const handleGoLiveChecks = () => {
    setIsLoading(true)
    showModal()

    Promise.allSettled([goLiveCheck('domain', 'launch', 16), goLiveCheck('subscribers', 'launch', 16), goLiveCheck('obitData', 'launch', 16),
    goLiveCheck('duda', 'launch', 16), goLiveCheck('miscData', 'launch', 16), goLiveCheck('revisions', 'launch', 16), goLiveCheck('manage2Domain', 'launch', 16),
    goLiveCheck('icons', 'design', 2), goLiveCheck('designChecks', 'design', 3), goLiveCheck('websiteContent', 'design', 4), goLiveCheck('finalChecks', 'design', 5),
    goLiveCheck('floral', 'floral', 18), goLiveCheck('seo', 'seo', 17)])
      .then((res) => {
        res.map((promise, index) => {
          if(promise.status === 'rejected'){
            return console.log(`Promise ${index + 1} failed with reason: ${promise.reason}`)
          }
        })
        setDomainCheck(res[0]?.value.data)
        setSubscriberCheck(res[1]?.value.data)
        setObitDataCheck(res[2]?.value.data)
        setDudaCheck(res[3]?.value.data)
        setMiscDataCheck(res[4]?.value.data)
        setRevisionsCheck(res[5]?.value.data)
        setmanage2DomainCheck(res[6]?.value.data)
        setIconCheck(res[7]?.value.data)
        setDesignCheck(res[8]?.value.data)
        setWebsiteContentCheck(res[9]?.value.data)
        setFinalCheck(res[10]?.value.data)
        setFloralCheck(res[11]?.value.data)
        // setAccountCheck(res[12]?.value.data)
        setSeoCheck(res[12]?.value.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleGoLive = () => {
    setLaunching(true)
    showModal()
    goLiveCheck('launchSite', 'launch', 16)
      .then((res) => {
        setIsLive(res.data.live)
        setLaunching(false)
        toast.promise(
          goLive(site.id, updateSiteLabelDisplay),
          {
            pending: 'Launching...',
            success: 'Site marked as live!',
            error: 'Unable to mark site as live'
          }
        )
      })
      .catch((error) => {
        setLaunching(false)
        console.log(error)
      })
  }

  const sendDnsInstructions = (e) => {
    console.log(otherEmail)
    e.preventDefault()
    setIsSubmitting(true)
    let contactEmail = corpEmail !== 'other' ? corpEmail : otherEmail
    axiosConfig.post('/api/goLive/sendDnsInstructions', {
      fhName: site.name,
      contactEmail: contactEmail,
      fhid: site.id, 
      domain: site.apex,
    })
      .then(() => {
        setIsSubmitting(null)
        setInstructionsSent(true)
      })
      .catch((error) => {
        setError(true)
        setIsSubmitting(null)
        console.log(error)
      })
  }

  const showModal = () => {
    handleModal(
      <>
        <div className="mb-5 text-center text-xl font-medium text-gray-500">
          {!isLoading ? <h1>Go Live Check Results</h1> : <h1>Running Go Live Checks</h1>}
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* Domain */}
          <div>
            <h3 className="text-md font-medium text-gray-500 ">Domain Proxy</h3>
            <div className="flex">
              {!isLoading ? domainCheck.domain ? (
                <>
                  <div className="mr-1">
                    {domainCheck.onAws ? <CheckCircleIcon className="w-6 text-green-500" /> : <ExclamationCircleIcon className="w-6 text-yellow-500" />}
                  </div>
                  <p>{domainCheck.message}</p>
                </>
              ) : (
                <>
                  <div>
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>Domain is not ready</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Manage 2 Domains  */}
          <div>
            <h3 className="text-md font-medium text-gray-500">M2 Primary Domain</h3>
            <div className="flex">
              {!isLoading ? manage2DomainCheck.primaryDomain ? (
                <>
                  <div className="mr-1">
                    {manage2DomainCheck.primaryDomain ? <CheckCircleIcon className="w-6 text-green-500" /> : <ExclamationCircleIcon className="w-6 text-yellow-500" />}
                  </div>
                  <p>{manage2DomainCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="mr-1">
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>{manage2DomainCheck.message}</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Subscribers */}
          <div>
            <h3 className="text-md font-medium text-gray-500">Subscribers</h3>
            <div className="flex">
              {!isLoading ? subscriberCheck.subscribers ? (
                <>
                  <div className="mr-1">
                    {subscriberCheck.hasSubscribers ? <CheckCircleIcon className="w-6 text-green-500" /> : <ExclamationCircleIcon className="w-6 text-yellow-500" />}
                  </div>
                  <p>{subscriberCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="mr-1">
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>{subscriberCheck.message}</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Obituary Data */}
          <div>
            <h3 className="text-md font-medium text-gray-500">Scrape / Migration</h3>
            <div className="flex">
              {!isLoading ? obitDataCheck.obitData ? (
                <>
                  <div className="mr-1">
                    {obitDataCheck.hasDrafts ? <ExclamationCircleIcon className="w-6 text-yellow-500" /> : <CheckCircleIcon className="w-6 text-green-500" />}
                  </div>
                  <p>{obitDataCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="mr-1">
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>{obitDataCheck.message}</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Duda */}
          <div>
            <h3 className="text-md font-medium text-gray-500">Duda</h3>
            <div className="flex">
              {!isLoading ? dudaCheck.duda ? (
                <>
                  <div className="mr-1">
                    <CheckCircleIcon className="w-6 text-green-500" />
                  </div>
                  <p>{dudaCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="mr-1">
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>{dudaCheck.message}</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Misc Data */}
          <div>
            <h3 className="text-md font-medium text-gray-500">Blogs / Merch / Testimonials</h3>
            <div className="flex flex-wrap">
              {!isLoading ? miscDataCheck.miscData ? (
                <>
                  <div className="mr-1">
                    <CheckCircleIcon className="w-6 text-green-500" />
                  </div>
                  <p>{miscDataCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="flex mb-3">
                    <div className="mr-1">
                      <ExclamationCircleIcon className="w-6 text-red-500" />
                    </div>
                    <p>{miscDataCheck.message}</p>
                  </div>
                  <Disclosure>
                    {({open}) => (
                      <>
                        <Disclosure.Button className="w-full flex justify-between shadow p-2 rounded-sm">
                          <p>View Pending Scrapes</p>
                          <ChevronUpIcon className={open ? "w-6 rotate-180 transform duration-200" : "w-6 transform duration-200"} />
                        </Disclosure.Button>
                        <Transition
                          className="w-full shadow p-2"
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0">
                          <Disclosure.Panel>
                            <ul>
                              {miscDataCheck?.pendingScrapes?.map((pendingScrapeType) => (
                                <li className="px-3 m-1 inline-flex leading-5 rounded-full text-sm bg-yellow-100 text-yellow-800">{pendingScrapeType}</li>
                              ))}
                            </ul>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Revisions Check */}
          <div>
            <h3 className="text-md font-medium text-gray-500">Revisions</h3>
            <div className="flex">
              {!isLoading ? revisionsCheck.revisions ? (
                <>
                  <div className="mr-1">
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>{revisionsCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="mr-1">
                    <CheckCircleIcon className="w-6 text-green-500" />
                  </div>
                  <p>{revisionsCheck.message}</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Design Checks */}
          <div>
            <h3 className="text-md font-medium text-gray-500">Design</h3>
            <div className="flex flex-wrap">
              {!isLoading ? designCheck.designCheck && iconCheck.icon && finalCheck.finalCheck && websiteContentCheck.websiteContent ? (
                <>
                  <div className="mr-1">
                    <CheckCircleIcon className="w-6 text-green-500" />
                  </div>
                  <p>Design Completed</p>
                </>
              ) : (
                <>
                  <div className="flex mb-3">
                    <div className="mr-1">
                      <ExclamationCircleIcon className="w-6 text-red-500" />
                    </div>
                    <p>Unfinished Design Lists</p>
                  </div>
                  <Disclosure>
                    {({open}) => (
                      <>
                        <Disclosure.Button className="w-full flex justify-between shadow p-2 rounded-sm">
                          <p>View Unfinished Lists</p>
                          <ChevronUpIcon className={open ? "w-6 rotate-180 transform duration-200" : "w-6 transform duration-200"} />
                        </Disclosure.Button>
                        <Transition
                          className="w-full shadow p-2"
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0">
                          <Disclosure.Panel>
                            <ul>
                              {!iconCheck.icon && <li className="px-3 m-1 inline-flex leading-5 rounded-full text-sm bg-yellow-100 text-yellow-800">{iconCheck.name}</li>}
                              {!designCheck.designCheck && <li className="px-3 m-1 inline-flex leading-5 rounded-full text-sm bg-yellow-100 text-yellow-800">{designCheck.name}</li>}
                              {!websiteContentCheck.websiteContent && <li className="px-3 m-1 inline-flex leading-5 rounded-full text-sm bg-yellow-100 text-yellow-800">{websiteContentCheck.name}</li>}
                              {!finalCheck.finalCheck && <li className="px-3 m-1 inline-flex leading-5 rounded-full text-sm bg-yellow-100 text-yellow-800">{finalCheck.name}</li>}
                            </ul>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Floral */}
          <div>
            <h3 className="text-md font-medium text-gray-500">Floral</h3>
            <div className="flex">
              {!isLoading ? floralCheck.floral ? (
                <>
                  <div className="mr-1">
                    <CheckCircleIcon className="w-6 text-green-500" />
                  </div>
                  <p>{floralCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="mr-1">
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>{floralCheck.message}</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Account Manager */}
          {/* <div>
            <h3 className="text-md font-medium text-gray-500">Account Setup</h3>
            <div className="flex">
              {!isLoading ? accountCheck.account ? (
                <>
                  <div className="mr-1">
                    <CheckCircleIcon className="w-6 text-green-500" />
                  </div>
                  <p>{accountCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="mr-1">
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>{accountCheck.message}</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div> */}

          {/* Seo */}
          <div>
            <h3 className="text-md font-medium text-gray-500">Seo</h3>
            <div className="flex">
              {!isLoading ? seoCheck.seo ? (
                <>
                  <div className="mr-1">
                    <CheckCircleIcon className="w-6 text-green-500" />
                  </div>
                  <p>{seoCheck.message}</p>
                </>
              ) : (
                <>
                  <div className="mr-1">
                    <ExclamationCircleIcon className="w-6 text-red-500" />
                  </div>
                  <p>{seoCheck.message}</p>
                </>
              ) : <ClipLoader size={20} color={"#002D45"} />}
            </div>
          </div>

          {/* Launch Button */}
          {domainCheck.domain && subscriberCheck.subscribers && obitDataCheck.obitData && dudaCheck.duda && miscDataCheck.miscData &&
            !revisionsCheck.revisions && designCheck.designCheck && iconCheck.icon && manage2DomainCheck.primaryDomain &&
            websiteContentCheck.websiteContent && finalCheck.finalCheck && floralCheck.floral && seoCheck.seo && (
              <div className="col-span-2 text-center border-t-2">
                <div className="pt-3">
                  {domainCheck.onAws ? (
                    <>
                      {!launching ? isLive ? (
                        <div className="w-full ">
                          <div className="flex justify-center mb-4">
                            <CheckCircleIcon className="w-6 mr-1 text-green-500" />
                            <p>Your new site is live!</p>
                          </div>
                          <div className="flex justify-center ">
                            <button onClick={() => handleModal()} className='mr-2 inline-flex min-w-max items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none'>
                              Close
                            </button>
                            <a href={`https://www.${site.apex}`} target="_blank" rel="noreferrer" className="ml-2 inline-flex min-w-max items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none">
                              Check it out!
                            </a>
                          </div>
                        </div>
                      ) : (
                        <>
                          <h3 className="mb-5 text-md font-medium text-gray-500">Everything looks great! Please select the amount of send...</h3>
                          <button onClick={handleGoLive} className="inline-flex min-w-max items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none">
                            Full Send
                          </button>
                        </>
                      )
                        : <ClipLoader size={20} color={"#002D45"} />}
                    </>
                  ) : (
                    <>
                      {!instructionsSent && <h3 className="mb-5 text-md font-medium text-gray-500">Manual DNS changes required. Who's changing the dns?</h3>}
                      <div className="grid grid-cols-2 gap-5">
                        {/* Launch Specialist Dropdown */}
                        {!instructionsSent &&
                          <div className="flex flex-col">
                            <Disclosure>
                              {({open}) => (
                                <>
                                  <Disclosure.Button className="w-full flex justify-between shadow p-2 rounded-sm">
                                    <p>Tukios Launch Specialist</p>
                                    <ChevronUpIcon className={open ? "w-6 rotate-180 transform duration-200" : "w-6 transform duration-200"} />
                                  </Disclosure.Button>
                                  <Transition
                                    className="w-full shadow p-2"
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0">
                                    <Disclosure.Panel>
                                      <a href={"https://www.notion.so/Launch-a-Site-04f093c7d2d04addb9f36f665be4df42"} target="_blank" rel="noreferrer" class="inline-flex min-w-max items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none">
                                        <button>View Wiki</button>
                                      </a>
                                    </Disclosure.Panel>
                                  </Transition>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        }

                        {/* Instructions Form */}
                        {!isSubmitting ? instructionsSent ? (
                          <div className="w-full col-span-2 flex flex-col items-center">
                            <div className="flex mb-2">
                              <CheckCircleIcon className="w-6 mr-1 text-green-500" />
                              <p>Instructions Sent To...</p>
                            </div>
                            <p className="text-center col-span-2">{corpEmail !== 'other' ? corpEmail : otherEmail}</p>
                          </div>
                        ) : (
                          <div>
                            <Disclosure>
                              {({open}) => (
                                <>
                                  <Disclosure.Button className="w-full flex justify-between shadow p-2 rounded-sm">
                                    <div className="w-full text-center">
                                      {corporation?.name && <p>{corporation.name !== 'None' ? corporation.name : 'Funeral Home or IT'}</p>}
                                    </div>
                                    <ChevronUpIcon className={open ? "w-6 rotate-180 transform duration-200" : "w-6 transform duration-200"} />
                                  </Disclosure.Button>
                                  <Transition
                                    className="w-full shadow p-2"
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0">
                                    <Disclosure.Panel>
                                      <form onSubmit={sendDnsInstructions}>
                                        <p className="text-center mb-2">Send launch instructions to...</p>
                                        <div className="flex flex-col items-end">
                                          <div className="w-full flex flex-col items-start">
                                            <select
                                              name="corporation"
                                              className="mt-1 block w-full pl-3 pr-10 py-2 mb-3 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                                              value={corpEmail}
                                              onChange={(e) => setCorpEmail(e.target.value)}>
                                              <option value="" disabled>Select one</option>
                                              {
                                                corporationContacts?.contacts?.map((contact, i) => (
                                                  contact.domainContact && (
                                                    <>
                                                      <option value={contact.email} key={i}>{contact.name}</option>
                                                    </>
                                                  )
                                                ))

                                              }
                                              <option value='other'>Other</option>
                                            </select>
                                          </div>

                                          {(corpEmail === 'other') && (
                                            <div className="flex flex-col w-full items-start mb-3">
                                              <label>Email</label>
                                              <input type="email" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow" value={otherEmail} onChange={(e) => setOtherEmail(e.target.value)} />
                                            </div>
                                          )}

                                          <button type="submit" className='inline-flex min-w-max items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none'>Send Email</button>
                                        </div>
                                      </form>
                                    </Disclosure.Panel>
                                  </Transition>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        ) : !error ? (
                          <div className="flex">
                            <div className="mr-2">
                              <ClipLoader size={20} color={"#002D45"} />
                            </div>
                            <p>Sending Instructions</p>
                          </div>
                        ) : (
                          <div className="flex">
                            <div className='flex mr-1 items-center'>
                              <ExclamationCircleIcon className="w-6 text-red-500" />
                            </div>
                            <p>Couldn't send email</p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
        </div>
      </>
    )
  }
  return (
    <>
      <button onClick={() => {handleGoLiveChecks()}} className="inline-flex min-w-max items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none">Go Live</button>
    </>
  )
}

export default GoLiveButton