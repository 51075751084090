import { useEffect, useState } from 'react'
import axiosConfig from '../../axiosConfig'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import ContentShimmer from 'react-content-shimmer'
import { ClipboardIcon } from '@heroicons/react/outline'


const Proxify = ({ site }) => {
  const [ domain, setDomain ] = useState(site.apex)
  const [ records, setRecords ] = useState({})
  const [ zoneId, setZoneId] = useState()
  const [ onGodaddy, setOnGoDaddy ] = useState(false)
  const [ nameServers, setNameservers ] = useState([])
  const [ showRecords, setShowRecords ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isSubmitting, setIsSubmitting ] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setDomain(site.apex)
    if (site.apex) {
      setIsLoading(false)
    }
  }, [ site ])


  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    axiosConfig.post('/api/proxify', {
      domain,
      fhid: site.id,
    })
      .then((data) => {
        console.log(data.data.lookupData)
        setShowRecords(true)
        setRecords(data.data.lookupData)
        setZoneId(data.data.zoneId.replace('/hostedzone/', ''))
        setNameservers(data.data.ns)
        setOnGoDaddy(data.data.onGodaddy)
        setIsSubmitting(false)
        setIsLoading(false)
        toast.success('Domain Proxied')
      })
      .catch((error) => {
        setIsLoading(false)
        setIsSubmitting(false)        
        if(error.message.includes('403')){
          toast.error('Domain Already Proxied!')
        }
        if (error.message.includes('400')){
          toast.error('No DNS records found')
        }
        if (error.message.includes('406')) {
          toast.error('Error updating checklist & notes')
        }       
        if(!error.message.includes('403') && !error.message.includes('400') && !error.message.includes('406')){
          toast.error('Could not proxy domain, contact developer')
        }
            
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='flex w-full mb-5'>
          {!isLoading ? (
            <>
              <input type="text" className='block w-full mr-5 pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow' value={domain} onChange={(e) => setDomain(e.target.value)} />
              <button type="submit" className={classNames('block w-4/12 items-center justify-center px-4 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white col-start-2 justify-self-end', isSubmitting || isLoading ? "bg-gray-500" : "bg-tukios hover:bg-tukios-dark focus:outline-none")}>Proxy</button>
            </>
          ) : (
            <ContentShimmer style={{ width: "600px", height: "38px", borderRadius: "7px" }} />
          )}
        </div>

        {!isSubmitting ? (
          records && showRecords && (
            <>
              {onGodaddy ? (<div className='flex w-full justify-center items-center mt-10 mb-10 p-4 bg-green-50 border-solid border-2 rounded-md border-green-100'>
                <label className='mr-5 text-xl font-bold'>Nameservers Updated on GoDaddy</label>
                <a href={`https://dcc.godaddy.com/control/portfolio/${domain}/settings`} target='_blank' rel='noreferrer' className='text-center px-4 py-2 w-1/4 border border-transparent text-md font-medium rounded-md shadow-sm text-white col-start-2 justify-self-end bg-tukios hover:bg-tukios-dark focus:outline-none'>
                  View Domain
                </a>
              </div>) : (<div className='flex w-full justify-center items-center mt-10 mb-10 p-4 bg-yellow-50 border-solid border-2 rounded-md border-yellow-100'>
                <span className='mr-5 text-xl font-bold'>Manual Proxy Required</span>
                <a href={`https://us-east-1.console.aws.amazon.com/route53/v2/hostedzones?region=us-east-1#ListRecordSets/${zoneId}`} target='_blank' rel='noreferrer' className='text-center mr-5 px-4 py-2 w-1/4 border border-transparent text-md font-medium rounded-md shadow-sm text-white col-start-2 justify-self-end bg-tukios hover:bg-tukios-dark focus:outline-none'>
                  Hosted Zone
                </a>
                <a href={`https://www.godaddy.com/whois/results.aspx?itc=dlp_domain_whois&domain=${domain}`} target='_blank' rel='noreferrer' className='text-center px-4 py-2 w-1/4 border border-transparent text-md font-medium rounded-md shadow-sm text-white col-start-2 justify-self-end bg-tukios hover:bg-tukios-dark focus:outline-none'>
                  WhoIs
                </a>
              </div>)}
              <div className='grid grid-cols-2 w-3/4'>
                <label className='col-span-2 text-xl font-semi-bold'>Records Added to AWS</label>
                <label className='text-xl font-medium text-gray-500 mt-auto mb-auto'>A Records</label>
                <div className='p-5'>
                  {records.a?.map((a) => (
                    <p>{a.Value}</p>
                  ))}
                </div>
                <div className='col-span-2 border-b-2' />
                {!onGodaddy && (
                  <>
                    <label className='text-xl font-medium text-gray-500 mt-auto mb-auto'>Name Servers</label>
                    <div className='p-5'>
                      {nameServers && nameServers.map((ns) => (
                        <div className='flex justify-left mb-1'>
                          <div className='mr-2 cursor-pointer'>
                            <ClipboardIcon className='w-6' onClick={() => {
                              navigator.clipboard.writeText(ns)
                              toast.success(`Copied ${ns}`)
                            }} />
                          </div>
                          <p>{ns}</p>
                        </div>
                      ))}
                    </div>
                    <div className='col-span-2 border-b-2' />
                  </>
                )}
                <label className='text-xl font-medium text-gray-500 mt-auto mb-auto'>MX Records</label>
                <div className='p-5'>
                  {records.mx?.map((mx) => (
                    <p>{mx.Value}</p>
                  ))}
                </div>
                <div className='col-span-2 border-b-2' />
                <label className='text-xl font-medium text-gray-500 mt-auto mb-auto'>TXT Records</label>
                <div className='p-5'>
                  {records.txt?.map((txt) => (
                    <p>{txt.Value}</p>
                  ))}
                </div>
                <div className='col-span-2 border-b-2' />
                <label className='text-xl font-medium text-gray-500 mt-auto mb-auto'>CNAME</label>
                <div className='p-5'>
                  {records.cname?.map((cname) => (
                    <p>{cname.Value}</p>
                  ))}            
                </div>
              </div>
            </>
          )
        ) : (
          [ ...Array(5).keys() ].map(() => (
            <ContentShimmer
              style={{
                width: "100%",
                height: "18px",
                borderRadius: "5px",
                marginBottom: "10px"
              }}
            />
          ))
        )}
      </form>
    </>
  )
}

export default Proxify