import { createContext } from "react";
import useModal from './useModal';
import Modal from '../components/ui/modal/Modal';
import { useEffect } from "react";

let ModalContext;
let { Provider } = (ModalContext = createContext());

let ModalProvider = ({ children }) => {
  let { isOpen, handleModal, modalContent, width, setWidth } = useModal();

  return (
    <Provider value={{ isOpen, handleModal, modalContent, width, setWidth }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
