import classNames from 'classnames';
import { useContext } from 'react';
import SiteFilter from '../../components/sites/SiteFilter';

export default function FloristSiteFilter({ Context, className }) {

  const { florists, floristFilter, handleFloristChange, anyFlorist } = useContext(Context)

  return (
    <div className={classNames(className, 'w-full')}>
      <SiteFilter
        placeholder={anyFlorist}
        filters={florists}
        selected={floristFilter}
        setSelected={handleFloristChange}
      />
    </div>
  )
}
