import axiosConfig from '../axiosConfig'

const updateLastCoached = (fhid, additionalNote) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`api/sites/${fhid}/updateLastCoached`, {
      additionalNote: additionalNote,
    })
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export { updateLastCoached }